import { useState, VFC } from 'react'

const Leftnav: VFC = (): JSX.Element => {
	const [isOpen] = useState(false)
	const navClass = `${isOpen ? ' nav-active' : ''}`
	return (
		<nav className={`navigation scroll-bar ${navClass}`}>
			<div className='container ps-0 pe-0'>
				<div className='nav-content'>
					<div className='nav-wrap card border-0 rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-3'>
						<div className='nav-caption fw-600 font-xssss text-grey-500 pt-1 pb-1'>
							<span>Platforms </span>
						</div>

						<ul className='top-content p-1'>
							<li className='logo d-none d-xl-block d-lg-block'></li>

							<li>
								<a
									href={`/games/discover?platform=ps5&page=1`}
									className='nav-content-bttn open-font'
								>
									<i className='btn-round-md bg-p5 me-3'>P5</i>
									<span>Playstation 5</span>
								</a>
							</li>

							<li>
								<a
									href={`/games/discover?platform=xbox-sx&page=1`}
									className='nav-content-bttn open-font'
								>
									<i className='btn-round-md bg-xs me-3'>XS</i>
									<span>Xbox SX</span>
								</a>
							</li>

							<li>
								<a
									href={`/games/discover?platform=pc&page=1`}
									className='nav-content-bttn open-font'
								>
									<i className='btn-round-md bg-pc me-3'>PC</i>
									<span>Desktop</span>
								</a>
							</li>

							<li>
								<a
									href={`/games/discover?platform=switch&page=1`}
									className='nav-content-bttn open-font'
								>
									<i className='btn-round-md bg-sw me-3'>SW</i>
									<span>Switch</span>
								</a>
							</li>

							<li>
								<a
									href={`/games/discover?platform=ps4&page=1`}
									className='nav-content-bttn open-font'
								>
									<i className='btn-round-md bg-p4 me-3'>P4</i>
									<span>Playstation 4 </span>
								</a>
							</li>

							<li>
								<a
									href={`/games/discover?platform=xbox&page=1`}
									className='nav-content-bttn open-font'
								>
									<i className='btn-round-md bg-xo me-3'>XO</i>
									<span>Xbox One </span>
								</a>
							</li>
						</ul>
					</div>

					<div className='nav-wrap card border-0 rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-3'>
						<div className='nav-caption fw-600 font-xssss text-grey-500 pt-1'>
							<span>More </span>Pages
						</div>

						<ul className='p-1'>
							<li>
								<a
									href='https://media.unigamer.com/news'
									className='nav-content-bttn open-font'
								>
									<i className='font-xl text-current feather-cast me-3'></i>
									<span>News</span>
									<span className='circle-count bg-warning mt-1'>584</span>
								</a>
							</li>

							<li>
								<a
									href='https://media.unigamer.com'
									className='nav-content-bttn open-font'
								>
									<i className='font-xl text-current feather-home me-3'></i>
									<span>Community</span>
								</a>
							</li>

							<li>
								<a href='/games/discover' className='nav-content-bttn open-font'>
									<i className='font-xl text-current feather-compass me-3'></i>
									<span>Discover</span>
								</a>
							</li>

							<li>
								<a href='/coming-soon' className='nav-content-bttn open-font'>
									<i className='font-xl text-current feather-youtube me-3'></i>
									<span>Live Stream</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	)
}

export default Leftnav
