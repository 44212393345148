import { IOnlineUser } from "../contexts";

interface IUser {
  _id: string;
  isLoggedIn?: boolean;
}
export const checkIsOnline = (user: IUser, onlineUsers: IOnlineUser[]) => {
  const record = onlineUsers.find((u) => u._id === user._id);
  if (record?.status === "connected") return true;
  else if (record?.status === "disconnected") return false;
  else if (user?.isLoggedIn) return true;
  return false;
};
