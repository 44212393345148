import { FC, useContext, useEffect } from 'react'

import { AppContext } from '../contexts'
import { SocketContext } from '../contexts/socket';

import { getUserByAccessToken } from '../services/user.service'

const MainLayout: FC = ({ children }) => {
	const { socket } = useContext(SocketContext);
	const { user, setUser, isLoggedIn, setIsLoading, setIsLoggedIn ,onlineUsers,setOnlineUser} =
		useContext(AppContext)

	useEffect(() => {
		const handleAsyncCalls = async () => {
			try {
				if (!user && localStorage.getItem('token')) {
					setIsLoading(true)
					const result = await getUserByAccessToken()
					//Cal Api For User Followers
					if (result) {
						setUser({
							followerUsers: [],
							followingGames: [],
							followingUsers: [],
							reviewsCount: 0,
							...result.data,
						})
						setIsLoggedIn(true)
					}
				}
			} catch (err) {
			} finally {
				setIsLoading(false)
			}
		}
		handleAsyncCalls()
	}, [isLoggedIn, setUser, user])

	useEffect(()=>{
		socket?.on(`User_Connected`, (id: string) => {
			setOnlineUser((users) =>{
              const user=users.find(u=>u._id===id)
		      return !user ? [...users, {_id:id,status:'connected'}] 
			  : [...users.filter(u=>u._id!==user._id),{_id:user._id,status:'connected'}]
			});
		  });
		  socket?.on(`User_Disconnected`, (id: string) => {
			setOnlineUser((users) =>{
			  const index = users.findIndex(u=>u._id===id) 
			  if(index>=0)
				return [...users.slice(0,index),{_id:id,status:'disconnected'} ,...users.slice(index+1)]
			  return  [...users,{_id:id,status:'disconnected'}]
			 }
			);
		  });
		  
		  //Remove Event on Socket Disconnection
		  socket?.on("disconnect", () => {
			setOnlineUser(()=>[])
			socket?.off(`User_Connected`, () => {});
			socket?.off(`User_Disconnected`, () => {});
		  });

	},[socket])

	return <div>{children}</div>
}

export default MainLayout
