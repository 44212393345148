import Layout from '../../components/Layout'
import { Helmet } from "react-helmet";

const NotFound = (): JSX.Element => {
	return (
		<Layout>
						<Helmet>
				<meta charSet="utf-8" />
				<title>404 - Not Found</title>
			</Helmet>
			<div className='main-content pt-0 border-0 card ps-0 pe-0'>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex'>
							<div className='card border-0 text-center d-block p-0'>
								<img
									src='../assets/images/bg-43.png'
									alt='icon'
									className='w200 mb-4 ms-auto me-auto pt-md-5'
								/>

								<h1 className='fw-700 text-grey-900 display3-size display4-md-size'>
									Oops! It looks like you're lost.
								</h1>

								<p className='text-grey-500 font-xsss'>
									The page you're looking for isn't available. Try to search again or use
									the go to.
								</p>

								<a
									href='/home'
									className='btn pb-3 pt-3 ps-5 pe-5 bg-blue text-white mr-4 text-uppercase mt-4'
								>
									Home Page
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default NotFound
