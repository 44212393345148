import { useContext, useState } from 'react'
import Avatar from 'react-avatar'
import { Spinner } from 'react-bootstrap'
import { Mention, MentionsInput } from 'react-mentions'
import ReactModal from 'react-modal'
import { AppContext } from '../../../contexts'
import { handleMentionInputChange } from '../../../helpers/MentionInput'
import { postComment } from '../../../services/comment.service'
import { ISuggestionList } from '../../../types/global.interface'

ReactModal.setAppElement('#root')

const CreateComment = ({
	open,
	setOpen,
	postId,
	refetchComments,
}: {
	open: boolean
	postId: string
	setOpen: Function
	refetchComments: Function
}) => {
	const { user } = useContext(AppContext)
	const [message, setMessage] = useState('')
	const [loading, setLoading] = useState(false)
	const [suggestionList,setSuggestionList]=useState<(ISuggestionList)[]>([])

	const handleSendComment = async () => {
		try {
			setLoading(true)
			const mentionIds:string[]=[]
			if (message.includes('@[') && message.includes('](') && message.includes(')')) {
				message.split(/\@(.*?)\)/g).forEach((word:string) => {
				  if (word.startsWith('[') && word.includes('](')) {
					const urlStartIndex = word.indexOf('(') + 1;
					mentionIds.push(word.substring(urlStartIndex));
				  }
				});
			  }
			await postComment({
				body: message,
				parent: postId,
				...(!!mentionIds.length && { mentionIds })
			})
			setMessage('')
			await refetchComments()
			setOpen(false)
		} catch (err) {
			console.log('Error::', err)
		} finally {
			setLoading(false)
		}
	}

	function closeModal() {
		setOpen(false)
	}

	return (
		<div>
			<ReactModal
				isOpen={open}
				contentLabel='onRequestClose Example'
				onRequestClose={closeModal}
				className='Modal modal-card card shadow-xss rounded-xxl border-0 p-4 mb-3'
				overlayClassName='Overlay'
			>
				<div className='card-body p-0'>
					<h4 className='fw-700 font-xss text-grey-900 d-flex align-items-center'>
						Comment
						<i
							className='feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 pointer'
							onClick={closeModal}
						></i>
					</h4>
				</div>
				<div className='card-body p-0 mt-3 position-relative'>
					<figure className='avatar position-absolute ms-2 mt-1 top-5'>
						{user?.profile?.picture?.length ? (
							<img
								src={user?.profile?.picture}
								alt={user?.username}
								className='rounded-circle h30 w30'
							/>
						) : (
							<Avatar name={user?.username} className='rounded-circle h30 w30' />
						)}
					</figure>

					{/* <textarea
						name='message'
						className='h100 bor-0 w-100 rounded-xxl p-2 ps-5 mb-3 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg'
						cols={30}
						rows={10}
						placeholder="What's on your mind?"
						value={message}
						onChange={e => setMessage(e.target.value)}
					></textarea> */}
					 <MentionsInput
					    id='comment-box-element'
						name='message'
						className='mentionTextarea editMentionTextarea'
						cols={30}
						rows={10}
						placeholder="What's on your mind?"
						value={message} 
						allowSuggestionsAboveCursor
						allowSpaceInQuery
						onChange={(e)=>handleMentionInputChange(e,'comment-box-element',setMessage,setSuggestionList,suggestionList,user?._id)}
					>
						<Mention
							trigger="@"
							data={suggestionList}
							appendSpaceOnAdd	
              				style={{color:"#1E74FD"}}
						/>
					</MentionsInput>

					<button
						className='p-2 lh-20 w100 bg-primary-gradiant me-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0'
						onClick={handleSendComment}
						disabled={loading}
					>
						{loading ? <Spinner animation='border' size='sm' /> : 'Send'}
					</button>

					<button
						className='p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl border-0'
						onClick={closeModal}
					>
						Cancel
					</button>
				</div>
			</ReactModal>
		</div>
	)
}

export default CreateComment
