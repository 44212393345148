import { Link, Navigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import { AppContext, Profile } from '../../contexts'
import AuthTag from '../../components/common/AuthTag'
import { updateUserProfile } from '../../services/user.service'
import { uploadMedia } from '../../services/post.service'
import Avatar from 'react-avatar'
import { Helmet } from "react-helmet";
import { showNotification } from '../../components/common/ShowNotification'

const Settings = (): JSX.Element => {
	const { user, setUser } = useContext(AppContext)

	const [userProfile, setUserProfile] = useState<Profile | null>(null)
	const [userName, setUserName] = useState<string>("")
	const [editUserName, setEditUserName] = useState<boolean>(false)
	const [loading, setIsLoading] = useState(false)
	const [profileImage, setProfileImage] = useState<File | null>()
	const [isOpen, setIsOpen] = useState(false)
	const menuClass = `${isOpen ? ' show' : ''}`

	const toggleOpen = () => {
		setIsOpen(!isOpen)
	}

	const handleChange = (type: string, value: string) => {
		if (userProfile) {
			setUserProfile({
				...userProfile,
				[type]: value,
			})
		}
	}

	const handleUpdateProfile = async () => {
		try {
			if (userProfile) {
				setIsLoading(true)
				const _res: any = profileImage ? await uploadMedia(profileImage) : ''

				// const imageURL = profileImage ? await uploadFile(profileImage) : "";
				// TODO: pass this imageURL to payload
				const res = await updateUserProfile({
					...userProfile,
					username: userName,
					picture: _res?.data || userProfile?.picture,
				})
				setEditUserName(false)
				if (res) {
					setUser({
						followerUsers: [],
						followingGames: [],
						followingUsers: [],
						reviewsCount: 0,
						...res.data,
					})
					showNotification(`Successfully Updated`,'success',{autoClose:5000})
				}
			}
		} catch (err) {
			console.log("Error Occured!", err)
		} finally {
			setIsLoading(false)
			window.scrollTo(0, 0)
		}
	}

	useEffect(() => {
		if (user) {
			setUserProfile({ ...user.profile })
			setUserName(user.username || "")
		}
	}, [user])

	if (!user) {
		return <Navigate to='/home' />
	}

	return (
		<>
			<Layout>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Settings for {user._id}</title>
			</Helmet>
				<div className='row'>
					<div className='middle-sidebar-bottom'>
						<div className='middle-sidebar-left'>
							<div className='middle-wrap'>
								<div className='card w-100 border-0 bg-white shadow-xs p-0 mb-4'>
									<div className='card-body p-4 w-100 text-dark border-0 d-flex rounded-3'>
										<Link to='/home' className='d-inline-block mt-2'>
											<i className='ti-arrow-left font-sm'></i>
										</Link>
										<h4 className='font-xs fw-600 ms-4 mb-0 mt-2'>Account Details</h4>
									</div>

									<div className='card-body p-lg-5 p-4 w-100 border-0 '>
										<div className='row justify-content-center'>
											<div className='col-lg-4 text-center'>
												<figure className='avatar ms-auto me-auto mb-0 mt-2 w100'>
													{profileImage ? (
														<>
															<img
																className='rounded-circle h100 w100'
																width={100}
																height={100}
																src={URL.createObjectURL(profileImage)}
																alt=''
															/>
														</>
													) : (
														<>
															{user?.profile?.picture.length ? (
																<img
																	src={user?.profile?.picture}
																	alt={user?.username}
																	className='rounded-circle h100 w100'
																/>
															) : (
																<Avatar
																	name={user?.username}
																	round={true}
																	size={'100px'}
																	className='rounded-circle h100 w100'
																/>
															)}
														</>
													)}
												</figure>
												<div
													className={`ms-auto pointer mt-3 ${menuClass}`}
													id='dropdownMenu4'
													data-bs-toggle='dropdown'
													aria-expanded='false'
													onClick={toggleOpen}
												>
													<i className='ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss'></i>
												</div>
												<div
													className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg ${menuClass} theme-dark-bg`}
													aria-labelledby='dropdownMenu4'
												>
													<div className='nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer'>
														<i className='feather-edit text-grey-500 me-2 font-sm'></i>
														<div className='fw-600 mb-0 text-dark mt-0 me-4'>
															<div className='form-group mb-0 w-100'>
																<input
																	type='file'
																	name='file'
																	onChange={event => {
																		if (event.target.files && event.target.files[0]) {
																			let img = event.target.files[0]
																			setProfileImage(img)
																		}
																	}}
																	id='file'
																	className='input-file'
																/>
																<label htmlFor='file' className='pointer'>
																	{profileImage ? (
																		<>
																			<span className='font-xsss'>Avatar</span>
																		</>
																	) : (
																		<>
																			<span className='font-xsss'>Avatar</span>
																		</>
																	)}
																</label>
															</div>
														</div>
													</div>
												</div>

												<h2 className='fw-700 font-sm text-grey-900 mt-3'>
													{user?.username || ''}
												</h2>
												<h4 className='text-grey-500 fw-500 mb-3 font-xsss mb-4'>
													{userProfile?.city || ''}
												</h4>
											</div>
										</div>

										<form
											onSubmit={e => {
												e.preventDefault()
											}}
										>
											<div className='row'>
												<div className='col-lg-6 mb-3'>
													<div className='form-group'>
														<label className='text-grey-500 fw-600 font-xsss mb-2'>
															First Name
														</label>
														<input
															type='text'
															className='form-control'
															value={userProfile?.firstname}
															onChange={e => handleChange('firstname', e.target.value)}
														/>
													</div>
												</div>

												<div className='col-lg-6 mb-3'>
													<div className='form-group'>
														<label className='text-grey-500 fw-600 font-xsss mb-2'>
															Last Name
														</label>
														<input
															type='text'
															className='form-control'
															value={userProfile?.lastname}
															onChange={e => handleChange('lastname', e.target.value)}
														/>
													</div>
												</div>
											</div>

											<div className='row'>
												<div className='col-lg-6 mb-3'>
													<div className='form-group'>
														<label className='text-grey-500 fw-600 font-xsss mb-2'>
															Email
														</label>
														<input
															type='text'
															className='form-control'
															value={user?.email}
															disabled
														/>
													</div>
												</div>

												<div className='col-lg-6 mb-3'>
													<div className='form-group'>
														<label className='text-grey-500 fw-600 font-xsss mb-2'>
															Phone
														</label>
														<input
															type='text'
															className='form-control'
															value={userProfile?.telephone}
															onChange={e => handleChange('telephone', e.target.value)}
														/>
													</div>
												</div>
											</div>

											<div className='row'>
												<div className='col-lg-6 mb-3'>
													<div className='form-group'>
														<label className='text-grey-500 fw-600 font-xsss mb-2'>
															Username
														</label>
														<input
															type='text'
															className='form-control'
															value={userName}
															onChange={e => setUserName(e.target.value)}
														/>
													</div>
												</div>

												<div className='col-lg-6 mb-3'>
													<div className='form-group'>
														<label className='text-grey-500 fw-600 font-xsss mb-2'>
															Country
														</label>
														<input
															type='text'
															className='form-control'
															value={userProfile?.country}
															onChange={e => handleChange('country', e.target.value)}
														/>
													</div>
												</div>

												<div className='col-lg-12 mb-3'>
													<div className='form-group'>
														<label className='text-grey-500 fw-600 font-xsss mb-2'>
															Address
														</label>
														<input
															type='text'
															className='form-control'
															value={userProfile?.street}
															onChange={e => handleChange('street', e.target.value)}
														/>
													</div>
												</div>
											</div>

											<div className='row'>
												<div className='col-lg-6 mb-3'>
													<div className='form-group'>
														<label className='text-grey-500 fw-600 font-xsss mb-2'>
															Town / City
														</label>
														<input
															type='text'
															className='form-control'
															value={userProfile?.city}
															onChange={e => handleChange('city', e.target.value)}
														/>
													</div>
												</div>

												<div className='col-lg-6 mb-3'>
													<div className='form-group'>
														<label className='text-grey-500 fw-600 font-xsss mb-2'>
															Postcode
														</label>
														<input
															type='text'
															className='form-control'
															value={userProfile?.zip}
															onChange={e => handleChange('zip', e.target.value)}
														/>
													</div>
												</div>

												{/* <div className="col-lg-12 mb-3">
                          <div className="card mt-3 border-0">
                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                              <div className="form-group mb-0 w-100">
                                <input
                                  type="file"
                                  name="file"
                                  onChange={(event) => {
                                    if (
                                      event.target.files &&
                                      event.target.files[0]
                                    ) {
                                      let img = event.target.files[0];
                                      setProfileImage(img);
                                    }
                                  }}
                                  id="file"
                                  className="input-file"
                                />
                                <label
                                  htmlFor="file"
                                  className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                >
                                  {profileImage ? (
                                    <>
                                      <img
                                        className="rounded mx-auto d-block"
                                        width={100}
                                        height={100}
                                        src={URL.createObjectURL(profileImage)}
                                        alt=""
                                      />
                                      <span className="js-fileName">
                                        Drag and drop or click to replace
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                      <span className="js-fileName">
                                        Drag and drop or click to replace
                                      </span>
                                    </>
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div> */}

												<div className='col-lg-12 mb-3'>
													<label className='text-grey-500 fw-600 font-xsss mb-2 text-dark'>
														Description
													</label>
													<textarea
														className='form-control mb-0 p-3 h100 bg-greylight lh-16'
														rows={5}
														placeholder='Write your message...'
														value={userProfile?.description}
														onChange={e => handleChange('description', e.target.value)}
													></textarea>
												</div>
												<AuthTag>
													<div className='col-lg-12'>
														<button
															onClick={handleUpdateProfile}
															className='btn pb-3 pt-3 ps-5 pe-5 bg-blue text-white mr-4 text-uppercase mt-4'
														>
															{loading ? (
																<Spinner animation='border' variant='light' size='sm' />
															) : (
																'Save'
															)}
														</button>
													</div>
												</AuthTag>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</>
	)
}

export default Settings
