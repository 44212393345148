import NothingFoundCard from "../../common/NothingFoundCard";
import { IGame, IGamesReviewPayload } from "../../../types/global.interface";
import { IContributions } from "../../../pages/Users";
import ReviewCard from "./ReviewCard";

const Review = ({
  list,
  game,
  setGame,
  refetch,
}: {
  list: (IGamesReviewPayload | any)[];
  game: IGame | any | IContributions;
  setGame: Function;
  refetch: Function;
}) => {
  if (!list.length) {
    return <NothingFoundCard text="No Review Found" />;
  }

  return (
    <>
      {list.map((item, index) => {
        return (
          <ReviewCard
            key={`review_${index}`}
            list={list}
            item={item}
            game={game}
            setGame={setGame}
            refetch={refetch}
          />
        );
      })}
    </>
  );
};

export default Review;
