import moment from "moment";
import { useRef, useCallback, FC } from "react";
import IGameData, {
  IPost,
  IPostData,
  IUserData,
} from "../../../types/global.interface";
import Post from "./Post";

const Posts: FC<IPost> = ({
  list,
  hasNextPage,
  pageNumber,
  loading,
  setPageNumber,
  setPosts,
  allPosts,
}: IPost) => {
  const observer = useRef<IntersectionObserver | undefined>();

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !loading) {
          setPageNumber && setPageNumber((prev: number) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasNextPage, pageNumber, loading]
  );

  return (
    <>
      {list?.map((post: IPostData<IUserData | IGameData>, index: number) => {
        const likes =
          post.likedBy.length >= 1000
            ? `${post.likedBy.length}K`
            : post.likedBy.length;

        const likesUserData = post.likedBy

        const createdAt = moment(post?.createdAt).format("LLL");

        return (
          <div
            key={index}
            className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
            ref={
              pageNumber && index === list.length - 1 ? lastElementRef : null
            }
          >
            <Post
              post={post}
              createdAt={createdAt}
              likesUserData={likesUserData}
              likes={likes}
              postList={allPosts}
              setPosts={setPosts}
            />
          </div>
        );
      })}
    </>
  );
};

export default Posts;
