import { ReactNode } from 'react'
import Appfooter from '../Footer/AppFooter'
import Header from '../Header'
import Leftnav from '../LeftNav'

const Layout = ({
	setSearchValue,
	setPageNumber,
	loading,
	children,
}: {
	setSearchValue?: Function
	setPageNumber?: Function
	loading?: boolean
	children: ReactNode
}) => {
	return (
		<div className='layout'>
			<Header
				setSearchValue={setSearchValue}
				setPageNumber={setPageNumber}
				loading={loading}
			/>
			<Leftnav />
			<div className='main-content right-chat-active'>
				<div className='middle-sidebar-bottom'>
					<div className='middle-sidebar-left'>{children}</div>
				</div>
			</div>
			<Appfooter />
		</div>
	)
}

export default Layout
