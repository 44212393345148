import _ from 'lodash'
import { useContext, useState } from 'react'
import { AppContext } from '../../../contexts'
import { likeComment, unLikeComment } from '../../../services/comment.service'
import { Comment } from '../../../types/global.interface'

const LikeComment = ({
	comments,
	value,
	setComments,
}: {
	comments: (Comment | null)[]
	value: Comment | null
	setComments: Function
}) => {
	const { user } = useContext(AppContext)

	const [timeoutd, setTimeoutId] = useState(0)

	const isLiked = value?.likedBy.find(item => item._id === user?._id) ? true : false

	const likeBtn = (type: 'like' | 'unlike', commentId: string) => {
		if (comments?.length) {
			const index = comments.findIndex(item => item?._id === commentId)
			const comment = comments.find(item => item?._id === commentId)

			let ourComment = _.cloneDeep(comment)
			if (user && ourComment) {
				if (type === 'like') ourComment.likedBy.push({
						_id:user._id||"",
						username:user.username||"",
						profile:user.profile
				     })
				else if (type === 'unlike') {
					const index = ourComment.likedBy.findIndex(item => item._id === user._id)
					ourComment?.likedBy?.splice(index, 1)
				}
			}

			setComments &&
				setComments([
					...comments.slice(0, index),
					ourComment,
					...comments.slice(index + 1),
				])
		}
	}

	const handleLike = async (id: string, type: string) => {
		try {
			if (type === 'like') {
				// likeBtn("like", id);
				await likeComment(id)
			} else if (type === 'unlike') {
				// likeBtn("unlike", id);
				await unLikeComment(id)
			}
		} catch (err) {
			console.log('Error:', err)
			likeBtn(type === 'like' ? 'unlike' : 'like', id)
		}
	}
	return (
		<div className='btn-toolbar'>
			<div
				className='d-flex align-items-center fw-600 text-grey-500 text-dark lh-26 font-xssss pointer border-0 '
				onClick={() => {
					likeBtn(isLiked ? 'unlike' : 'like', value?._id || '')
					clearTimeout(timeoutd)
					let timeout = window.setTimeout(() => {
						handleLike(value?._id || '', isLiked ? 'unlike' : 'like')
					}, 1000)
					setTimeoutId(timeout)
				}}
			>
				<span className='d-none-xss'>
					<u>Like</u>
				</span>
			</div>
		</div>
	)
}
export default LikeComment
