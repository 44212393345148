import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import ReactModal from "react-modal";
import { Spinner } from "react-bootstrap";
import Avatar from "react-avatar";
import { Mention, MentionsInput } from "react-mentions";
import moment from "moment";

import { AppContext } from "../../../contexts";
import { Comment, ISuggestionList } from "../../../types/global.interface";
import { editComment } from "../../../services/comment.service";
import { getMentionIds, handleMentionInputChange } from "../../../helpers/MentionInput";

ReactModal.setAppElement("#root");

const EditComment = ({
  open,
  setOpen,
  comment,
  allComments,
  setComments,
}: {
  open: boolean;
  setOpen: Function;
  comment: Comment | null;
  allComments: (Comment | null)[];
  setComments: Function;
}) => {
  const { user } = useContext(AppContext);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestionList,setSuggestionList]=useState<(ISuggestionList)[]>([])

  const createdAt = moment(comment?.createdAt).format("LLL");

  const handleUpdateComment = async () => {
    try {
      setLoading(true);
      const latestMentionIds:string[]=getMentionIds(message)
      const oldMentionIds:string[]=getMentionIds(comment?.body||"")

      await editComment(comment?._id || "", message, latestMentionIds?.filter(mentionId=>!oldMentionIds?.includes(mentionId)));
      const index =
        allComments.findIndex((item) => item?._id === comment?._id) || 0;
      let cloneComment = _.cloneDeep(comment);
      if (cloneComment) cloneComment.body = message;
      setComments &&
        setComments([
          ...allComments.slice(0, index),
          cloneComment,
          ...allComments.slice(index + 1),
        ]);

      setMessage("");
      setOpen(false);
    } catch (err) {
      console.log("Error::", err);
    } finally {
      setLoading(false);
    }
  };

  function closeModal() {
    setOpen(false);
  }

  useEffect(() => {
    if (comment) {
      setMessage(comment.body);
    }
  }, [comment, open]);

  return (
    <div>
      <ReactModal
        isOpen={open}
        contentLabel="onRequestClose Example"
        onRequestClose={closeModal}
        className="Modal modal-card card shadow-xss rounded-xxl border-0 p-4 mb-3"
        overlayClassName="Overlay"
      >
        <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">
          Edit Comment
          <i
            className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 pointer"
            onClick={closeModal}
          ></i>
        </h4>
        <div className="card-body p-0 d-flex">
          <figure className="avatar me-3">
            {user?.profile?.picture?.length ? (
              <img
                src={user?.profile?.picture}
                alt={user?.username}
                className="rounded-circle h45 w45"
              />
            ) : (
              <Avatar
                name={user?.username}
                className="rounded-circle h45 w45"
              />
            )}
          </figure>
          <h4 className="fw-700 text-grey-900 font-xssss mt-1">
            {" "}
            {user?.username}
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
              {" "}
              {moment(createdAt, "LLL").fromNow()}
            </span>
          </h4>
        </div>
        <div className="card-body p-0 mt-1 position-relative">
          {/* <textarea
            name="message"
            className="h100 bor-0 w-100 rounded-xxl p-2 ps-3 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg mb-3"
            cols={30}
            rows={10}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea> */}
           <MentionsInput
					    id='edit-comment-box-element'
              name='message'
              className='editMentionTextarea mentionTextarea'
              cols={30}
              rows={10}
              placeholder="What's on your mind?"
              value={message} 
						  allowSuggestionsAboveCursor
						  allowSpaceInQuery
              onChange={(e)=>handleMentionInputChange(e,'edit-comment-box-element',setMessage,setSuggestionList,suggestionList,user?._id)}
					>
						<Mention
							trigger="@"
							data={suggestionList}
							appendSpaceOnAdd	
              style={{background:"gray",color:"white"}}
						/>
					</MentionsInput>
          <button
            className="p-2 lh-20 w100 bg-primary-gradiant me-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0"
            onClick={handleUpdateComment}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Update"
            )}
          </button>
          <button
            className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl border-0"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </ReactModal>
    </div>
  );
};

export default EditComment;
