import { VFC } from 'react'

const GameSliderPlaceholder: VFC = (): JSX.Element => {
	return (
		<div className='card w200 d-block border-0 shadow-xss rounded-xxl overflow-hidden mb-3 me-3'>
			<div className='card-body position-relative h100 bg-image-cover bg-image-center loading'></div>

			<div className='card-body d-block w-100 ps-4 pe-4 pb-4 text-center'>
				<figure className='avatar overflow-hidden ms-auto me-auto mb-0 mt--6 position-relative w75 h75 z-index-1'>
					<div className='float-right p-1 bg-white rounded-circle w75 h75 loading' />
				</figure>

				<div className='clearfix'></div>

				<h4 className='fw-700 font-xsss mt-2 mb-1 loading'>Placeholder</h4>

				<p className='fw-500 font-xsssss text-grey-500 mt-0 mb-2'>
					<span className='loading'>Followers</span>
				</p>

				<span className='live-tag mt-2 mb-0 bg-danger p-2 z-index-1 rounded-3 text-white font-xsssss text-uppersace fw-700 ls-3 loading'>
					Sponsored
				</span>

				<div className='clearfix mb-2'></div>
			</div>
		</div>
	)
}

export default GameSliderPlaceholder
