import { createContext } from "react";

export interface Profile {
  description: string;
  telephone: string;
  country: string;
  state: string;
  zip: string;
  city: string;
  street: string;
  company: string;
  picture: string;
  lastname: string;
  firstname: string;
}

export interface Social {
  steam: string;
  discord: string;
  youtube: string;
  twitch: string;
  reddit: string;
}

export interface ContextUser {
  consoles: [];
  createdAt: Date;
  email: string;
  email_verified: boolean;
  profile: Profile;
  social: Social;
  sub: string;
  active: number | string | boolean;
  updatedAt: Date;
  username: string;
  __v: number;
  _id: string;
  followerUsers: string[];
  followingUsers: string[];
  followingGames: string[];
  reviewsCount: number;
  claimGames: string[];
}
export interface IOnlineUser {
  _id: string;
  status: string;
}

interface AppContextInterface {
  user: ContextUser | null;
  setUser: (user: ContextUser | null) => void;
  isLoggedIn: boolean;
  setIsLoggedIn: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  onlineUsers: IOnlineUser[];
  setOnlineUser: (
    ids: (users: IOnlineUser[]) => IOnlineUser[]
  ) => void;
}

export const AppContext = createContext<AppContextInterface>({
  user: null,
  setUser() {},
  isLoggedIn: false,
  setIsLoggedIn() {},
  isLoading: true,
  setIsLoading() {},
  onlineUsers: [],
  setOnlineUser() {},
});
