import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { io, Socket } from 'socket.io-client'
import { Formik, Form, Field, FormikProps } from 'formik'

import { AppContext } from '../../../contexts'
import { loginUser } from '../../../services/login.service'
import AuthLayout from '../../Layout/Auth'
import LeftBanner from '../LeftBanner'
import { ILoginPayload } from '../../../types/global.interface'
import { Spinner } from 'react-bootstrap'
import { SocketContext } from '../../../contexts/socket'

import { loadRecaptcha } from '../../../helpers/recaptcha'
import { showNotification } from '../../common/ShowNotification'

const LoginSchema = Yup.object().shape({
	email: Yup.string().required('Email Required'),
	password: Yup.string().required('Password Required'),
})
declare global {
	interface Window {
		grecaptcha: any
	}
}
interface Ilogin extends ILoginPayload {
	token: string
}

const initialValues: ILoginPayload = {
	email: '',
	password: '',
}

const CLogin = (): JSX.Element => {
	const navigate = useNavigate()
	const { setIsLoggedIn } = useContext(AppContext)
	const { setSocket } = useContext(SocketContext)

	const [loading, setLoading] = useState(false)

	const createSocketConnectionOptions = (token: string) => {
		return {
			transportOptions: {
				polling: {
					extraHeaders: {
						Authorization: `Bearer ${token}` || '',
					},
				},
			},
		}
	}

	const connectSocket = (token: string) => {
		const socketConnectionOptions = createSocketConnectionOptions(token)
		const socketUri = process.env.REACT_APP_SOCKET_URL || ''

		const socketConnection: Socket = io(socketUri, socketConnectionOptions)

		setSocket(socketConnection)
	}

	const fetchReCaptchaToken = (values: ILoginPayload) => {
		try {
			setLoading(true)
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
						action: 'submit',
					})
					.then((token: string) => {
						handleLogin({ ...values, token })
					})
			})
		} catch (err) {
			setLoading(false)
		}
	}

	const handleLogin = async (values: Ilogin) => {
		try {
			const { data } = await loginUser(values)
			if (data?.jwtToken) {
				localStorage.setItem('token', data.jwtToken)
				connectSocket(data.jwtToken)
				setIsLoggedIn(true)
				navigate('/home')
			}
		} catch (err: any) {
			if (err?.response) {
			showNotification(`${err.response.data.message}`,'error',{autoClose:5000})

				if (err.response.data.message?.includes('verification'))
					setTimeout(() => {
						navigate('/auth/verify')
					}, 4000)
			}
		} finally {
			setLoading(false)
		}
	}

	/* Script For Re-Captcha */
	useEffect(() => {
		loadRecaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY || '')
	}, [])

	return (
		<AuthLayout>
			<LeftBanner />

			<div className='row'>
				<div
					className='col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat '
					style={{
						backgroundImage: `url("assets/images/landingpage/background.svg")`,
						filter: 'brightness(0.8)',
					}}
				></div>

				<div className='col-xl-7 vh-100 align-items-center d-flex rounded-3 overflow-hidden'>
					<div className='shadow-none border-0 ms-auto me-auto login-card'>
						<div className='card-body rounded-0 text-left'>

							<h2 className='fw-700 display1-size display2-md-size mb-3'>
								Login into <br />
								your account
							</h2>

							<Formik
								validationSchema={LoginSchema}
								initialValues={initialValues}
								onSubmit={async (values, actions) => {
									actions.setSubmitting(true)
									fetchReCaptchaToken(values)
									actions.setSubmitting(false)
								}}
							>
								{(props: FormikProps<ILoginPayload>) => {
									return (
										<Form>
											<small className='text-danger'>
												{props.errors.email || props.errors.password}
											</small>

											<div className='form-group icon-input mb-3'>
												<i className='font-sm ti-email text-grey-500 pe-0'></i>
												<Field
													type='text'
													className='style2-input ps-5 form-control text-grey-900 font-xsss fw-600'
													placeholder='Your Email Address'
													name='email'
												/>
											</div>

											<div className='form-group icon-input mb-1'>
												<Field
													type='Password'
													className='style2-input ps-5 form-control text-grey-900 font-xss ls-3'
													placeholder='Password'
													name='password'
												/>
												<i className='font-sm ti-lock text-grey-500 pe-0'></i>
											</div>

											<div className='form-check text-left mb-3'>
												<input
													type='checkbox'
													className='form-check-input mt-2'
													id='remember'
												/>

												<label
													className='form-check-label font-xsss text-grey-500 mt-1'
													htmlFor='remember'
												>
													Remember me
												</label>

												<Link
													to='/forgot'
													className='fw-600 font-xsss text-grey-700 mt-1 float-right'
												>
													Forgot your Password?
												</Link>
											</div>

											<div className='col-sm-12 p-0 text-left'>
												<div className='form-group mb-1'>
													<button
														disabled={props?.isSubmitting || loading}
														className='form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 '
														type='submit'
													>
														{props?.isSubmitting || loading ? (
															<Spinner animation='border' variant='light' size='sm' />
														) : (
															'Login'
														)}
													</button>
												</div>

												<h6 className='text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32'>
													Dont have account{' '}
													<Link to='/register' className='fw-700 ms-1'>
														Register
													</Link>
												</h6>
											</div>
										</Form>
									)
								}}
							</Formik>

							{/* <div className="col-sm-12 p-0 text-center mt-2">
                <h6 className="mb-0 d-inline-block bg-white fw-500 font-xsss text-grey-500 mb-3">
                  Or, Sign in with your social account{" "}
                </h6>
                <div className="form-group mb-1">
                  <a
                    href="/register"
                    className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2"
                  >
                    <img
                      src="/assets/images/icon-1.png"
                      alt="icon"
                      className="ms-2 w40 mb-1 me-5"
                    />{" "}
                    Sign in with Google
                  </a>
                </div>
                <div className="form-group mb-1">
                  <a
                    href="/register"
                    className="form-control text-left style2-input text-white fw-600 bg-twitter border-0 p-0 "
                  >
                    <img
                      src="/assets/images/icon-3.png"
                      alt="icon"
                      className="ms-2 w40 mb-1 me-5"
                    />{" "}
                    Sign in with Facebook
                  </a>
                </div>
              </div> */}
						</div>
					</div>
				</div>
			</div>
		</AuthLayout>
	)
}

export default CLogin
