import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'

import { AppContext } from '../../../contexts'
import {
	followGame,
	getGameById,
	unFollowGame,
} from '../../../services/game.service'
import { GamesEntity } from '../../../types/global.interface'
import AuthTag from '../../common/AuthTag'

const GameCard = ({ game }: { game: GamesEntity }) => {
	const { user } = useContext(AppContext)
	const [followers, setFollowers] = useState<(string | null)[]>([])
	const [loading, setLoading] = useState(false)

	const isFollowed = followers?.includes(user?._id || '') || false

	const handleFollow = async (type: 'follow' | 'unfollow') => {
		try {
			setLoading(true)
			if (type === 'follow') {
				await followGame({ id: game?._id || '' })
				if (!followers.includes(user?._id || '') && user) {
					setFollowers([...followers, user?._id])
				}
			} else if (type === 'unfollow') {
				await unFollowGame({ id: game?._id || '' })
				setFollowers([...followers.filter(item => item !== user?._id)])
			}
		} catch (err) {
			console.log('err::', err)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		const fetchGame = async () => {
			try {
				if (game._id) {
					setLoading(true)
					const res = await getGameById({
						id: game._id,
					})

					res.data.followers && setFollowers(res.data.followers)
				}
			} catch (error) {
				console.log('error', error)
			} finally {
				setLoading(false)
			}
		}
		fetchGame()
	}, [game._id])

	return (
		<div className='wrap'>
			<div className='card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0'>
				<figure className='avatar me-3'>
					<a href={`/game/${game._id}`}>
						<img
							src={game.coverUrl}
							alt={game.name}
							className='rounded-circle w45 h45'
						/>
					</a>
				</figure>

				<h4 className='fw-700 text-grey-900 font-xssss mt-1 trnc-game-card'>
					<a className="text-dark" href={`/game/${game._id}`}>
						{game.name}
					</a>
					<span className='d-block font-xssss fw-500 mt-1 lh-3 text-grey-500'>
						{loading ? 'loading...' : <>{followers.length || 0} Followers</>}
					</span>
				</h4>
			</div>

			<div className='card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4'>
				<AuthTag>
					<button
						onClick={() => {
							handleFollow(isFollowed ? 'unfollow' : 'follow')
						}}
						className={`p-2 lh-20 w100 ${
							isFollowed ? 'bg-primary-gradiant-active' : 'bg-primary-gradiant'
						}  me-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0`}
					>
						{loading ? (
							<Spinner animation='border' size='sm' />
						) : isFollowed ? (
							' Following'
						) : (
							' Follow'
						)}
					</button>
				</AuthTag>

				<a href={`/game/${game._id}`}>
					<button className='p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl border-0'>
						Page
					</button>
				</a>
			</div>
		</div>
	)
}
export default GameCard
