import { IGamesReviewPayload } from '../../../types/global.interface'

function ReviewCircles({
	reviews,
}: {
	reviews: (IGamesReviewPayload | null)[]
}) {
	// let sum_rating = 0;
	// let av_rating = 0;

	let positive = 0
	let Neutral = 0
	let Negative = 0
	let experience = 0
	let easy = 0
	let normal = 0
	let hard = 0
	let difficulty = 0
	let t_100 = 0
	let t_24_p = 0
	let t_24_m = 0
	let timespent = 0

	reviews.map(item => {
		if (item?.experience === 'positive') positive += 1
		if (item?.experience === 'negative') Negative += 1
		if (item?.experience === 'neutral') Neutral += 1

		if (item?.difficulty === 'easy') easy +=1
		if (item?.difficulty === 'normal') normal +=1
		if (item?.difficulty === 'hard') hard +=1

		if (item?.hoursPlayed === '<24h') t_24_p +=1
		if (item?.hoursPlayed === '>100h') t_100 +=1
		if (item?.hoursPlayed === '>24h') t_24_m +=1
		return <></>
	})

	/*props.reviews.forEach(function (result) {
      sum_rating += result.rating;
      positive = (result.experience.includes('Positive')) ? (positive + 1) : positive;
      Neutral = (result.experience.includes('Neutral')) ? (Neutral + 1) : Neutral;
      Negative = (result.experience.includes('Negative')) ? (Negative + 1) : Negative;
      easy = (result.difficulty.includes('Easy')) ? (easy + 1) : easy;
      normal = (result.difficulty.includes('Normal')) ? (normal + 1) : normal;
      hard = (result.difficulty.includes('Hard')) ? (hard + 1) : hard;
      t_100 = (result.timespent.includes('>100')) ? (t_100 + 1) : t_100;
      t_24_p = (result.timespent.includes('>24')) ? (t_24_p + 1) : t_24_p;
      t_24_m = (result.timespent.includes('<24')) ? (t_24_m + 1) : t_24_m;
    });*/

	experience = positive + Neutral + Negative
	difficulty = easy + normal + hard
	timespent = t_100 + t_24_p + t_24_m
	//av_rating = sum_rating / props.reviews.count;

	return (
		<div className='rounded pt-2'>
			<div className='frame'>
				<div className='row'>
					{/* Experience Circle */}
					<div className='col-4'>
						<figure>
							<div className='figure-content pl-0 pr-8'>
								<svg
									width='100%'
									height='100%'
									viewBox='0 0 42 42'
									className='donut'
									aria-labelledby='rating-title rating-desc'
									role='img'
								>
									<title id='rating-title'>Experience</title>
									<desc id='rating-desc'>Experience Descriptions</desc>
									<circle
										className='donut-hole'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										role='presentation'
									></circle>
									<circle
										className='donut-ring'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#d2d3d4'
										strokeWidth='3'
										role='presentation'
									></circle>

									<circle
										className='donut-segment'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#f65d72'
										strokeWidth='3'
										strokeDasharray={
											(positive / experience) * 100 -
											((0.1 * positive) / experience) * 100 +
											' ' +
											(100 -
												(positive / experience) * 100 +
												((0.1 * positive) / experience) * 100)
										}
										strokeDashoffset='25'
									>
										<title id='donut-segment-1-title'></title>
										<desc id='donut-segment-1-desc'></desc>
									</circle>
									<circle
										className='donut-segment'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#a02133'
										strokeWidth='3'
										strokeDasharray={
											(Neutral / experience) * 100 -
											((0.1 * Neutral) / experience) * 100 +
											' ' +
											(100 -
												(Neutral / experience) * 100 +
												((0.1 * Neutral) / experience) * 100)
										}
										strokeDashoffset={
											100 -
											((positive / experience) * 100 -
												((0.1 * positive) / experience) * 100) +
											25
										}
									>
										<title id='donut-segment-2-title'></title>
										<desc id='donut-segment-2-desc'></desc>
									</circle>
									<circle
										className='donut-segment'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#fdbec7'
										strokeWidth='3'
										strokeDasharray={
											(Negative / experience) * 100 -
											((0.1 * Negative) / experience) * 100 +
											' ' +
											(100 -
												(Negative / experience) * 100 +
												((0.1 * Negative) / experience) * 100)
										}
										strokeDashoffset={
											100 -
											((Neutral / experience) * 100 -
												((0.1 * Neutral) / experience) * 100) +
											100 -
											((positive / experience) * 100 -
												((0.1 * positive) / experience) * 100) +
											25
										}
									>
										<title id='donut-segment-3-title'></title>
										<desc id='donut-segment-3-desc'></desc>
									</circle>
									{/* unused 10% */}
									<g className='chart-text text-dark'>
										<text x='50%' y='50%' className='chart-number'>
											{positive + Negative + Neutral}
										</text>
										<text x='50%' y='50%' className='chart-label'>
											Experience
										</text>
									</g>
								</svg>
							</div>
							<figcaption className='figure-key truncate-circle'>
								<p className='sr-only'></p>

								<ul className='figure-key-list' aria-hidden='true' role='presentation'>
									<li>
										<span className='shape-circle shape-red'></span>{' '}
										<span className='text-dark text-uppercase sizeFont'>
											Positive ({positive})
										</span>
									</li>
									<li>
										<span className='shape-circle shape-red-dark'></span>{' '}
										<span className='text-dark text-uppercase sizeFont'>
											Neutral ({Neutral})
										</span>
									</li>
									<li>
										<span className='shape-circle shape-red-light'></span>{' '}
										<span className='text-dark text-uppercase sizeFont'>
											Negative ({Negative})
										</span>
									</li>
								</ul>
							</figcaption>
						</figure>
					</div>
					{/* End Experience Circle */}
					{/* Difficulty Circle */}
					<div className='col-4'>
						<figure>
							<div className='figure-content pl-0 pr-8'>
								<svg
									width='100%'
									height='100%'
									viewBox='0 0 42 42'
									className='donut'
									aria-labelledby='rating-title rating-desc'
									role='img'
								>
									<title id='rating-title'>Difficulty</title>
									<desc id='rating-desc'>Difficulty Description</desc>
									<circle
										className='donut-hole'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										role='presentation'
									></circle>
									<circle
										className='donut-ring'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#d2d3d4'
										strokeWidth='3'
										role='presentation'
									></circle>

									<circle
										className='donut-segment'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#0dddbd'
										strokeWidth='3'
										strokeDasharray={
											(easy / difficulty) * 100 -
											((0.1 * easy) / difficulty) * 100 +
											' ' +
											(100 - (easy / difficulty) * 100 + ((0.1 * easy) / difficulty) * 100)
										}
										strokeDashoffset='25'
									>
										<title id='donut-segment-1-title'></title>
										<desc id='donut-segment-1-desc'></desc>
									</circle>
									<circle
										className='donut-segment'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#078e79'
										strokeWidth='3'
										strokeDasharray={
											(normal / difficulty) * 100 -
											((0.1 * normal) / difficulty) * 100 +
											' ' +
											(100 -
												(normal / difficulty) * 100 +
												((0.1 * normal) / difficulty) * 100)
										}
										strokeDashoffset={
											100 -
											((easy / difficulty) * 100 - ((0.1 * easy) / difficulty) * 100) +
											25
										}
									>
										<title id='donut-segment-2-title'></title>
										<desc id='donut-segment-2-desc'></desc>
									</circle>
									<circle
										className='donut-segment'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#a2efe3'
										strokeWidth='3'
										strokeDasharray={
											(hard / difficulty) * 100 -
											((0.1 * hard) / difficulty) * 100 +
											' ' +
											(100 - (hard / difficulty) * 100 + ((0.1 * hard) / difficulty) * 100)
										}
										strokeDashoffset={
											100 -
											((normal / difficulty) * 100 - ((0.1 * normal) / difficulty) * 100) +
											100 -
											((easy / difficulty) * 100 - ((0.1 * easy) / difficulty) * 100) +
											25
										}
									>
										<title id='donut-segment-3-title'></title>
										<desc id='donut-segment-3-desc'></desc>
									</circle>
									{/* unused 10% */}
									<g className='chart-text text-dark'>
										<text x='50%' y='50%' className='chart-number'>
											{easy + normal + hard}
										</text>
										<text x='50%' y='50%' className='chart-label'>
											Difficulty
										</text>
									</g>
								</svg>
							</div>
							<figcaption className='figure-key truncate-circle'>
								<p className='sr-only'></p>

								<ul className='figure-key-list' aria-hidden='true' role='presentation'>
									<li>
										<span className='shape-circle shape-green'></span>{' '}
										<span className='text-dark text-uppercase sizeFont'>
											Easy ({easy})
										</span>
									</li>
									<li>
										<span className='shape-circle shape-green-dark'></span>{' '}
										<span className='text-dark text-uppercase sizeFont'>
											Medium ({normal})
										</span>
									</li>
									<li>
										<span className='shape-circle shape-green-light'></span>{' '}
										<span className='text-dark text-uppercase sizeFont'>
											Hard ({hard})
										</span>
									</li>
								</ul>
							</figcaption>
						</figure>
					</div>
					{/* End Difficulty Circle */}
					{/* TimesPent Circle */}
					<div className='col-4'>
						<figure>
							<div className='figure-content pl-0 pr-8'>
								<svg
									width='100%'
									height='100%'
									viewBox='0 0 42 42'
									className='donut'
									aria-labelledby='rating-title rating-desc'
									role='img'
								>
									<title id='rating-title'>TimesPent</title>
									<desc id='rating-desc'>TimesPent Description</desc>
									<circle
										className='donut-hole'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										role='presentation'
									></circle>
									<circle
										className='donut-ring'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#d2d3d4'
										strokeWidth='3'
										role='presentation'
									></circle>

									<circle
										className='donut-segment'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#ffea00'
										strokeWidth='3'
										strokeDasharray={
											(t_100 / timespent) * 100 -
											((0.1 * t_100) / timespent) * 100 +
											' ' +
											(100 - (t_100 / timespent) * 100 + ((0.1 * t_100) / timespent) * 100)
										}
										strokeDashoffset='25'
									>
										<title id='donut-segment-1-title'></title>
										<desc id='donut-segment-1-desc'></desc>
									</circle>
									<circle
										className='donut-segment'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#e4d100'
										strokeWidth='3'
										strokeDasharray={
											(t_24_p / timespent) * 100 -
											((0.1 * t_24_p) / timespent) * 100 +
											' ' +
											(100 -
												(t_24_p / timespent) * 100 +
												((0.1 * t_24_p) / timespent) * 100)
										}
										strokeDashoffset={
											100 -
											((t_100 / timespent) * 100 - ((0.1 * t_100) / timespent) * 100) +
											25
										}
									>
										<title id='donut-segment-2-title'></title>
										<desc id='donut-segment-2-desc'></desc>
									</circle>
									<circle
										className='donut-segment'
										cx='21'
										cy='21'
										r='15.91549430918954'
										fill='transparent'
										stroke='#fff68e'
										strokeWidth='3'
										strokeDasharray={
											(t_24_m / timespent) * 100 -
											((0.1 * t_24_m) / timespent) * 100 +
											' ' +
											(100 -
												(t_24_m / timespent) * 100 +
												((0.1 * t_24_m) / timespent) * 100)
										}
										strokeDashoffset={
											100 -
											((t_24_p / timespent) * 100 - ((0.1 * t_24_p) / timespent) * 100) +
											100 -
											((t_100 / timespent) * 100 - ((0.1 * t_100) / timespent) * 100) +
											25
										}
									>
										<title id='donut-segment-3-title'></title>
										<desc id='donut-segment-3-desc'></desc>
									</circle>
									{/* unused 10% */}
									<g className='chart-text text-dark'>
										<text x='50%' y='50%' className='chart-number'>
											{/* {t_100 * 100 + t_24_p * 24 + t_24_m * 20} */}
											{t_100 + t_24_p + t_24_m}
										</text>
										<text x='50%' y='50%' className='chart-label'>
											TIMESPENT
										</text>
									</g>
								</svg>
							</div>
							<figcaption className='figure-key truncate-circle'>
								<p className='sr-only'></p>
								<ul className='figure-key-list' aria-hidden='true' role='presentation'>
									<li>
										<span className='shape-circle shape-yellow'></span>{' '}
										<span className='text-dark text-uppercase sizeFont'>
											+100h ({t_100})
										</span>
									</li>
									<li>
										<span className='shape-circle shape-yellow-dark'></span>{' '}
										<span className='text-dark text-uppercase sizeFont'>
											-24h ({t_24_p})
										</span>
									</li>
									<li>
										<span className='shape-circle shape-yellow-light'></span>{' '}
										<span className='text-dark text-uppercase sizeFont'>
											+24h ({t_24_m})
										</span>{' '}
									</li>{' '}
								</ul>{' '}
							</figcaption>{' '}
						</figure>
					</div>
				</div>
			</div>
			{/* End TimesPent Circle */}
		</div>
	)
}

export default ReviewCircles
