const NothingFoundCard = ({ text }: { text: string }) => {
	return (
		<div className='card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3'>
			<div className='card-body text-dark text-center text-grey-500 p-0'>
				{text}
			</div>
		</div>
	)
}
export default NothingFoundCard
