import _ from "lodash";
import { useCallback, useContext, useState } from "react";
import AuthTag from "../../../common/AuthTag";
import "linkify-plugin-hashtag";
import "linkify-plugin-mention";
import { AppContext } from "../../../../contexts";
import { User } from "../../../../types/global.interface";
import CarmModal from "./CarmModal";
interface Props {
  item: any;
  refetch: Function;
}

const CarmComponent: React.FC<Props> = (props) => {
  const { item, refetch } = props;
  const { user } = useContext(AppContext);
  const [open, setOpen] = useState(false);

  const isLiked =
    (user &&
      item &&
      item?.gradeReviewBy &&
      item?.gradeReviewBy?.find((u: string) => u === user._id)) ||
    false;

  const handleModal: () => void = useCallback(() => {
    if (!isLiked) {
      return setOpen(!open);
    }
  }, [open]);

  console.log(item!)
  return (
    <div className="card-body d-flex p-0">
      {user && (
        <div
          onClick={() => handleModal()}
          className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-1"
        >
          <i
            className={`feather-chevrons-up carm-authentic${
              isLiked ? "-active" : ""
            } me-1 btn-round-xs font-xss`}
          ></i>
        </div>
      )}
      <div className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
        <span className="d-none-xss">
          {item?.gradeReviewBy?.length || 0} Grade
          {item?.gradeReviewBy?.length > 1 ? "s" : ""}
        </span>
        <div
          className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg theme-dark-bg`}
          aria-labelledby="dropdownMenu5"
        ></div>
      </div>

      <div
        className={`ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss`}
      >
        <i className={`feather-zap points me-1 btn-round-xs font-xss`}></i>
        <span className="d-none-xs">
          {!item?.grade ? 0 : item?.grade} Point{item?.grade != 1 ? "s" : ""}
        </span>
      </div>
      <CarmModal
        item={item}
        open={open}
        refetch={refetch}
        setOpen={setOpen}
        handleModal={handleModal}
      />
    </div>
  );
};

export default CarmComponent;
