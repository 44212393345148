const PopularUsersPlaceholder = (): JSX.Element => {
	return (
		<>
			{/* PopularUsers */}
			<div className='card w-100 shadow-xss rounded-xxl border-0 mb-3'>
				<div className='card-body d-flex align-items-center p-4'>
					<h4 className='fw-700 mb-0 font-xssss text-grey-500 loading pointer-none'>
						Popular Members
					</h4>

					<div className='fw-600 ms-auto font-xssss loading pointer-none'>
						See all
					</div>
				</div>

				<div className='card-body bg-transparent-card d-flex pt-0 ps-4 pe-4'>
					<figure className='avatar me-3'>
						<div className='rounded-circle h45 w45 loading' />
					</figure>

					<h4 className='fw-700 text-grey-900 font-xssss mt-1 trnc-game-card'>
						<span className='loading pointer-none'>Placeholder</span>
						<span className='d-block font-xssss fw-500 mt-1 lh-3 pointer-none loading'>
							Placeholder
						</span>
					</h4>

					<div className='btn-round-sm loading ms-auto mt-2'>
						<span className='feather-chevron-right font-xss pointer-none'></span>
					</div>
				</div>

				<div className='card-body bg-transparent-card d-flex pt-0 ps-4 pe-4'>
					<figure className='avatar me-3'>
						<div className='rounded-circle h45 w45 loading' />
					</figure>

					<h4 className='fw-700 text-grey-900 font-xssss mt-1 trnc-game-card'>
						<span className='loading pointer-none'>Placeholder</span>
						<span className='d-block font-xssss fw-500 mt-1 lh-3 pointer-none loading'>
							Placeholder
						</span>
					</h4>

					<div className='btn-round-sm loading ms-auto mt-2'>
						<span className='feather-chevron-right font-xss pointer-none'></span>
					</div>
				</div>

				<div className='card-body bg-transparent-card d-flex pt-0 ps-4 pe-4'>
					<figure className='avatar me-3'>
						<div className='rounded-circle h45 w45 loading' />
					</figure>

					<h4 className='fw-700 text-grey-900 font-xssss mt-1 trnc-game-card'>
						<span className='loading pointer-none'>Placeholder</span>
						<span className='d-block font-xssss fw-500 mt-1 lh-3 pointer-none loading'>
							Placeholder
						</span>
					</h4>

					<div className='btn-round-sm loading ms-auto mt-2'>
						<span className='feather-chevron-right font-xss pointer-none'></span>
					</div>
				</div>
			</div>
		</>
	)
}

export default PopularUsersPlaceholder
