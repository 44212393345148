import { useState } from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "react-avatar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { io, Socket } from "socket.io-client";
import Home from "./pages/Home";
import Users from "./pages/Users";
import { colors } from "./constants";
import Login from "./pages/Auth/Login";
import Forgot from "./pages/Auth/Forgot";
import Settings from "./pages/Settings";
import Register from "./pages/Auth/Register";
import * as serviceWorker from "./serviceWorker";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import { ToastContainer } from "react-toastify";
import EditPost from "./components/Posts/EditPost";
import MainLayout from "./layouts/MainLayout";
import NotFound from "./pages/NotFound";
import Analytics from "./pages/Analytics";
import Posts from "./pages/Posts";
import Games from "./pages/Games/GameProfile";
import { AppContext, ContextUser, IOnlineUser } from "./contexts";
import GameDiscovery from "./pages/Games/GameDiscovery";
import "./main.scss";
import "react-toastify/dist/ReactToastify.css";
import ResetPassword from "./components/Auth/Forgot/ResetPassword";
import Notifications from "./pages/Notifications";
import { SocketContext } from "./contexts/socket";
import LandingPage from "./pages/LandingPage";
import ComingSoon from "./pages/ComingSoon";
import SearchResults from "./pages/SearchResults";

let socketConnection: Socket | undefined;
if (localStorage.getItem("token")) {
  const socketConnectionOptions = {
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: `Bearer ${window.localStorage.token}` || "",
        },
      },
    },
  };
  socketConnection = io(
    process.env.REACT_APP_SOCKET_URL || "",
    socketConnectionOptions
  );
}

const Root = (): JSX.Element => {
  const [user, setUser] = useState<ContextUser | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [socket, setSocket] = useState<Socket | undefined>(socketConnection);
  const [onlineUsers, setOnlineUser] = useState<IOnlineUser[]>([]);

  return (
    <ConfigProvider colors={colors}>
      <AppContext.Provider
        value={{
          user,
          setUser,
          isLoggedIn,
          setIsLoggedIn,
          isLoading,
          setIsLoading,
          onlineUsers,
          setOnlineUser,
        }}
      >
        <SocketContext.Provider value={{ socket, setSocket }}>
          <BrowserRouter>
            <MainLayout>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme={
                  localStorage.getItem("theme") === "theme-light"
                    ? "dark"
                    : "light"
                }
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Routes>
                <Route path={`/home`} element={<Home />} />
                <Route path={`/`} element={<LandingPage />} />
                <Route path={`/games/discover`} element={<GameDiscovery />} />
                <Route path={`/search`} element={<SearchResults />} />
                <Route path={`/login`} element={<Login />} />
                <Route path={`/register`} element={<Register />} />
                <Route path={`/forgot`} element={<Forgot />} />
                <Route path={`/edit`} element={<EditPost />} />
                <Route path={`/me`} element={<Users />} />
                <Route path={`/profile/:id`} element={<Users />} />
                <Route path={`/game/:id`} element={<Games />} />
                <Route path={`/settings`} element={<Settings />} />
                <Route path={`/analytics/:id`} element={<Analytics />} />
                <Route path={`/auth/verify`} element={<VerifyEmail />} />
                <Route path={`/reset-password`} element={<ResetPassword />} />
                <Route path={`/notifications`} element={<Notifications />} />
                <Route path={`/post/:id`} element={<Posts />} />
                <Route path={`/post`} element={<Posts />} />
                <Route path="*" element={<NotFound />} />
                <Route path={`/coming-soon`} element={<ComingSoon />} />
              </Routes>
            </MainLayout>
          </BrowserRouter>
        </SocketContext.Provider>
      </AppContext.Provider>
    </ConfigProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
