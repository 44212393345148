import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Avatar from "react-avatar";
import NotificationPlaceholder from "./Placeholder";
import { searchGames } from "../../services/game.service";
import { searchUser } from "../../services/user.service";
import { GamesEntity, User } from "../../types/global.interface";

const SearchComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const search = useLocation().search;
  const url = new URLSearchParams(search);
  const [games, setGames] = useState<GamesEntity[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  const mountEffect = () => {
    const fetchResult = async () => {
      try {
        setLoading(true);
        const games = await searchGames(url.get("query") || "");
        const users = await searchUser(url.get("query") || "");

        if (games.data.games.length) {
          setGames([...games.data.games]);
        }
        if (users.data.users.length) {
          setUsers([...users.data.users]);
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };
    fetchResult();
  };
  useEffect(() => {
    mountEffect();
  }, [url.get("query")]);

  return (
    <>
      <ul
        className='nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4 mb-3'
        id='nav-tab'
        role='tablist'
      >
        <li className='active list-inline-item me-5'>
          <a
            href='/#'
            className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active'
            id='nav-games-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-games'
            role='tab'
            aria-controls='nav-games'
            aria-selected='true'
          >
            Games
          </a>
        </li>
        <li className='list-inline-item me-5'>
          <a
            href='/#'
            className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block'
            id='nav-user-tab'
            data-bs-toggle='tab'
            data-bs-target='#nav-user'

            role='tab'
            aria-controls='nav-user'
            aria-selected='false'
          >
            Users
          </a>
        </li>
      </ul>

      <div
        className="notification-message tab-content"
        id='nav-tabContent'>
        {loading && <NotificationPlaceholder />}

        {!!games.length && (
          <div
            className='tab-pane fade show active'
            id='nav-games'
            role='tabpanel'
            aria-labelledby='nav-games-tab'>
            <li>
              {games?.map((game, index) => (
                <Link
                  key={`${game._id}-Game-${index}`}
                  to={`/game/${game._id}`}
                  className="rounded-3 d-flex notification-li"
                >
                  <div className="notification-user">
                    <img
                      src={game.coverUrl}
                      alt="avatar"
                      className="rounded-circle h30 w30 me-2"
                    />
                    <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700 truncate-text me-3">
                      {game.name || "_ _"}
                    </h6>
                  </div>
                  <div className="notification-description text-grey-900 text-dark fw-600 font-xssss truncate-text me-3">
                    {game.publisher || "_ _"}
                  </div>
                  <div className="ms-2 notification-text text-grey-500 fw-600 font-xssss truncate-text me-3">
                    {game.genre || "_ _"}
                  </div>
                  <div className="notification-time text-grey-500 fw-600">
                    <span
                      className={`${game.consoles === 'ps5'
                        ? 'bg-p5'
                        : game.consoles === 'ps4'
                          ? 'bg-p4'
                          : game.consoles === 'switch'
                            ? 'bg-sw'
                            : game.consoles === 'pc'
                              ? 'bg-pc'
                              : game.consoles === 'xbox-sx'
                                ? 'bg-xs'
                                : 'bg-xo'
                        } text-uppercase p-1 pe-2 ps-2 z-index-1 rounded-3 font-xsssss ls-3 me-1`}
                    >
                      {game.consoles || "_ _"}
                    </span>
                  </div>
                </Link>
              ))}
            </li>
          </div>
        )}

        {!!users.length && (
          <div 							
          className='tab-pane fade'
          id='nav-user'
          role='tabpanel'
          aria-labelledby='nav-user-tab'>
            <li>
              {users?.map((user, index) => (
                <Link
                  key={`${user._id}-Game-${index}`}
                  to={`/profile/${user._id}`}
                  className="rounded-3 d-flex notification-li"
                >
                  <div className="notification-user">
                    {user.profile.picture ? (
                      <img
                        src={user.profile.picture}
                        alt="avatar"
                        className="rounded-circle h30 w30 me-2"
                      />
                    ) : (
                      <Avatar
                        name={user?.username}
                        className="rounded-circle h30 w30 me-2"
                      />
                    )}
                    <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700 truncate-text">
                      {user.username || "_ _"}
                    </h6>
                  </div>
                </Link>
              ))}
            </li>
          </div>
        )}
      </div>
      <div className='col-lg-12 mt-3 mb-5 text-center'>
          <a
            href="/games/discover"
            className='fw-700 text-white font-xssss text-uppercase ls-3 lh-32 mt-3 text-center d-inline-block p-2 bg-current w150 rounded-xl border-0'
          >Discover
          </a>
        </div>
    </>
  );
};

export default SearchComponent;
