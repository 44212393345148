/**
 * @description list comments by query
 * @returns Promise
 */

import axios from "../http-common";

export const getAllComments = async ({ filter }: { filter: string }) => {
  try {
    return await axios.get(`/comments`, {
      params: {
        filter: `${filter}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const postComment = async (data: { parent: string; body: string , mentionIds?:string[]
}) => {
  try {
    return await axios.post(`/comments`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const editComment = async (commentId: string, body: string, mentionIds:string[]) => {
  try {
    return await axios.patch(
      // comments/{id}
      `/comments/${commentId}`,
      { body,
        mentionIds },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const deleteComment = async (commentId: string) => {
  try {
    return await axios.delete(`/comments/${commentId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const likeComment = async (commentId: string) => {
  try {
    return await axios.patch(
      `/comments/${commentId}/like`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const unLikeComment = async (commentId: string) => {
  try {
    return await axios.patch(
      `/comments/${commentId}/unlike`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};
