import Slider from "react-slick";
import ReviewLoader from "../../../components/common/ReviewLoader";


const GamesByPopularityPlaceholder = (): JSX.Element => {
  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
  };
  return (
    <Slider {...sliderSettings}>
      {[1, 2, 3, 4].map((_, index) => {
        return <ReviewLoader key={`review_${index}`} />;
      })}
    </Slider>
  );
};

export default GamesByPopularityPlaceholder;
