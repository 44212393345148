import { useContext, useState } from 'react'
import Avatar from 'react-avatar'
import { Badge, Spinner } from 'react-bootstrap'
import { MentionsInput, Mention } from 'react-mentions'
import { AppContext } from '../../../contexts'
import {
	createPost,
	ICreatePostInput,
	uploadMedia,
} from '../../../services/post.service'
import IGameData, {
	IPostData,
	ISuggestionList,
	IUserData,
} from '../../../types/global.interface'
import AuthTag from '../../common/AuthTag'
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import CreatePostPlaceholder from '../../../placeholder/posts/CreatePostPlaceholder'
import Picker from "emoji-picker-react";
import { handleMentionInputChange } from '../../../helpers/MentionInput'

const CreatePost = ({
	setPosts,
	allPosts,
	postedTo,
	postedToType,
}: {
	setPosts?: Function
	allPosts?: IPostData<IUserData | IGameData>[]
	postedTo?: string
	postedToType?: string
}): JSX.Element => {
	const { user } = useContext(AppContext)
	const [isOpen, setIsOpen] = useState(false)
	const [postMessage, setPostMessage] = useState<any>('')
	const [loading, setLoading] = useState(false)
	const [photo, setPhoto] = useState<File | null>()
    const [suggestionList,setSuggestionList]=useState<(ISuggestionList)[]>([])

	const onEmojiClick = (event: any, emojiObject: any) => {
		// setChosenEmoji(emojiObject);
		if (postMessage !== "") {
			setPostMessage(postMessage + emojiObject.emoji);
		} else {
			setPostMessage(emojiObject.emoji);
		}
		setIsOpen(false);
	};

	const toggleOpen = () => setIsOpen(!isOpen)

	const urlRegex = /(https?:\/\/[^ ]*)/;
	const preview = postMessage.match(urlRegex) ? postMessage.match(urlRegex)![0] : ''
	const menuClass = `${isOpen ? ' show' : ''}`
	const handleCreatePost = async () => {
		try {
			setLoading(true)
			const res: any = photo ? await uploadMedia(photo) : ''
			const mentionIds:string[]=[]
			if (postMessage.includes('@[') && postMessage.includes('](') && postMessage.includes(')')) {
				postMessage.split(/\@(.*?)\)/g).forEach((word:string) => {
				  if (word.startsWith('[') && word.includes('](')) {
					const urlStartIndex = word.indexOf('(') + 1;
					mentionIds.push(word.substring(urlStartIndex));
				  }
				});
			  }
			let creatPostInput: ICreatePostInput = {
				body: postMessage,
				postedTo: postedTo || user?._id || '',
				postedToType: postedToType || 'User',
				...(!!mentionIds.length && { mentionIds })
			}
			if (res?.data) {
				creatPostInput = { ...creatPostInput, imageUrl: res.data }
			}

			const { data } = await createPost({
				...creatPostInput,
			})
			setPostMessage('')
			setPhoto(null)
			if (allPosts) setPosts && setPosts([data, ...allPosts])
			else setPosts && setPosts([data])
		} catch (err) {
			console.log('Error in handleCreatePost ,', err)
		} finally {
			setLoading(false)
		}
	}

	return (
		<AuthTag>
			<div className='card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3'>
				<div className='card-body p-0'>
					<div className='font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center border-0'>
						<i className='btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight'></i>
						Create Post
					</div>
				</div>

				<div className='card-body p-0 mt-3 position-relative'>
					<figure className='avatar position-absolute ms-2 mt-1 top-5'>
						{user?.profile?.picture?.length ? (
							<img
								src={user?.profile?.picture}
								alt={user?.username}
								className='rounded-circle w30 h30'
							/>
						) : (
							<Avatar name={user?.username} className='rounded-circle w30 h30' />
						)}
					</figure>

					{/* <textarea
						name='message'
						className='h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg'
						cols={30}
						rows={10}
						placeholder="What's on your mind?"
						value={postMessage}
						onChange={handleChange}
					></textarea> */}

					<MentionsInput 
					    id='post-box-element'
						name='message'
						className='mentionTextarea'
						cols={30}
						rows={10}
						placeholder="What's on your mind?"
						value={postMessage} 
						onChange={(e)=>handleMentionInputChange(e,'post-box-element',setPostMessage,setSuggestionList,suggestionList,user?._id)}
						allowSpaceInQuery
						allowSuggestionsAboveCursor
					>
						<Mention
							trigger="@"
							data={suggestionList}
							appendSpaceOnAdd	
							style={{color:"#1E74FD"}}
						/>
					</MentionsInput>

					<div style={{height:1, clear:"both"}}></div>

					{preview
						?
						<div className="preview-container">
							<LinkPreview
								url={preview}
								customLoader={<CreatePostPlaceholder />}
								className="card card-body p-0 rounded-xxl overflow-hidden uttam-die flex-row h-auto" />
						</div>
						:
						''
					}
				</div>

				<div className='mb-2'>
					{photo && (
						<Badge pill bg='light' className='text-grey-600 fw-500'>
							{photo?.name}
							<small
								className='text-danger mx-1 cursor-pointer'
								onClick={() => {
									setPhoto(null)
								}}
							>
								&nbsp;X
							</small>
						</Badge>
					)}
				</div>

				<div className='card-body d-flex p-0 mt-0'>
					<div
						id="dropdownMenu4"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						onClick={toggleOpen}
					>
						<i className='btn-round-sm font-md text-yellow-icon fe-smile me-2 bg-greylight pointer'></i>
					</div>
					<div
						className={`dropdown-menu p-0 rounded-xxl border-0 shadow-lg ${menuClass} theme-dark-bg`}
						aria-labelledby="dropdownMenu4"
					>
						{isOpen ? <Picker 
						pickerStyle={{
							borderRadius: "15px",
							boxShadow: "0 1rem 3rem rgb(0 0 0 / 18%)"
						}} 
						onEmojiClick={onEmojiClick} /> : <div />}
					</div>
					<a
						href='/coming-soon'
					>
						<i className='btn-round-sm font-xs text-danger feather-video me-2 bg-greylight ms-2'></i>
					</a>

					<input
						type='file'
						onChange={event => {
							if (event.target.files && event.target.files[0]) {
								let img = event.target.files[0]
								setPhoto(img)
							}
						}}
						name='file'
						id='file'
						className='d-none'
					/>

					<label
						htmlFor='file'
					>
						<i className='btn-round-sm font-xs text-success feather-image me-2 bg-greylight pointer ms-2'></i>
					</label>

					<button
						onClick={() => {
							if (postMessage) {
								handleCreatePost()
							}
						}}
						className={`p-2 lh-20 w100 text-white bg-dark text-center font-xssss fw-600 ls-1 rounded-xl border-0 ms-auto`}
					>
						{loading ? (
							<Spinner animation='border' size='sm' />
						) : (
							'Post'
						)}
					</button>
				</div>
			</div>
		</AuthTag>
	)
}

export default CreatePost
