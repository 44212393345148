import { useContext, useState } from "react";
import ReactModal from "react-modal";
import { Spinner } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { addReview, editReview } from "../../../services/review.service";
import { toast } from "react-toastify";
import {
  IGame,
  IGamesReviewPayload,
  IReviewPayload,
} from "../../../types/global.interface";
import { useParams } from "react-router-dom";
import RatingField from "../../common/RatingField";
import * as Yup from "yup";
import AuthTag from "../../common/AuthTag";
import { AppContext } from "../../../contexts";
import Avatar from "react-avatar";

ReactModal.setAppElement("#root");

const AddReviewSchema = Yup.object().shape({
  body: Yup.string().required("Required"),
});

const CreateReview = ({
  review,
  refetch,
  game,
}: {
  review?: IGamesReviewPayload | null;
  refetch?: Function;
  game?: IGame | undefined;
}) => {
  const { user } = useContext(AppContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const { id } = useParams<{ id: string }>();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const initialValues: IReviewPayload = {
    game: id || "",
    experience: review?.experience || "positive",
    difficulty: review?.difficulty || "easy",
    hoursPlayed: review?.hoursPlayed || ">100h",
    rating: review?.rating || 1,
    body: review?.body || "",
  };

  const alreadyReviewed = game?.reviews?.find(
    (r) => r?.owner?._id === user?._id
  )
    ? true
    : false;

  return (
    <div>
      <AuthTag>
        {!review ? (
          <button
            className={`${
              !alreadyReviewed ? "pointer" : ""
            } bg-greylight p-2 lh-20 w100 me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border-0 text-grey-700`}
            onClick={openModal}
            disabled={alreadyReviewed}
          >
            {alreadyReviewed ? "Reviewed" : "Review"}
          </button>
        ) : (
          <div
            className="nav-content-bttn open-font p-2 fw-600 text-dark d-flex pointer"
            onClick={openModal}
          >
            <i className="feather-edit text-grey-500 me-2 font-sm"></i>
            <span className="font-xsss">Edit</span>
          </div>
        )}
      </AuthTag>

      <ReactModal
        isOpen={modalIsOpen}
        contentLabel="onRequestClose Example"
        onRequestClose={closeModal}
        className="Modal modal-card card shadow-xss rounded-xxl border-0 p-4 mb-3"
        overlayClassName="Overlay"
      >
        <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">
          {review ? "Edit Review" : "Write Review"}
          <i
            className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 pointer"
            onClick={closeModal}
          ></i>
        </h4>
        <Formik
          validationSchema={AddReviewSchema}
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            try {
              const { data } = review
                ? await editReview({ id: review._id, payload: values })
                : await addReview({
                    ...values,
                  });
              // data needs to be assigned, is otherwise unassigned
              refetch && refetch();
              closeModal();
              actions.setSubmitting(false);
              toast.success("Thanks for submitting your review!");
            } catch (error) {
              console.log("error", error);
            }
          }}
        >
          {(props) => (
            <Form>
              <Field type="hidden" name="game" />
              <div className="card-body p-0 mb-3 position-relative">
                <div className="text-grey-500 fw-500">Rating</div>
                <Field name="rating" component={RatingField} />
              </div>
              <div className="card-body p-0 mb-4 position-relative">
                <div className="text-grey-500 fw-500">Experience</div>
                <Field
                  as="select"
                  className="form-select"
                  name="experience"
                  id="experience"
                >
                  <option value="positive">Positive</option>
                  <option value="neutral">Neutral</option>
                  <option value="negative">Negative</option>
                </Field>
              </div>
              <div className="card-body p-0 mb-4 position-relative">
                <div className="text-grey-500 fw-500">Difficulty</div>
                <Field
                  as="select"
                  className="form-select"
                  name="difficulty"
                  id="difficulty"
                >
                  <option value="easy">Easy</option>
                  <option value="normal">Normal</option>
                  <option value="hard">Hard</option>
                </Field>
              </div>
              <div className="card-body p-0 mb-4 position-relative">
                <div className="text-grey-500 fw-500">Time Spent</div>
                <Field
                  as="select"
                  className="form-select"
                  name="hoursPlayed"
                  id="hoursPlayed"
                >
                  <option value=">100h">{">100h"}</option>
                  <option value=">24h">{">24h"}</option>
                  <option value="<24h">{"<24h"}</option>
                </Field>
              </div>
              <div className="card-body p-0 position-relative">
                <div className="text-grey-500 fw-500">Review</div>
                <div className="card-body p-0 position-relative">
                  <figure className="avatar position-absolute ms-2 mt-1 top-5">
                    {user?.profile?.picture?.length ? (
                      <img
                        src={user?.profile?.picture}
                        alt={user?.username}
                        className="rounded-circle h30 w30"
                      />
                    ) : (
                      <Avatar
                        name={user?.username}
                        className="rounded-circle h30 w30"
                      />
                    )}
                  </figure>
                  <Field
                    as="textarea"
                    name="body"
                    className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                    cols={30}
                    rows={10}
                    placeholder="What's on your mind?"
                  />
                  {props.errors.body && props?.touched.body ? (
                    <small className="text-danger">{props.errors.body}</small>
                  ) : null}
                </div>
              </div>
              <button
                className="p-2 mt-3 lh-20 w100 bg-primary-gradiant me-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0"
                type="submit"
                disabled={props.isSubmitting}
              >
                {props.isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Submit"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </ReactModal>
    </div>
  );
};

export default CreateReview;
