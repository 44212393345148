import axios from "../http-common";

export const registerUser = async (data: {
  username: string;
  email: string;
  password: string;
  confirmPassword?: string;
  token: string;
}) => {
  try {
    delete data.confirmPassword;
    return await axios.post("/auth/signup", {
      ...data,
    });
  } catch (error) {
    throw error;
  }
};
