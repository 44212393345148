import axios from "../http-common";
import {
  IGamesReviewPayload,
  IUserData,
  IUserMeta,
  LoginResult,
} from "../types/global.interface";

export const getallUsers = async ({
  page,
  limit = 10,
}: {
  page?: string | number;
  limit?: number;
}) => {
  try {
    return await axios.get("/users", {
      params: {
        page,
        limit,
      },
    });
  } catch (error) {
    throw error;
  }
};
export const getallPopularUsers = async ({
  page,
  limit = 10,
}: {
  page?: string | number;
  limit?: number;
}) => {
  try {
    return await axios.get("/users/popular", {
      params: {
        page,
        limit,
      },
    });
  } catch (error) {
    throw error;
  }
};

/**
 * @description API call to fetch user by Id
 * @param query string
 * @returns Axios repsonse
 */
export const getUserById = async (_id: string) => {
  try {
    return await axios.get<IUserData>(`/users/${_id}`)
  } catch (error) {
    throw error;
  }
};

/**
 * @description API call to search by string
 * @param query string
 * @returns Axios repsonse
 */
export const searchUser = async (query: string, excludeUsers?: string[]) => {
  try {
    return await axios.get(`/users/search`, {
      params: {
        q: query,
        ...(!!excludeUsers?.length && { excludeUsers })
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getUserByAccessToken = async () => {
  try {
    return await axios.get("/auth/me", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (err) {
    // console.log("Error in getUserByAccessToken", err);
  }
};

export const verifyEmail = async ({
  userId,
  token,
}: {
  userId: string;
  token: string;
}) => {
  try {
    return await axios.get<LoginResult>("/auth/verify", {
      params: {
        token,
        id: userId,
      },
    });
  } catch (err) {
    console.log("Error in verifyEmail", err);
    throw err;
  }
};
interface Profile {
  description?: string;
  telephone?: string;
  country?: string;
  state?: string;
  zip?: string;
  city?: string;
  street?: string;
  company?: string;
  picture?: string;
  lastname?: string;
  firstname?: string;
  username?: string;
}

export const updateUserProfile = async (data: Profile) => {
  try {
    return await axios.patch("/users/profile", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (err) {
    console.log("Error in updateUserProfile", err);
  }
};
/**
 * @description Follow user by id
 * @returns Promise
 */
export const followUser = async ({ id }: { id: string }) => {
  try {
    return await axios.post(
      `/users/follow/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const unFollowUser = async ({ id }: { id: string }) => {
  try {
    return await axios.delete(`/users/unfollow/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getUserMeta = async ({ id }: { id: string }) => {
  try {
    return await axios.get<IUserMeta>(`/users/meta/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    throw error;
  }
};
export const fetchUserReviews = async ({ id }: { id: string }) => {
  try {
    return await axios.get<IGamesReviewPayload[]>(`/reviews/${id}/user`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    throw error;
  }
};
