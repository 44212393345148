import { Link } from 'react-router-dom'
import Darkbutton from '../../Header/DarkButton'

const LeftBanner = (): JSX.Element => {
	return (
		<>
			<div className='nav-header bg-transparent shadow-none border-0'>
				<div className='nav-top w-100'>
					<a href='/'>
						<i className='text-success display2-size m-3'>
							<img
								className='logo logo-white'
								src='../assets/images/brand/mainlogo.svg'
								alt='Unigamer'
							></img>
						</i>
					</a>

					<span className='p-2 pointer text-center ms-auto menu-icon'></span>

					<Darkbutton />

					<Link
						to='/login'
						className='header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 w100 text-center lh-20 ms-2 rounded-xl'
					>
						Login
					</Link>

					<Link
						to='/register'
						className='header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl'
					>
						Register
					</Link>
				</div>
			</div>
		</>
	)
}

export default LeftBanner
