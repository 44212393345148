import { useState } from 'react'
import ReactModal from 'react-modal'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { deleteComment } from '../../../services/comment.service'

const DeleteCommentModal = ({
	commentId,
	refetchComments,
}: {
	commentId: string
	refetchComments: Function
}) => {
	const [showModal, setShowModal] = useState(false)
	const [loading, setLoading] = useState(false)

	const closeModal = () => {
		setShowModal(false)
	}

	const handleDelete = async () => {
		try {
			setLoading(true)
			await deleteComment(commentId)
			await refetchComments()
		} catch (error) {
			toast.error('Error while deleting comment!')
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<div
				className='d-flex align-items-center fw-600 text-grey-500 text-dark lh-26 font-xssss pointer border-0 ms-3'
				onClick={() => {
					if (!loading) {
						setShowModal(true)
					}
				}}
			>
				<span className='d-none-xss'>
					<u>Delete</u>
				</span>
			</div>
			<ReactModal
				isOpen={showModal}
				contentLabel='onRequestClose Example'
				onRequestClose={closeModal}
				className='Modal modal-card card shadow-xss rounded-xxl border-0 p-4 mb-3'
				overlayClassName='Overlay'
			>
				<h4 className='fw-700 font-xss text-grey-900 d-flex align-items-center'>
					Delete comment
					<i
						className='feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 pointer'
						onClick={closeModal}
					></i>
				</h4>

				<div className='card-body p-0 d-flex'>
					<p className='fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2'>
						Are you sure you want delete this comment?
					</p>
				</div>

				<div className='d-flex align-items-center'>
					<button
						className='p-2 lh-20 w100 bg-primary-gradiant me-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0'
						onClick={handleDelete}
						disabled={loading}
					>
						{loading ? <Spinner animation='border' size='sm' /> : 'Delete'}
					</button>

					<button
						className='p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl border-0'
						onClick={closeModal}
					>
						Cancel
					</button>
				</div>
			</ReactModal>
		</>
	)
}

export default DeleteCommentModal
