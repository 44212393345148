import React from 'react'

class MHomeLayout extends React.Component<any> {
	public render() {
		return (
			<div className='col-xl-8 col-xxl-9 col-lg-8'>{this.props.children}</div>
		)
	}
}

export default MHomeLayout
