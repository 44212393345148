import React, { useState } from "react";
import ReactModal from "react-modal";
import { Spinner } from "react-bootstrap";

import { deleteReview } from "../../../services/review.service";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement("#root");

const DeleteReview = ({
  id,
  refetch
}: {
  id: string;
  refetch: Function
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteReview({ id });
      await refetch();
      closeModal();
    } catch (err) {
      console.log("Error::", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        className="nav-content-bttn open-font p-2 fw-600 text-dark d-flex pointer"
        onClick={openModal}
      >
        <i className="feather-trash-2 text-danger me-2 font-sm"></i>
        <span className="font-xsss">Delete</span>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        contentLabel="onRequestClose Example"
        onRequestClose={closeModal}
        className="Modal modal-card card shadow-xss rounded-xxl border-0 p-4 mb-3"
        overlayClassName="Overlay"
      >
        <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">
          Delete Post
          <i
            className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 pointer"
            onClick={closeModal}
          ></i>
        </h4>
        <div className="card-body p-0 d-flex">
          <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
            Are you sure you want delete this post?
          </p>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="p-2 lh-20 w100 bg-primary-gradiant me-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0"
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Delete"
            )}
          </button>
          <button
						className='p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl border-0'
						onClick={closeModal}
					>
						Cancel
					</button>
        </div>
      </ReactModal>
    </div>
  );
};

export default DeleteReview;
