import { toast, ToastOptions } from "react-toastify";

export const showNotification = (
  msg: string,
  type: "success" | "error",
  options: ToastOptions
) => {
  if (type === "success") {
    toast.success(msg, options);
  } else if (type === "error") {
    toast.error(msg, options);
  }
};
