const NotificationPlaceholder = () => {
	return (
		<>
			<li>
				<a href='/#' className='rounded-3 notification-li'>
					<div className='notification-user'>
						<div className='rounded-circle h30 w30 me-2 loading' />

						<h6 className='font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700'>
							<span className='loading'>Placeholder</span>
						</h6>
					</div>

					<div className='notification-description text-grey-900 text-dark fw-600 font-xssss'>
						<i className='feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2 loading'></i>
						<span className='loading'>Placeholder</span>
					</div>

					<div className='ms-2 notification-text text-grey-500 fw-600 font-xssss'>
						<span className='loading'>PlaceholderPlaceholderPlaceholder</span>
					</div>

					<div className='notification-time text-grey-500 fw-600'>
						<span className='loading'>time</span>
					</div>
				</a>
			</li>
			<li>
				<a href='/#' className='rounded-3 notification-li'>
					<div className='notification-user'>
						<div className='rounded-circle h30 w30 me-2 loading' />

						<h6 className='font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700'>
							<span className='loading'>Placeholder</span>
						</h6>
					</div>
					<div className='notification-description text-grey-900 text-dark fw-600 font-xssss'>
						<i className='feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2 loading'></i>

						<span className='loading'>Placeholder</span>
					</div>
					<div className='ms-2 notification-text text-grey-500 fw-600 font-xssss'>
						<span className='loading'>PlaceholderPlaceholderPlaceholder</span>
					</div>
					<div className='notification-time text-grey-500 fw-600'>
						<span className='loading'>time</span>
					</div>
				</a>
			</li>
			<li>
				<a href='/#' className='rounded-3 notification-li'>
					<div className='notification-user'>
						<div className='rounded-circle h30 w30 me-2 loading' />

						<h6 className='font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700'>
							<span className='loading'>Placeholder</span>
						</h6>
					</div>
					<div className='notification-description text-grey-900 text-dark fw-600 font-xssss'>
						<i className='feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2 loading'></i>

						<span className='loading'>Placeholder</span>
					</div>
					<div className='ms-2 notification-text text-grey-500 fw-600 font-xssss'>
						<span className='loading'>PlaceholderPlaceholderPlaceholder</span>
					</div>
					<div className='notification-time text-grey-500 fw-600'>
						<span className='loading'>time</span>
					</div>
				</a>
			</li>

			<li>
				<a href='/#' className='rounded-3 notification-li'>
					<div className='notification-user'>
						<div className='rounded-circle h30 w30 me-2 loading' />

						<h6 className='font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700'>
							<span className='loading'>Placeholder</span>
						</h6>
					</div>
					<div className='notification-description text-grey-900 text-dark fw-600 font-xssss'>
						<i className='feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2 loading'></i>

						<span className='loading'>Placeholder</span>
					</div>
					<div className='ms-2 notification-text text-grey-500 fw-600 font-xssss'>
						<span className='loading'>PlaceholderPlaceholderPlaceholder</span>
					</div>
					<div className='notification-time text-grey-500 fw-600'>
						<span className='loading'>time</span>
					</div>
				</a>
			</li>

			<li>
				<a href='/#' className='rounded-3 notification-li'>
					<div className='notification-user'>
						<div className='rounded-circle h30 w30 me-2 loading' />

						<h6 className='font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700'>
							<span className='loading'>Placeholder</span>
						</h6>
					</div>

					<div className='notification-description text-grey-900 text-dark fw-600 font-xssss'>
						<i className='feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2 loading'></i>

						<span className='loading'>Placeholder</span>
					</div>

					<div className='ms-2 notification-text text-grey-500 fw-600 font-xssss'>
						<span className='loading'>PlaceholderPlaceholderPlaceholder</span>
					</div>

					<div className='notification-time text-grey-500 fw-600'>
						<span className='loading'>time</span>
					</div>
				</a>
			</li>

			<li>
				<a href='/#' className='rounded-3 notification-li'>
					<div className='notification-user'>
						<div className='rounded-circle h30 w30 me-2 loading' />

						<h6 className='font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700'>
							<span className='loading'>Placeholder</span>
						</h6>
					</div>

					<div className='notification-description text-grey-900 text-dark fw-600 font-xssss'>
						<i className='feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2 loading'></i>
						<span className='loading'>Placeholder</span>
					</div>

					<div className='ms-2 notification-text text-grey-500 fw-600 font-xssss'>
						<span className='loading'>PlaceholderPlaceholderPlaceholder</span>
					</div>

					<div className='notification-time text-grey-500 fw-600'>
						<span className='loading'>time</span>
					</div>
				</a>
			</li>
			<li>
				<a href='/#' className='rounded-3 notification-li'>
					<div className='notification-user'>
						<div className='rounded-circle h30 w30 me-2 loading' />

						<h6 className='font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700'>
							<span className='loading'>Placeholder</span>
						</h6>
					</div>

					<div className='notification-description text-grey-900 text-dark fw-600 font-xssss'>
						<i className='feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2 loading'></i>
						<span className='loading'>Placeholder</span>
					</div>

					<div className='ms-2 notification-text text-grey-500 fw-600 font-xssss'>
						<span className='loading'>PlaceholderPlaceholderPlaceholder</span>
					</div>

					<div className='notification-time text-grey-500 fw-600'>
						<span className='loading'>time</span>
					</div>
				</a>
			</li>
			<li>
				<a href='/#' className='rounded-3 notification-li'>
					<div className='notification-user'>
						<div className='rounded-circle h30 w30 me-2 loading' />

						<h6 className='font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700'>
							<span className='loading'>Placeholder</span>
						</h6>
					</div>

					<div className='notification-description text-grey-900 text-dark fw-600 font-xssss'>
						<i className='feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2 loading'></i>
						<span className='loading'>Placeholder</span>
					</div>

					<div className='ms-2 notification-text text-grey-500 fw-600 font-xssss'>
						<span className='loading'>PlaceholderPlaceholderPlaceholder</span>
					</div>

					<div className='notification-time text-grey-500 fw-600'>
						<span className='loading'>time</span>
					</div>
				</a>
			</li>
			<li>
				<a href='/#' className='rounded-3 notification-li'>
					<div className='notification-user'>
						<div className='rounded-circle h30 w30 me-2 loading' />

						<h6 className='font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700'>
							<span className='loading'>Placeholder</span>
						</h6>
					</div>

					<div className='notification-description text-grey-900 text-dark fw-600 font-xssss'>
						<i className='feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2 loading'></i>
						<span className='loading'>Placeholder</span>
					</div>

					<div className='ms-2 notification-text text-grey-500 fw-600 font-xssss'>
						<span className='loading'>PlaceholderPlaceholderPlaceholder</span>
					</div>

					<div className='notification-time text-grey-500 fw-600'>
						<span className='loading'>time</span>
					</div>
				</a>
			</li>
		</>
	)
}

export default NotificationPlaceholder
