import { useContext, useState } from "react";
import Avatar from "react-avatar";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../../contexts";
import { followUser, unFollowUser } from "../../../services/user.service";
import { User } from "../../../types/global.interface";
import AuthTag from "../../common/AuthTag";

const UserCard = ({
  user,
  userList,
  setUserList,
}: {
  user: User | null;
  setUserList: Function;
  userList: (User | null)[];
}) => {
  const { user: contextUser } = useContext(AppContext);
  const [followLoading, setFollowLoading] = useState(false);

  const isFollowed = user?.followerUsers.includes(contextUser?._id || "");

  const handleFollow = async ({ id, type }: { id: string; type: string }) => {
    try {
      setFollowLoading(true);
      let followingUser = userList.find((u) => u?._id === id);
      const followingUserIndex = userList.findIndex((u) => u?._id === id);

      if (followingUser && followingUserIndex>=0 && contextUser) {
        if (type === "follow") {
          await followUser({ id });
          followingUser = {
            ...followingUser,
            followerUsers: [...followingUser.followerUsers, contextUser?._id],
          };
        } else if (type === "unfollow") {
          await unFollowUser({ id });
          followingUser = {
            ...followingUser,
            followerUsers: [
              ...followingUser.followerUsers.filter(
                (u) => u !== contextUser._id
              ),
            ],
          };
        }
        setUserList([
          ...userList.slice(0, followingUserIndex),
          followingUser,
          ...userList.slice(followingUserIndex + 1),
        ]);
      }
    } catch (err) {
    } finally {
      setFollowLoading(false);
    }
  };

  return (
    <div className="card w150 d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3 me-3 ">
      <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
        <Link to={`/profile/${user?._id}`}>
          <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
            {user?.profile?.picture?.length ? (
              <img
                src={user?.profile?.picture}
                alt={user?.username}
                className="float-right p-0 rounded-circle w65 h65"
              />
            ) : (
              <Avatar
                name={user?.username}
                className="float-right p-0 rounded-circle w65 h65"
              />
            )}
          </figure>
        </Link>
        <div className="clearfix"></div>
        <h4
          className="fw-700 font-xssss mt-3 mb-1 d-block w-100 trnc-game-card"
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {user?.username}{" "}
        </h4>
        <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-2">
          @{user?.username}
        </p>
        <AuthTag>
          <button
            onClick={() =>
              handleFollow({
                id: user?._id || "",
                type: isFollowed ? "unfollow" : "follow",
              })
            }
            disabled={followLoading}
            className={`text-center p-2 lh-20 w100 ms-1 ls-3 d-inline-block rounded-xl ${
              isFollowed ? "bg-primary-gradiant-active" : "bg-primary-gradiant"
            } font-xsssss fw-700 ls-lg border-0`}
          >
            {followLoading ? (
              <Spinner animation="border" size="sm" />
            ) : isFollowed ? (
              " Following"
            ) : (
              " Follow"
            )}
          </button>
        </AuthTag>
      </div>
    </div>
  );
};
export default UserCard;
