import ReactModal from "react-modal";
import { Formik, Form, Field } from "formik";
import RatingField from "../../../../common/RatingField";
import { Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { getPoint } from "../../../../../helpers/carmPoint";
import { FC } from "react";
import { addGradeReview } from "../../../../../services/review.service";
import { toast } from "react-toastify";
import { IReview } from "../../../../../types/review.interface";

ReactModal.setAppElement("#root");

const AddReviewSchema = Yup.object().shape({
  authentic: Yup.string().required("Required"),
  relevant: Yup.string().required("Required"),
  complete: Yup.string().required("Required"),
  correct: Yup.string().required("Required"),
});

interface Props {
  open: boolean;
  item: IReview;
  setOpen: Function;
  refetch: Function;
  handleModal: () => void;
}

const CarmModal: FC<Props> = (props) => {
  const { open, handleModal, item, setOpen, refetch } = props;

  const initialValues: any = {
    authentic: "",
    relevant: "",
    complete: "",
    correct: "",
  };

  return (
    <div>
      <ReactModal
        isOpen={open}
        contentLabel="onRequestClose Example"
        className="Modal modal-card card shadow-xss rounded-xxl border-0 p-4 mb-3"
        overlayClassName="Overlay"
      >
        <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">
          Grade Review
          <i
            onClick={() => {
              handleModal();
            }}
            className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 pointer"
          ></i>
        </h4>
        <div className="text-grey-500 fw-500 font-xssss">
          Please rate this game review based on the following factors to help
          improve content quality on Unigamer
        </div>
        <Formik
          validationSchema={AddReviewSchema}
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            console.log(values);
            const grade = getPoint(values);
            const data = {
              review: item?._id,
              grade,
            };
            try {
              const response = await addGradeReview({ ...data });
              actions.setSubmitting(false);
              toast.success("Thanks for submitting your grade review!");
              refetch && refetch();
              setOpen(false);
            } catch (error: any) {
              console.log("error", error);
              toast.error(
                error?.response?.data?.message ||
                  "Error while saving grade review"
              );
            }
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Field type="hidden" name="game" />
              <div className="card-body p-0 mb-3 mt-3 position-relative">
                <div className="text-grey-900 fw-600 ">Authenticity</div>
                <div className="text-grey-500 fw-500 font-xssss">
                  It is defined as the quality or condition for the review to be
                  authentic, genuine and trustworthy
                </div>
                <Field name="authentic" component={RatingField} />
                {touched.authentic && Boolean(errors.authentic) && (
                  <p className="text-danger">{errors.authentic}</p>
                )}
              </div>
              <div className="card-body p-0 mb-3 position-relative">
                <div className="text-grey-900 fw-600">Relevancy</div>
                <div className="text-grey-500 fw-500 font-xssss">
                  It is defined as the quality or condition for the review to be
                  appropriate to the current context
                </div>
                <Field name="relevant" component={RatingField} />
                {touched.relevant && Boolean(errors.relevant) && (
                  <p className="text-danger">{errors.relevant}</p>
                )}
              </div>
              <div className="card-body p-0 mb-3 position-relative">
                <div className="text-grey-900 fw-600">Completeness</div>
                <div className="text-grey-500 fw-500 font-xssss">
                  It is defined as the quality or condition for the review to be
                  exhaustive and complete in its entirety
                </div>
                <Field name="complete" component={RatingField} />
                {touched.complete && Boolean(errors.complete) && (
                  <p className="text-danger">{errors.complete}</p>
                )}
              </div>
              <div className="card-body p-0 mb-3 position-relative">
                <div className="text-grey-900 fw-600">Accuracy</div>
                <div className="text-grey-500 fw-500 font-xssss">
                  It is defined as the quality or condition for the review to be
                  specific, definite and vivid
                </div>
                <Field name="correct" component={RatingField} />
                {touched.correct && Boolean(errors.correct) && (
                  <p className="text-danger">{errors.correct}</p>
                )}
              </div>
              <button
                className="p-2 mt-3 lh-20 w100 bg-primary-gradiant me-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Submit"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </ReactModal>
    </div>
  );
};

export default CarmModal;
