import { Fragment, useContext } from 'react'
import { Navigate } from 'react-router-dom'

import CLogin from '../../../components/Auth/Login'
import { AppContext } from '../../../contexts'
import { Helmet } from "react-helmet";

const Login = (): JSX.Element => {
	const { user } = useContext(AppContext)

	if (user) {
		return <Navigate to='/home' />
	}

	return (
		<Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Login Page</title>
				<link rel="canonical" href={`https://unigamer.com/login`} />
			</Helmet>
			<CLogin />
		</Fragment>
	)
}

export default Login
