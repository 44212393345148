const GProfileCardPlaceholder = (): JSX.Element => {
	return (
		<div className='card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 pb-3'>
			<div
				className='card-body h250 p-0 rounded-xxl overflow-hidden m-3'
				style={{ height: '250px' }}
			>
				<div className='profile-banner loading' />
			</div>

			<div className='card-body d-block pt-4 text-center'>
				<figure className='avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto'>
					<div className='p-1 bg-white rounded-xl h100 w100 border-solid loading' />
				</figure>

				<h4 className='font-xs ls-1 fw-700 text-grey-900'>
					<span className='loading'>Placeholder</span>
					<span className='d-block font-xssss fw-500 mt-1 lh-3 text-grey-500'>
						<span className='text-uppercase bg-grey live-tag mt-2 mb-0 p-1 pe-2 ps-2 z-index-1 rounded-3 font-xsssss ls-3 loading'>
							PS4
						</span>
					</span>
				</h4>
			</div>

			<div className='card-body d-flex align-items-center ps-4 pe-4 pt-0'>
				<h4 className='font-xsssss text-center text-grey-500 fw-600 ms-2 me-2'>
					<b className='text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark'>
						<span className='loading'>Posts</span>
					</b>{' '}
				</h4>

				<h4 className='font-xsssss text-center text-grey-500 fw-600 ms-2 me-2'>
					<b className='text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark'>
						<span className='loading'>Followers</span>
					</b>{' '}
				</h4>

				<h4 className='font-xsssss text-center text-grey-500 fw-600 ms-2 me-2'>
					<b className='text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark'>
						<span className='loading'>Reviews</span>
					</b>{' '}
				</h4>
			</div>

			<div className='card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0'>
				<div className='p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border-0 loading'>
					Follow
				</div>

				<div className='p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border-0 loading'>
					Review
				</div>

				<div className='ms-auto'>
					<i className='ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss loading'></i>
				</div>
			</div>

			<div className='card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs'>
				<ul className='nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4'>
					<li className='active list-inline-item me-5'>
						<div className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block'>
							Posts
						</div>
					</li>

					<li className='list-inline-item me-5'>
						<div className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block'>
							Reviews
						</div>
					</li>

					<li className='list-inline-item me-5'>
						<div className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block'>
							About
						</div>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default GProfileCardPlaceholder
