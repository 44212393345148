import { Link } from 'react-router-dom'
import { Fragment, useContext, useRef, useState } from "react";
import { useOnLoadImages } from "../../hooks/useOnLoadImages";
import './index.css'
import { AppContext } from '../../contexts';
import { SocketContext } from '../../contexts/socket';
import Avatar from 'react-avatar';
import { Helmet } from "react-helmet";

const LandingPage = (): JSX.Element => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const imagesLoaded = useOnLoadImages(wrapperRef);
	const { user, setUser, setIsLoggedIn, isLoading } = useContext(AppContext);
	const { socket } = useContext(SocketContext);
	const [isOpen, setIsOpen] = useState(false);

	const toggleOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleLogout = () => {
		setUser(null);
		setIsLoggedIn(false);
		socket?.disconnect();
		localStorage.removeItem("token");
	};
	return (
		<div ref={wrapperRef}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Unigamer - Connecting the World of Gaming</title>
				<link rel="canonical" href={`https://unigamer.com`} />
			</Helmet>
			{!imagesLoaded ? <></> :
				<Fragment>
					<div className='nav-header home absolute bg-transparent shadow-none border-0'>
						<div className='nav-top w-100'>
							<a href='/'>
								<i className='text-success display2-size m-3'>
									<img
										className='logo white'
										src='assets/images/brand/mainlogo.svg'
										alt='Unigamer logo' />
								</i>
							</a>
						</div>
						<span className="p-2 pointer text-center ms-auto menu-icon">

						</span>

						{isLoading ? (
							<Fragment>
								{!user ? (
									<div className="p-0 ms-3 rounded-circle w40 h40 loading d-none-xs" />
								) : (
									<></>
								)}
							</Fragment>
						) : user ? (
							<Fragment>
								<div
									className={`p-0 ms-3 menu-icon pointer`}
									id="dropdownMenu4"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									onClick={toggleOpen}
								>
									{user?.profile?.picture?.length ? (
										<img
											src={user?.profile?.picture}
											alt={user?.username}
											className="rounded-circle w40 h40"
										/>
									) : (
										<Avatar
											name={user?.username}
											className="rounded-circle w40 h40"
										/>
									)}
								</div>
								<div
									className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg theme-dark-bg`}
									aria-labelledby="dropdownMenu4"
								>
									<h4 className="fw-700 p-2 font-xss text-grey-900 d-flex align-items-center">
										Settings
										<i
											className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900"
											onClick={toggleOpen}
										></i>
									</h4>

									<Link to="/me">
										<div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
											<i className="feather-user text-grey-500 me-2 font-sm"></i>
											<div className="fw-600 mb-0 text-dark me-4">
												<span className="font-xsss">Profile</span>
											</div>
										</div>
									</Link>

									<Link to="/settings">
										<div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
											<i className="feather-settings text-grey-500 me-2 font-sm"></i>
											<div className="fw-600 mb-0 text-dark me-4">
												<span className="font-xsss">Settings</span>
											</div>
										</div>
									</Link>

									{!!user.claimGames.length && (
										<Link to={`/analytics/${user.claimGames[0]}`}>
											<div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
												<i className="feather-layout text-primary me-2 font-sm"></i>
												<div className="fw-600 mb-0 text-dark me-4">
													<span className="font-xsss">Dashboard</span>
												</div>
											</div>
										</Link>
									)}

									<div
										className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer"
										onClick={handleLogout}
									>
										<i className="feather-log-out text-danger me-2 font-sm"></i>
										<div className="fw-600 mb-0 text-dark me-4">
											<span className="font-xsss">Logout</span>
										</div>
									</div>
								</div>
							</Fragment>
						) : (
							<Fragment>
								<Link
									to="/login"
									className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-2 w100-l text-center lh-20 rounded-xl"
								// onClick={handleLogout}
								>
									Login
								</Link>
								<Link
									to="/register"
									className="header-btn d-none d-lg-block bg-white fw-500 text-dark font-xsss p-3 ms-2 w100-l text-center lh-20 rounded-xl"
								// onClick={handleLogout}
								>
									Register
								</Link>
							</Fragment>
						)}

					</div>
					<div
						className='banner-wrapper vh-100 bscover demo-style pe-4 ps-4'
						style={{
							backgroundImage: `url("assets/images/landingpage/banner-bg-1.svg")`,
						}}
					>
						<div className='banner-content'>
							<div className='container max-container'>
								<div className='row'>
									<div className='col-lg-5 col-md-6 col-sm-8'>
										<h1 className='title-text2 text-white mt-5'>
											<b>Welcome to Unigamer</b>
										</h1>

										<p className="font-xssss text-white mb-5">
											Our Mission is to bridge the world of gaming by connecting Gamers with Game Studios.{' '}
										</p>

										<p className='d-inline-block text-white'>
											20k{' '}
											<span className="text-white">
												Game <br /> Profiles
											</span>
										</p>

										<p className='d-inline-block text-white'>
											1k{' '}
											<span className="text-white">
												User <br /> Experiences
											</span>
										</p>

										<p className='d-inline-block text-white'>
											1k{' '}
											<span className="text-white">
												Content <br /> Contributions
											</span>
										</p>

										<div className='clearfix'></div>

										<a
											href='/home'
											className='btn pb-3 pt-3 ps-5 pe-5 bg-dark text-white mr-4 text-uppercase mt-4'
										>
											Discover
										</a>
									</div>
								</div>
							</div>
						</div>
					</div><div className='section pb100 pt50 demo-style pe-4 ps-4' id='feature'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-6'>
									<img
										src='assets/images/landingpage/com-1.png'
										alt='Image with parts of unigamer software'
										className='img-fluid' />
								</div>

								<div className='col-lg-5 offset-lg-1'>
									<h2 className='title-text2 mb-4 mt-5'>
										<b>Become a Unigamer</b>
									</h2>{' '}
									<p>
										Join the fastest growing social gaming community now and become a top contributor to make an impact on successful game releases.{' '}
									</p>
									<a
										href='/home'
										className='btn pb-3 pt-3 ps-5 pe-5 bg-blue text-white mr-4 text-uppercase mt-4'
									>
										Join
									</a>
								</div>
							</div>
						</div>
					</div><div className='section pb100 pt50 demo-style pe-4 ps-4' id='feature'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-5 offset-lg-1'>
									<h2 className='title-text2 mb-4 mt-5'>
										<b>Are you a game studio?</b>
									</h2>{' '}
									<p>
										We can help you deliver successful games FOR gamers.{' '}
									</p>
									<a
										href='https://share.hsforms.com/1OEM-SEUPT7OHvL8QHHFf_gc5p0m'
										className='btn pb-3 pt-3 ps-5 pe-5 bg-blue text-white mr-4 text-uppercase mt-4'
									>
										Learn More
									</a>
								</div>
								<div className='col-lg-6'>
									<img
										src='assets/images/landingpage/analytics.png'
										alt='Some graphics with metrics data'
										className='img-fluid' />
								</div>
							</div>
						</div>
					</div><div className='p100 bg-black demo-style' id='contact'>
						<div className='container'>
							<div className='row justify-content-center'>
								<div className='col-lg-6 text-center'>
									<h2 className='title-text2 text-white mt-4'>
										<b>Bridging the World of Gaming</b>
									</h2>

									<p className='text-white ml-5 mr-5'>
										Unigamer bridges the World of Gaming by connecting Gamers with Game Studios.
									</p>

									<div className='col-sm-12 text-center mt-5'>
										<a
											href='/home'
											className='btn pb-3 pt-3 ps-5 pe-5 bg-white text-uppercase'
										>
											join now
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			}
		</div>
	)
}

export default LandingPage
