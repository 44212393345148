import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import PopularUsersPlaceholder from "../../../placeholder/home/PopularUsers";
import { getallPopularUsers } from "../../../services/user.service";
import { ListUserQuery, User } from "../../../types/global.interface";

const PopularUsers = () => {
  const [userList, setUserList] = useState<(User | null)[]>([]);
  const [loading, setLoading] = useState(false);
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const {
        data,
      }: {
        data: ListUserQuery;
      } = await getallPopularUsers({ page: 1, limit: 3 });
      if (data.users) {
        setUserList([...data?.users]);
      }
    } catch (error) {
      // TODO: Show error toast with message
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <>
      {!loading && (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
          <div className="card-body d-flex align-items-center p-4">
            <span className="fw-700 mb-0 font-xssss text-grey-500">
              Popular Members
            </span>

          </div>
          {userList.map((value, index) => {
            return (
              <div
                key={index}
                className="card-body bg-transparent-card d-flex pt-0 ps-4 pe-4"
              >
                <figure className="avatar me-3">
                <a href={`/profile/${value?._id}`}>
                  {value?.profile?.picture?.length ? (
                    <img
                      src={value?.profile?.picture}
                      alt={value?.username}
                      className="rounded-circle h45 w45"
                    />
                  ) : (
                    <Avatar
                      name={value?.username}
                      className="rounded-circle h45 w45"
                    />
                  )}
                </a>
                </figure>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                  <a className="text-dark" href={`/profile/${value?._id}`}>
                    {value?.username || "--"}
                  </a>
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    {value?.followerUsers?.length || 0} Followers
                  </span>
                </h4>
                <Link
                  to={`/profile/${value?._id}`}
                  className="btn-round-sm bg-greylight ms-auto mt-2"
                >
                  <span className="feather-chevron-right font-xss text-grey-900"></span>
                </Link>
              </div>
            );
          })}
        </div>
      )}
      {loading && !userList.length && <PopularUsersPlaceholder />}
    </>
  );
};

export default PopularUsers;
