import { VFC } from 'react'

const UserSliderPlaceholder: VFC = (): JSX.Element => {
	return (
		<div className='card w150 d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3 me-3 '>
			<div className='card-body d-block w-100 ps-3 pe-3 pb-4 text-center'>
				<figure className='overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1'>
					<div className='float-right p-0 rounded-circle w65 h65 loading' />
				</figure>

				<div className='clearfix'></div>

				<h4
					className='fw-700 font-xssss mt-3 mb-1 d-block w-100 trnc-game-card'
					style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
				>
					<span className='loading'>placeholder</span>{' '}
				</h4>

				<p className='fw-500 font-xsssss text-grey-500 mt-0 mb-3 lh-2'>
					<span className='loading'>place</span>
				</p>

				<a
					href='/'
					className='text-center p-2 lh-20 w100 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white loading text-uppercase'
				>
					follow
				</a>
			</div>
		</div>
	)
}

export default UserSliderPlaceholder
