import * as Yup from 'yup'
import { Formik, FormikHelpers, FormikProps, Form, Field } from 'formik'

import LeftBanner from '../LeftBanner'
import AuthLayout from '../../Layout/Auth'
import { IResetPassword } from '../../../types/global.interface'
import { useLocation, useNavigate } from 'react-router-dom'
import { resetPassword } from '../../../services/forgot.service'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'

// Form validation schema
const RegisterUserSchema = Yup.object().shape({
	password: Yup
		.string()
		.required('New Password Required')
		.matches(
			/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/,
			"Min 8 Characters, at least one letter and one number"
		),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm New Password'),
})

const ResetPassword = (): JSX.Element => {
	const search = useLocation().search
	const navigate = useNavigate()
	const id = new URLSearchParams(search).get('id') || ''
	const token = new URLSearchParams(search).get('token') || ''
	const initialValues: IResetPassword = {
		password: '',
		confirmPassword: '',
	}

	const handleRegister = async (
		values: IResetPassword,
		actions: FormikHelpers<IResetPassword>
	) => {
		try {
			actions.setSubmitting(true)
			await resetPassword({
				id,
				token,
				password: values?.password,
			})
			toast.success('Password updated!')
			navigate('/login')
		} catch (err: any) {
			const errorMessage = err?.response?.data?.message
			const defaultErrorMessage = 'Error while reset password!'

			if (errorMessage) toast.error(errorMessage || defaultErrorMessage)
		} finally {
			actions.setSubmitting(false)
		}
	}

	return (
		<>
			<AuthLayout>
				<LeftBanner />

				<div className='row'>
					<div
						className='col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat '
						style={{
							backgroundImage: `url("assets/images/landingpage/background.svg")`,
							filter: 'brightness(0.8)',
						}}
					></div>

					<div className='col-xl-7 vh-100 align-items-center d-flex rounded-3 overflow-hidden'>
						<div className='shadow-none border-0 ms-auto me-auto login-card'>
							<div className='card-body rounded-0 text-left'>
								<h2 className='fw-700 display1-size display2-md-size mb-4'>
									Reset <br />
									your password
								</h2>

								<Formik
									validationSchema={RegisterUserSchema}
									initialValues={initialValues}
									onSubmit={handleRegister}
								>
									{(props: FormikProps<IResetPassword>) => {
										return (
											<Form>
												<small className='text-danger'>
													{props.errors.password || props.errors.confirmPassword}
												</small>

												<div className='form-group icon-input mb-1'>
													<Field
														type='password'
														className='style2-input ps-5 form-control text-grey-900 font-xss ls-3'
														placeholder='Password'
														name='password'
													/>

													<i className='font-sm ti-lock text-grey-500 pe-0'></i>
												</div>

												<br />

												<div className='form-group icon-input mb-1'>
													<Field
														type='password'
														className='style2-input ps-5 form-control text-grey-900 font-xss ls-3'
														placeholder='Confirm your password'
														name='confirmPassword'
													/>

													<i className='font-sm ti-lock text-grey-500 pe-0'></i>
												</div>

												<br />

												<div className='col-sm-12 p-0 text-left'>
													<div className='form-group mb-1'>
														<button
															className='form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 '
															type='submit'
														>
															{props?.isSubmitting ? (
																<Spinner animation='border' variant='light' size='sm' />
															) : (
																'Submit'
															)}
														</button>
													</div>
												</div>
											</Form>
										)
									}}
								</Formik>
							</div>
						</div>
					</div>
				</div>
			</AuthLayout>
		</>
	)
}

export default ResetPassword
