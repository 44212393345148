import moment from "moment";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { readNotification } from "../../../services/notifications.service";
import { INotification } from "../../../types/global.interface";

const NotificationDropdownItem = ({
  notification,
  onDeleteNotification,
}: {
  notification: INotification | null;
  onDeleteNotification: Function;
}) => {
  const navigate = useNavigate();
  const [isRead, setIsRead] = useState<Boolean>(
    notification?.isRead ? true : false
  );
  const profilePic=notification?.sender?.profile?.picture||notification?.senderPicture ||""
  const userName=notification?.sender?.username || notification?.senderName ||"N/A"
  
  useEffect(()=>{
    setIsRead(notification?.isRead?true:false)
    },[notification])
  
  return (
    <div
      className={`nav-content-bttn open-font p-2 fw-600 text-dark pointer border-0 mb-2  ${
        !isRead ? "bg-lightblue theme-light-bg" : ""
      }`}
    >
      <span className="d-flex">
      <span
        onClick={() => {
          if (!notification?.isRead) {
            setIsRead(true);
            readNotification({ id: notification?._id || "" });
          }
          navigate(notification?.link || "");
        }}
      >
        {profilePic ? (
          <img
            src={profilePic}
            alt={userName}
            className="rounded-circle h30 w30"
          />
        ) : (
          <Avatar
            name={userName}
            className="rounded-circle h30 w30"
          />
        )}
        <span className="ms-2 me-2 text-grey-500 fw-500 font-xssss">
          {notification?.body || "--"}
        </span>
      </span>
      <span className="ms-auto mt-notification">
      <span 
        className="text-grey-400 font-xsssss fw-600 me-2">
          {moment(moment(notification?.createdAt), "LLL").fromNow()}
        </span>
      <i
        onClick={() => {
          setIsRead(true);
          onDeleteNotification({ id: notification?._id });
        }}
        style={{
          position: 'relative',
          top: '3px',
        }}
        className="feather-x font-xss me-1 text-notification"
      />
      </span>
      </span>
    </div>
  );
};

export default NotificationDropdownItem;