export const handleErrorMessage = (responseMessage: string) => {
	let message = responseMessage

	if (responseMessage) message = handleArrayMessage(responseMessage)

	return message
}

const handleArrayMessage = (message: string) => {
	if (Array.isArray(message) && message?.length > 1) {
		if (message?.length > 1) {
			message?.join(' and ')
		} else {
			message?.join('')
		}
	}

	return message
}
