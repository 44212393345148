import axios from "../http-common";
import { AllNotification } from "../types/global.interface";

/**
 * @description list all notifications
 * @returns Promise
 */
export const getAllNotifications = async ({
  page = 1,
  limit = 25,
}: {
  limit?: number;
  page?: number;
}) => {
  try {
    return await axios.get<AllNotification>(`/notifications`, {
      params: {
        page,
        limit,
        filter: {
          isDeleted: false,
        },
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

/**
 * @description read notification
 * @returns Promise
 */
export const readNotification = async ({ id }: { id?: string }) => {
  try {
    return await axios.patch<AllNotification>(
      `/notifications/${id}`,
      {
        isRead: true,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * @description delete notification
 * @returns Promise
 */
export const deleteNotification = async ({ id }: { id?: string }) => {
  try {
    return await axios.patch<AllNotification>(
      `/notifications/${id}`,
      {
        isDeleted: true,
        isRead: true,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * @description readAll notification
 * @returns Promise
 */
export const readAll = async () => {
  try {
    return await axios.put(
      `/notifications/readAll`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};
