const events = ['Metacritic', 'IGN', 'IGDB']

const Events = () => {
	return (
		<div className='card w-100 shadow-xss rounded-xxl border-0 mb-3'>
			<div className='card-body d-flex align-items-center p-4'>
				<h4 className='fw-700 mb-0 font-xssss text-grey-500 loading'>Critics</h4>
			</div>

			{events.map(event => (
				<div className='card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden'>
					<div className='bg-success me-2 p-3 rounded-xxl loading'>
						<h4 className='fw-700 font-lg ls-3 lh-1 text-transparent mb-0'>
							<span className='ls-1 d-block font-xsss fw-600 loading'>Score</span>
							<span className='loading'>90</span>
						</h4>
					</div>
					<h4 className='fw-700 text-grey-900 font-xssss mt-2'>
						<span className='loading'>{event}</span>{' '}
						<span className='d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500 text-truncate'>
							<span className='loading'>Placeholder</span>
						</span>{' '}
					</h4>
				</div>
			))}
		</div>
	)
}

export default Events
