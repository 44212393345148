import * as Yup from 'yup'
import { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Form, Field, FormikProps, FormikHelpers } from 'formik'
import LeftBanner from '../LeftBanner'
import AuthLayout from '../../Layout/Auth'
import { registerUser } from '../../../services/register.service'
import {
	IRegisterUserPayload,
	LoginResult,
} from '../../../types/global.interface'

import { loadRecaptcha } from '../../../helpers/recaptcha'
import { handleErrorMessage } from '../../../helpers/error'
import { Helmet } from "react-helmet";
import { showNotification } from '../../common/ShowNotification'

// Form validation schema
const RegisterUserSchema = Yup.object().shape({
	email: Yup.string().required('Email Required'),
	username: Yup.string()
		.min(2, 'Too Short!')
		.max(20, 'Too Long!')
		.required('Username Required')
		.matches(
			/^[a-zA-Z0-9]*$/,
			"No Special Characters allowed"
		),
	password: Yup
		.string()
		.required('Please Enter your password')
		.matches(
			/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/,
			"Min 8 Characters, at least one letter and one number"
		),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm Password'),
	termsAccepted: Yup.bool().oneOf([true], 'Accept Terms & Conditions'),
})

function validateEmail(value: any) {
	let error;
	if (!value) {
		error = 'Required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
		error = 'Invalid email address';
	}
	return error;
}

function validateUsername(value: any) {
	let error;
	if (value === 'admin') {
		error = 'Nice try!';
	} else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
		error = 'Invalid Username';
	}
	return error;
}

interface IRegister extends IRegisterUserPayload {
	token: string
}

const CRegister = () => {
	const navigate = useNavigate()

	const initialValues: IRegisterUserPayload = {
		username: '',
		email: '',
		password: '',
		confirmPassword: '',
		termsAccepted: false,
	}

	const fetchReCaptchaToken = (
		values: IRegisterUserPayload,
		actions: FormikHelpers<IRegisterUserPayload>
	) => {
		try {
			// setLoading(true);
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
						action: 'submit',
					})
					.then((token: string) => {
						handleRegister({ ...values, token }, actions)
					})
			})
		} catch (err) {
			// setLoading(false);
		}
	}

	const handleRegister = async (
		values: IRegister,
		actions: FormikHelpers<IRegisterUserPayload>
	) => {
		try {
			actions.setSubmitting(true)
			const { termsAccepted, ...payload } = values
			const { data }: { data: LoginResult } = await registerUser(payload)
			if (!data?.active) {
				navigate('/auth/verify')
			}
		} catch (err: any) {
			actions.setSubmitting(false)
			const errorMessage = err?.response?.data?.message
			if (errorMessage) {	

				const message = handleErrorMessage(errorMessage)
			    showNotification(message,'error',{autoClose:5000})
			}
		} finally {
			actions.setSubmitting(false)
		}
	}

	useEffect(() => {
		loadRecaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY || '')
	}, [])

	return (
		<AuthLayout>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Registration Page</title>
				<link rel="canonical" href={`https://unigamer.com/register`} />
			</Helmet>
			<LeftBanner />

			<div className='row'>
				<div
					className='col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat '
					style={{
						backgroundImage: `url("assets/images/landingpage/background.svg")`,
						filter: 'brightness(0.8)',
					}}
				></div>

				<div className='col-xl-7 vh-100 align-items-center d-flex rounded-3 overflow-hidden'>
					<div className='shadow-none border-0 ms-auto me-auto login-card'>
						<div className='card-body rounded-0 text-left'>

							<h2 className='fw-700 display1-size display2-md-size mb-4'>
								Create <br />
								your account
							</h2>

							<Formik
								validationSchema={RegisterUserSchema}
								initialValues={initialValues}
								onSubmit={fetchReCaptchaToken}

							>
								{(props: FormikProps<IRegisterUserPayload>) => {
									return (
										<Form>
											<div className='form-group icon-input mb-3'>
												<i className='font-sm ti-user text-grey-500 pe-0'></i>
												<Field
													type='text'
													className='style2-input ps-5 form-control text-grey-900 font-xsss fw-600'
													placeholder='Username'
													name='username'
													validate={validateUsername}
												/>
											</div>

											<div className='form-group icon-input mb-3'>
												<i className='font-sm ti-email text-grey-500 pe-0'></i>
												<Field
													type='text'
													className='style2-input ps-5 form-control text-grey-900 font-xsss fw-600'
													placeholder='Your Email Address'
													name='email'
													validate={validateEmail}
												/>
											</div>

											<div className='form-group icon-input mb-3'>
												<Field
													type='Password'
													className='style2-input ps-5 form-control text-grey-900 font-xss ls-3'
													placeholder='Password'
													name='password'
												/>

												<i className='font-sm ti-lock text-grey-500 pe-0'></i>
											</div>

											<div className='form-group icon-input mb-1'>
												<Field
													type='Password'
													className='style2-input ps-5 form-control text-grey-900 font-xss ls-3'
													placeholder='Confirm Password'
													name={'confirmPassword'}
												/>

												<i className='font-sm ti-lock text-grey-500 pe-0' />
											</div>

											<div className='form-check text-left mb-3'>
												<Field
													name={'termsAccepted'}
													type='checkbox'
													className='form-check-input mt-2'
													id='term-conditions'
												/>

												<label
													className='form-check-label font-xsss text-grey-500 mt-1'
													htmlFor='term-conditions'
												>
													Accept Term and Conditions
												</label>
											</div>

											<div className='col-sm-12 p-0 text-left'>
												<div className='form-group mb-1'>
													<button
														type='submit'
														className='form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 '
													>
														{props.isSubmitting ? (
															<Spinner animation='border' variant='light' size='sm' />
														) : (
															'Register'
														)}
													</button>
												</div>

												<h6 className='text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32'>
													Already have account{' '}
													<Link to='/login' className='fw-700 ms-1'>
														Login
													</Link>
												</h6>
												<small className='text-danger'>
													{props.errors.username ||
														props.errors.email ||
														props.errors.password ||
														props.errors.confirmPassword ||
														props.errors.termsAccepted}
												</small>
											</div>
										</Form>
									)
								}}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</AuthLayout>
	)
}

export default CRegister
