const CreatePostPlaceholder = (): JSX.Element => {
	return (
		<>
			<div data-testid="container" className="Container card-body p-0 rounded-xxl overflow-hidden uttam-die flex-row h-auto" style={{backgroundColor: "white", borderColor: "rgb(204, 204, 204)"}}>
				<div data-testid="image-container" className="Image"></div>
				<div className="LowerContainer">
					<h3 data-testid="title" className="Title loading" style={{color: "black"}}>Sequel to The Legend of Zelda: Breath of the Wild</h3>
					<span data-testid="desc" className="Description Secondary loading" style={{color: "rgb(100, 100, 100)"}}>Return to Hyrule - and the skies above - in this first look at equel to the</span>
					<div>
					<span data-testid="desc" className="Description Secondary loading" style={{color: "rgb(100, 100, 100)"}}> of Zelda: Breath of the Wild, planned for</span>
					</div>
					
					<div className="Secondary SiteDetails" style={{color: "rgb(100, 100, 100)"}}>
						<span className="loading">YouTube • www.youtube.com</span>
					</div>
				</div>
			</div>
		</>
	)
}

export default CreatePostPlaceholder
