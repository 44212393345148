import { useEffect, useState } from "react";
import Rating from "react-rating";
import { getGameById } from "../../../services/game.service";
import { GamesEntity } from "../../../types/global.interface";

const GameCard = ({ game }: { game: GamesEntity }) => {
  const [avgRating, setAvgRating] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);
  useEffect(() => {
    const fetchGame = async () => {
      try {

        if (game._id) {
          const res = await getGameById({
            id: game._id,
          });
          if (res.data.averageRating) setAvgRating(res.data.averageRating);
          if (res.data.reviews) setReviewsCount(res.data.reviews.length);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
      }
    };
    fetchGame();
  }, [game._id]);
  return (
    <>
     <a href={`/game/${game._id}`}>
      <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 overflow-hidden bor-0">
        <div
          className="card overlay-dark w-100 h361 d-block border-0 shadow-xss rounded-xxxl bg-gradiant-bottom overflow-hidden cursor-pointer mb-3 mt-0"
        >
          <img 
            className="bg-image-cover o-fit w-100 h-100" 
            src={game.coverUrl} 
            style={{ 
              position: "absolute",
              background: '#343a3f'
            }}
            alt={game.name} />
                    
          {/* <GameRatings /> */}
          <div
            className="p-2 d-flex"
            style={{ backgroundImage: "linear-gradient(black, #00000000)", position: "absolute", zIndex: "2", width: "100%" }}
          >
            <Rating
              readonly
              initialRating={avgRating}
              emptySymbol="feather-star emptySymbol-star ms-1"
              fullSymbol="feather-star fullSymbol-star ms-1"
            />
            <span className="text-white ms-2">({reviewsCount})</span>
          </div>

          <div className="card-body d-block p-3 w-100 position-absolute bottom-0 text-center">
          <figure className='avatar ms-auto me-auto mb-0 position-relative w50 z-index-1'>
							<i
								className={`btn-round-md me-3 ${
									game.consoles === 'ps5'
										? 'btn-round-md me-3 bg-p5'
										: game.consoles === 'ps4'
										? ' btn-round-md me-3 bg-p4'
										: game.consoles === 'switch'
										? 'bg-sw'
										: game.consoles === 'pc'
										? 'bg-pc'
										: game.consoles === 'xbox-sx'
										? 'bg-xs'
										: 'btn-round-md bg-xo me-3'
								}`}
							>
								{game.consoles === 'ps4'
									? 'P4'
									: game.consoles === 'ps5'
									? 'P5'
									: game.consoles === 'xbox'
									? 'XO'
									: game.consoles === 'switch'
									? 'SW'
									: game.consoles === 'pc'
									? 'PC'
									: 'XS'}
							</i>
						</figure>
            <div className="clearfix mt-1"></div>
            <h4 className="fw-600 position-relative z-index-1 ls-1 font-xssss text-white mt-2 mb-1">
              {game.name}{" "}
            </h4>
          </div>
        </div>
      </div>
      </a>
      <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
        <a
          href={`/game/${game._id}`}
          rel="noopener noreferrer"
          className="p-2 lh-28 w-100 bg-grey text-grey-800 text-center font-xssss fw-700 rounded-xl"
        >
          <i className="feather-external-link font-xss me-2"></i> Page
        </a>
      </div>
    </>
  );
};

export default GameCard;
