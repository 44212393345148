import { useState } from "react";

const Report = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuClass = `${isOpen ? " show" : ""}`;

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className={`ms-auto pointer ${menuClass}`}
        id="dropdownMenu4"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={toggleOpen}
      >
        <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
      </div>
      <div
        className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg ${menuClass} theme-dark-bg`}
        aria-labelledby="dropdownMenu4"
      >
        <div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
          <i className="feather-flag text-grey-500 me-2 font-sm"></i>
          <div className="fw-600 mb-0 text-dark mt-0 me-4">
          <span className="font-xsss">Report</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
