import { IGameResponse } from "../../../types/global.interface";
import GameCard from "./GameCard";

const SuggestedGames = ({ data }: { data: IGameResponse | undefined }) => {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-flex align-items-center p-4">
        <span className="fw-700 mb-0 font-xssss text-grey-500">Promoted Games</span>
        <a href="/games/discover" className="fw-600 ms-auto font-xssss text-primary">
          See all
        </a>
      </div>
      {data?.games?.filter(game => game.name.includes('Elden Ring') && game.consoles.includes("xbox-sx")).map((game, index: number) => (
        <div className="wrap" key={index}>
          <GameCard game={game} />
        </div>
      ))}
    </div>
  );
};

export default SuggestedGames;
