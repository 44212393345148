import { IGamesReviewPayload } from '../../../types/global.interface'
import ReviewCircles from '../Circle'

const ReviewContainer = ({
	reviews,
}: {
	reviews: (IGamesReviewPayload | null)[]
}): JSX.Element => {
	return (
		<div className='card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3'>
			<div className='card-body p-0 mt-3 position-relative'>
				<ReviewCircles reviews={reviews} />
			</div>
		</div>
	)
}

export default ReviewContainer
