import { Fragment, useContext, useEffect, useRef, useState } from "react";
import PopularGames from "../../components/RightNav/PopularGames";
import PopularMembers from "../../components/RightNav/PopularUsers";
import SuggestedGames from "../../components/RightNav/SuggestedGames";
import CreatePost from "../../components/Posts/CreatePost";
import GameSlider from "../../components/Games/GameSlider";
import UserSlider from "../../components/Users/UserSlider";
import GamesByPopularity from "../../components/Games/GamesByPopularity";
import Posts from "../../components/Posts/Posts";
import Load from "../../components/Posts/Load";
import { getAllPosts, getSearchPost } from "../../services/post.service";
import IGameData, {
  IGameResponse,
  IPostData,
  IUserData,
} from "../../types/global.interface";
import HomePlaceholder from "../../placeholder/home";
import Layout from "../../components/Layout";
import CHomeLayout from "../../components/Layout/HomeLayout/MHomeLayout";
import RHomeLayout from "../../components/Layout/HomeLayout/RHomeLayout";
import { allGames } from "../../services/game.service";
import GamesByPopularityPlaceholder from "../../placeholder/home/GamesByPopularity";
import CreatePostPlaceholder from "../../placeholder/home/CreatePost";
import PostsPlaceholder from "../../placeholder/home/Posts";
import PopularUsersPlaceholder from "../../placeholder/home/PopularUsers";
import { useOnLoadImages } from "../../hooks/useOnLoadImages";
import { SocketContext } from "../../contexts/socket";
import { AppContext } from "../../contexts";
import { Helmet } from "react-helmet";

const defaultPosts: IPostData<IUserData | IGameData>[] = [];

const Home = (): JSX.Element => {

  const [games, setGames] = useState<IGameResponse>();
  const [posts, setPosts] =
    useState<IPostData<IUserData | IGameData>[]>(defaultPosts);
  const [isError, setIsError] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const getPosts = async (page: number) => {
    try {
      setLoading(true);
      const { data } = searchValue
        ? await getSearchPost(searchValue, page)
        : await getAllPosts(page);
      setHasNextPage(data?.hasNextPage);
      pageNumber > 1
        ? setPosts([...posts, ...data?.posts])
        : setPosts([...data?.posts]);
    } catch (err) {
      console.log(err);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  const mountEffect = () => {
    getPosts(pageNumber);
  };

  /*Remove Re-Captcha If Exist*/

  const removeRecaptcha = () => {
    const script = document.getElementById("recaptcha-key");
    if (script) {
      script.remove();
    }

    const badge = document.getElementsByClassName("grecaptcha-badge");
    if (badge.length) {
      badge[0].remove();
    }
  };

  useEffect(() => {
    const fetchAllGames = async () => {
      try {
        const res = await allGames({
          page: 1,
          sortBy: "metaScore",
        });
        setGames(res?.data);
      } catch (error) {
        console.log("error");
      }
    };
    fetchAllGames();

    removeRecaptcha();
  }, []);

  useEffect(mountEffect, [pageNumber, searchValue]);



  return (
    <Layout
      setSearchValue={setSearchValue}
      setPageNumber={setPageNumber}
      loading={loading}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Unigamer - Home Feed</title>
        <link rel="canonical" href={`https://unigamer.com/home`} />
      </Helmet>
      <div className="row feed-body">
        {isError && <HomePlaceholder />}
        <Fragment>
          <CHomeLayout searchValue={""}>
            {games && games ? (
              <GamesByPopularity data={games} />
            ) : (
              <GamesByPopularityPlaceholder />
            )}

            {!games && !imagesLoaded ? (
              <CreatePostPlaceholder />
            ) : (
              <CreatePost setPosts={setPosts} allPosts={posts} />
            )}
            {!!posts?.length && !imagesLoaded ? (
              <Fragment>
                <Posts
                  list={posts.slice(0, 3)}
                  setPosts={setPosts}
                  allPosts={posts}
                />
                <GameSlider data={games} />
                <Posts
                  list={posts.slice(3, 6)}
                  setPosts={setPosts}
                  allPosts={posts}
                />
                <UserSlider />

                <Posts
                  list={posts.slice(6)}
                  hasNextPage={hasNextPage}
                  pageNumber={pageNumber}
                  loading={loading}
                  setPageNumber={setPageNumber}
                  setPosts={setPosts}
                  allPosts={posts}
                />
              </Fragment>
            ) : (
              <PostsPlaceholder />
            )}
            {loading && <Load />}
          </CHomeLayout>
          <RHomeLayout>
            <PopularGames />
            {loading && imagesLoaded ? (
              <PopularUsersPlaceholder />
            ) : (
              <PopularMembers />
            )}

            {games && games ? <SuggestedGames data={games} /> : <></>}
          </RHomeLayout>
        </Fragment>
      </div>
    </Layout>
  );
};

export default Home;
