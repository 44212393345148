import { ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../contexts'

const AuthTag = ({ children }: { children: ReactNode }): JSX.Element => {
	const { user } = useContext(AppContext)

	return (
		<>
			{!user ? (
				<NavigateButton path='/login' children={children} />
			) : user && !user.active ? (
				<NavigateButton path='/auth/verify' children={children} />
			) : (
				children
			)}
		</>
	)
}

export default AuthTag

const NavigateButton = ({
	path,
	children,
}: {
	path: string
	children: ReactNode
}): JSX.Element => {
	const navigate = useNavigate()

	return <span onClick={() => navigate(path)}>{children}</span>
}
