import { IGame } from '../../types/global.interface'

const ConsoleTag = ({ data }: { data: IGame | undefined }): JSX.Element => {
	return (
		<span
			className={`${
				data?.consoles === 'ps5'
					? 'bg-p5'
					: data?.consoles === 'ps4'
					? 'bg-p4'
					: data?.consoles === 'switch'
					? 'bg-sw'
					: data?.consoles === 'pc'
					? 'bg-pc'
					: data?.consoles === 'xbox-sx'
					? 'bg-xs'
					: 'bg-xo'
			} text-uppercase live-tag mt-2 mb-0 p-1 pe-2 ps-2 z-index-1 rounded-3 font-xsssss ls-3`}
		>
			{data?.consoles}
		</span>
	)
}

export default ConsoleTag
