const CreatePostPlaceholder = (): JSX.Element => {
	return (
		<>
			<div className='card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3'>
				<div className='card-body p-0'>
					<div className='font-xssss fw-600 card-body p-0 d-flex align-items-center'>
						<i className='btn-round-sm font-xs feather-edit-3 me-2 loading'></i>
						<div className='loading pointer-none'>Placeholder</div>
					</div>
				</div>

				<div className='card-body p-0 mt-3 position-relative'>
					<figure className='avatar position-absolute ms-2 mt-1 top-5'></figure>
					<textarea className='h100 bor-0 w-100 rounded-xxl p-3 ps-5 font-xssss fw-500 border-light-md theme-dark-bg pointer-none loading'></textarea>
				</div>

				<div className='card-body d-flex p-0 mt-2'>
					<div className='d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4'>
						<i className='font-md feather-video me-2 ps-3 loading'></i>
						<span className='d-none-xs loading pointer-none'>Live Video</span>
					</div>

					<div className='ms-auto'>
						<i className='ti-more-alt btn-round-md font-xss loading'></i>
					</div>
				</div>
			</div>
		</>
	)
}

export default CreatePostPlaceholder
