import { IUserData } from "../../../types/global.interface";
import reddit from "../../../assets/social/reddit.svg";
import twitch from "../../../assets/social/twitch.svg";
import youtube from "../../../assets/social/youtube.svg";
import steam from "../../../assets/social/steam.svg";
import TextTruncate from 'react-text-truncate'; // recommend

const Profiledetails = ({
  user,
}: {
  user: IUserData | undefined;
}): JSX.Element => {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-500">Social Media</h4>
        <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{"--"}</p>
      </div>
      <div className="card-body border-top-xs d-flex">
        <img src={reddit} className="social-icon me-3" alt="reddit" />
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          Reddit
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            <TextTruncate
              line={1}
              element="span"
              truncateText={user?.social?.reddit ? "…" : ""}
              text={user?.social?.reddit ? user?.social?.reddit : "--"}
              textTruncateChild={user?.social?.reddit ? <a href={user?.social?.reddit}>Visit</a> : "--"}
            />
          </span>
        </h4>
      </div>

      <div className="card-body d-flex pt-0">
        <img src={twitch} className="social-icon me-3" alt="twitch" />
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          Twitch
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
          <TextTruncate
              line={1}
              element="span"
              truncateText={user?.social?.twitch ? "…" : ""}
              text={user?.social?.twitch ? user?.social?.twitch : "--"}
              textTruncateChild={user?.social?.twitch ? <a href={user?.social?.twitch}>Visit</a> : "--"}
            />
          </span>
        </h4>
      </div>

      <div className="card-body d-flex pt-0">
        <img src={youtube} className="social-icon me-3" alt="youtube" />
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          Youtube
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
          <TextTruncate
              line={1}
              element="span"
              truncateText={user?.social?.youtube ? "…" : ""}
              text={user?.social?.youtube ? user?.social?.youtube : "--"}
              textTruncateChild={user?.social?.youtube ? <a href={user?.social?.youtube}>Visit</a> : "--"}
            />
          </span>
        </h4>
      </div>

      <div className="card-body d-flex pt-0">
        <img src={steam} className="social-icon me-3" alt="steam" />
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          Steam
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
          <TextTruncate
              line={1}
              element="span"
              truncateText={user?.social?.steam ? "…" : ""}
              text={user?.social?.steam ? user?.social?.steam : "--"}
              textTruncateChild={user?.social?.steam ? <a href={user?.social?.steam}>Visit</a> : "--"}
            />
          </span>
        </h4>
      </div>
    </div>
  );
};

export default Profiledetails;
