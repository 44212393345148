import { useState } from "react";
import { Comment } from "../../../types/global.interface";
import EditComment from "../EditComment";
import CommentCard from "./CommentCard";

const Comments = ({
  comments,
  setComments,
  refetchComments,
}: {
  comments: (Comment | null)[];
  setComments: Function;
  refetchComments: Function;
}): JSX.Element => {
  const [openEditModal, setOpenEdit] = useState(false);
  const [comment, setComment] = useState<Comment | null>(null);

  return (
    <div className="w-100 position-relative scroll-bar">
      <EditComment
        open={openEditModal}
        setOpen={setOpenEdit}
        comment={comment}
        setComments={setComments}
        allComments={comments}
      />
      {comments?.map((value, index) => (
        <CommentCard
          key={`${index}-${value?._id}`}
          comment={value}
          comments={comments}
          setComments={setComments}
          setEditComment={setComment}
          setOpenEdit={setOpenEdit}
          refetchComments={refetchComments}
        />
      ))}
    </div>
  );
};

export default Comments;
