import axios from "../http-common";
import { LoginResult } from "../types/global.interface";

export const loginUser = async (data: {
  email: string;
  password: string;
  token: string;
}) => {
  try {
    return await axios.post<LoginResult>("/auth/login", data);
  } catch (error) {
    throw error;
  }
};
