import TextTruncate from 'react-text-truncate'
import { IGame } from '../../../../types/global.interface'

const Events = ({ game }: { game: IGame | any }) => {
	const METAScore = () => {
		if (game?.metaScore >= 80) {
			return 'bg-success'
		} else if (game?.metaScore <= 79 && game?.metaScore >= 50) {
			return 'bg-warning'
		} else {
			return 'bg-danger'
		}
	}

	const IGNScore = () => {
		if (game?.ignscore >= 8) {
			return 'bg-success'
		} else if (game?.ignscore <= 7.9 && game?.ignscore >= 5) {
			return 'bg-warning'
		} else {
			return 'bg-danger'
		}
	}

	const IGDBScore = () => {
		if (game?.igdbscore >= 80) {
			return 'bg-success'
		} else if (game?.igdbscore <= 79 && game?.igdbscore >= 50) {
			return 'bg-warning'
		} else {
			return 'bg-danger'
		}
	}

	const critics = [
		{
			score: game?.metaScore || 'na',
			name: 'Metacritic',
			attribute: 'Score',
			status: { METAScore },
			description: game?.metaScoreUrl || 'https//www.metacritic.com',
		},
		{
			score: game?.ignScore || 'na',
			name: 'IGN',
			attribute: 'Score',
			status: { IGNScore },
			description: game?.ignScoreUrl || 'https//www.ign.com',
		},
		{
			score: game?.igdbScore || 'na',
			name: 'IGDB',
			attribute: 'Score',
			status: { IGDBScore },
			description: game?.igdbScoreUrl || 'https//www.igdb.com',
		},
	]

	return (
		<div className='card w-100 shadow-xss rounded-xxl border-0 mb-3'>
			<div className='card-body d-flex align-items-center p-4'>
				<h4 className='fw-700 mb-0 font-xssss text-grey-500'>Critics</h4>
			</div>

			{critics.map((value, index) => (
				<div
					key={index}
					className='card-body d-flex pt-0 ps-4 pe-4 pb-3 overflow-hidden'
				>
					<div className={`bg-success me-2 p-3 rounded-xxl ${value.status}`}>
						<h4 className='fw-700 font-lg ls-3 lh-1 text-white mb-0'>
							<span className='ls-1 d-block font-xsss text-white fw-600'>
								{value.attribute}
							</span>
							{value.score}
						</h4>
					</div>

					<h4 className='fw-700 text-grey-900 font-xssss mt-2'>
						{value.name}{' '}
						<span className='d-block font-xsssss fw-500 mt-1 lh-4 text-grey-500'>
							<TextTruncate
								line={2}
								element='span'
								truncateText='…'
								text={value.description ? value.description : '--'}
								textTruncateChild={
									value.description ? <a href={value.description}>Visit</a> : '--'
								}
							/>
						</span>{' '}
					</h4>
				</div>
			))}
		</div>
	)
}

export default Events
