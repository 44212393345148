import React, { useState } from 'react'

const DarkMode = () => {
	let clickedClass = 'clicked'
	const body = document.body
	const lightTheme = 'theme-light'
	const darkTheme = 'theme-dark'
	const [icon, setIcon] = useState('feather-sun')
	let theme: any

	if (localStorage) {
		theme = localStorage.getItem('theme')
	}

	if (theme === lightTheme || theme === darkTheme) {
		body.classList.add(theme)
	} else {
		body.classList.add(lightTheme)
	}

	const switchTheme = (e: any) => {
		if (theme === darkTheme) {
			body.classList.replace(darkTheme, lightTheme)
			e.target.classList.remove(clickedClass)
			localStorage.setItem('theme', 'theme-light')
			theme = lightTheme
			setIcon('feather-moon')
		} else {
			body.classList.replace(lightTheme, darkTheme)
			e.target.classList.add(clickedClass)
			localStorage.setItem('theme', 'theme-dark')
			theme = darkTheme
			setIcon('feather-sun')
		}
	}

	const darkClass = theme === 'dark' ? clickedClass : ''

	return (
		<span
			className={`pointer p-2 text-center ms-3 chat-active-btn ${darkClass}`}
			onClick={e => switchTheme(e)}
		>
			<i className={`${icon} feather-moon font-xl header-icon`}></i>
		</span>
	)
}

export default DarkMode
