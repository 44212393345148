const PopularGamesPlaceholder = (): JSX.Element => {
	return (
		<>
			{/* PopularGames Placeholder */}
			<div className='card w-100 shadow-xss rounded-xxl border-0 mb-3'>
				<div className='card-body d-flex align-items-center p-4'>
					<h4 className='fw-700 mb-0 font-xssss loading pointer-none'>
						Popular Games
					</h4>

					<div className='fw-600 ms-auto font-xssss loading pointer-none'>
						See all
					</div>
				</div>

				<div className='wrap'>
					<div className='card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0'>
						<figure className='avatar me-3'>
							<div className='rounded-circle w45 h45 loading' />
						</figure>

						<h4 className='fw-700 text-grey-900 font-xssss mt-1 trnc-game-card'>
							<span className='loading'>Placeholder</span>
							<span className='d-block font-xssss fw-500 mt-1 lh-3 loading'>
								Placeholder
							</span>
						</h4>
					</div>

					<div className='card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4'>
						<div className='p-2 lh-20 w100  me-2 text-center font-xssss fw-600 ls-1 rounded-xl loading pointer-none'>
							Follow
						</div>

						<div className='p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl loading pointer-none'>
							Page
						</div>
					</div>
				</div>

				<div className='wrap'>
					<div className='card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0'>
						<figure className='avatar me-3'>
							<div className='rounded-circle w45 h45 loading' />
						</figure>

						<h4 className='fw-700 text-grey-900 font-xssss mt-1 trnc-game-card'>
							<span className='loading'>Placeholder</span>
							<span className='d-block font-xssss fw-500 mt-1 lh-3 loading'>
								Placeholder
							</span>
						</h4>
					</div>

					<div className='card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4'>
						<div className='p-2 lh-20 w100  me-2 text-center font-xssss fw-600 ls-1 rounded-xl loading pointer-none'>
							Follow
						</div>
						<div className='p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl loading pointer-none'>
							Page
						</div>
					</div>
				</div>

				<div className='wrap'>
					<div className='card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0'>
						<figure className='avatar me-3'>
							<div className='rounded-circle w45 h45 loading' />
						</figure>

						<h4 className='fw-700 text-grey-900 font-xssss mt-1 trnc-game-card'>
							<span className='loading'>Placeholder</span>
							<span className='d-block font-xssss fw-500 mt-1 lh-3 loading'>
								Placeholder
							</span>
						</h4>
					</div>

					<div className='card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4'>
						<div className='p-2 lh-20 w100  me-2 text-center font-xssss fw-600 ls-1 rounded-xl loading pointer-none'>
							Follow
						</div>

						<div className='p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl loading pointer-none'>
							Page
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PopularGamesPlaceholder
