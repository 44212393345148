import { CSSProperties, useState } from 'react'
import Avatar from 'react-avatar'
import { useNavigate, generatePath } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import { searchGames } from '../../../services/game.service'
import { searchUser } from '../../../services/user.service'
import IGameData, { IUserData } from '../../../types/global.interface'
import {
	UserOption,
	GameOption,
	PostOption,
	GroupedOption,
} from '../Search/test.data'

// Need to remove any
const customStyles = {
	control: () => ({
		display: 'flex',
		gridTemplateColumns: '0 min-content',
	}),

	input: (provided: any) => ({
		...provided,
		margin: '2px',
		paddingBottom: '2px',
		paddingTop: '2px',
		visibility: 'visible',
		color: 'none',
		flex: '1 1 auto',
		display: 'inline-grid',
		gridArea: '1 / 1 / 2 / 3',
		gridTemplateColumns: '0px min-content',
	}),

	// Need to remove any
	singleValue: (provided: any, state: { isDisabled: any }) => {
		const opacity = state.isDisabled ? 0.5 : 1
		const transition = 'opacity 300ms'
		return { ...provided, opacity, transition }
	},

	menu: (provided: any, state: any) => ({
		...provided,
		width: '100%',
		minWidth: '0',
		wordWrap: 'break-word',
		backgroundClip: 'border-box',
		border: 'transparent',
		borderRadius: '15px',
		padding: '0.5rem !important',
		boxShadow: '0 8px 30px rgb(0 0 0 / 5%) !important',
		left: '0',
		color: state.selectProps.menuColor,
	}),

	option: () => ({
		padding: '0.5rem !important',
		display: 'flex !important',
		color: '#6c757d !important',
		fontWeight: '600 !important',
		'&:hover': {
			backgroundColor: '#e8e8e87a',
			borderRadius: '9px',
		},
	}),
}

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	fontWeight: '700',
}

const groupBadgeStyles: CSSProperties = {
	backgroundColor: '#EBECF0',
	borderRadius: '2em',
	color: '#172B4D',
	display: 'inline-block',
	fontSize: 12,
	fontWeight: 'normal',
	lineHeight: '1',
	minWidth: 1,
	padding: '0.16666666666667em 0.5em',
	textAlign: 'center',
}

const formatGroupLabel = (data: GroupedOption) => (
	<div style={groupStyles}>
		<span>{data.label}</span>
		<span style={groupBadgeStyles}>{data.options.length}</span>
	</div>
)

const fetchOptions = async (query: string) => {
	try {
		const gamesRes = await searchGames(query)
		const users = await searchUser(query)
		const gamesOptions = gamesRes.data?.games.map((data: IGameData) => {
			return {
				value: data._id,
				label: (
					<div className='card-body p-0 d-flex'>
						<img
							src={data.coverUrl}
							alt='game cover image'
							className='rounded-circle h30 w30 me-2'
						/>
						<span className="truncate-text text-dark ms-1">{data.name}</span>
						<span className='ms-auto d-block font-xssss fw-500 mt-2 lh-3'>
							<span
								className={`${data?.consoles === 'ps5'
										? 'bg-p5'
										: data?.consoles === 'ps4'
											? 'bg-p4'
											: data?.consoles === 'switch'
												? 'bg-sw'
												: data?.consoles === 'pc'
													? 'bg-pc'
													: data?.consoles === 'xbox-sx'
														? 'bg-xs'
														: 'bg-xo'
									} text-uppercase p-1 pe-2 ps-2 z-index-1 rounded-3 font-xsssss ls-3 me-1`}
							>
								{data?.consoles}
							</span>
						</span>
					</div>
				),
				type: 'game',
			}
		})

		const userOptions = users?.data?.users?.map((user: IUserData) => {
			return {
				value: user._id,
				label: (
					<div>
						{user.profile.picture ? (
							<img
								src={user.profile.picture}
								className='rounded-circle h30 w30 me-2'
							/>
						) : (
							<Avatar name={user?.username} className='rounded-circle h30 w30 me-2' />
						)}
						{'    '}
						<span className="truncate-text text-dark">{user.username}</span>
					</div>
				),
				type: 'user',
			}
		})

		const groupedOptions: GroupedOption[] = [
			{
				label: 'Games',
				options: gamesOptions,
			},
			{
				label: 'Users',
				options: userOptions,
			},
		]

		return groupedOptions
	} catch (error) {
		console.log('error', error)
	}
}
const getData = (inputValue: string) => {
	return fetchOptions(inputValue).then(result => {
		return result
	})
}

const Search = ({
	setSearchValue,
	setPageNumber,
}: {
	setSearchValue?: Function
	setPageNumber?: Function
}): JSX.Element => {
	const navigate = useNavigate()
	const [inputValue,setInputValue]=useState("")
	let enterPressed=false
	const handleOnChange = (
		e: {
			value: string
			label: string
			type: string
		} | null
	) => {
		if(enterPressed ) {
			if(inputValue){
				enterPressed=false
				navigate(`/search?query=${inputValue}`)
			}
		}
	    else if (e?.value) {
			// setSearchValue && setSearchValue(e?.value);
			// setPageNumber && setPageNumber(1);
			const route = e.type === 'game' ? 'game' : 'profile'
			navigate(generatePath(`/${route}/:id`, { id: e?.value }))
		}
	}
	const onKeyPress=(e:React.KeyboardEvent<HTMLDivElement>)=>{
		if(e?.key==="Enter"){
			enterPressed=true
		}
	}
    const handleInputChange = (value: string) => {
		setInputValue( value );
	  };

	return (
		<form  className='float-left ms-3 search'>
			<div className='form-group mb-0 icon-input'>
				<i className='feather-search font-sm ms-2 z-index-1 text-grey-600'></i>
				<AsyncSelect<GameOption | UserOption | PostOption, false, GroupedOption>
					styles={customStyles}
					value={{ label: 'Search...', value: '', type: '' }}
					onInputChange={handleInputChange}
					placeholder='Search...'
					onKeyDown={(e)=>onKeyPress(e)}
					loadOptions={getData}
					cacheOptions
					defaultOptions
					formatGroupLabel={formatGroupLabel}
					onChange={handleOnChange}
					className='bg-grey border-0 lh-32 pt-1 pb-1 ps-5 pe-3 pointer font-xssss fw-500 rounded-xl w350 text-dark theme-dark-bg'
				/>
			</div>
        </form>
	)
}

export default Search
