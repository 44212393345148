import React, { useContext, useState } from 'react'
import ReactModal from 'react-modal'
import { Spinner } from 'react-bootstrap'
import { AppContext } from '../../../contexts'
import { claimGameRequest } from '../../../services/game.service'
import { IGame } from '../../../types/global.interface'
import AuthTag from '../../common/AuthTag'
import { Link } from 'react-router-dom'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root')

const ClaimPopup = ({
	gameData,
	setGame,
}: {
	gameData?: IGame
	setGame: Function
}): JSX.Element => {
	const { user } = useContext(AppContext)
	const [modalIsOpen, setIsOpen] = React.useState(false)
	const [loading, setLoading] = useState(false)

	function openModal() {
		setIsOpen(true)
	}

	function closeModal() {
		setIsOpen(false)
	}

	const handleClaim = async () => {
		try {
			if (user && gameData) {
				setLoading(true)
				await claimGameRequest({
					id: gameData?._id,
				})
				setGame({
					...gameData,
					claimRequest: [...(gameData.claimRequest || []), user._id],
				})
				closeModal()
			}
		} catch (err) {
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			{gameData?.claimStatus === 'approved' ||
			(user && gameData?.claimRequest?.includes(user?._id)) ? (
				<span>
					{gameData?.claimBy === user?._id ? (
						<span className='claimed fw-600 mt-1 lh-3 text-grey-500 bg-grey mt-2 mb-0 p-1 pe-2 ps-2 z-index-1 rounded-3 text-grey-600 font-xsssss'>
							<Link to={`/analytics/${gameData._id}`}>Dashboard</Link>
						</span>
					) : gameData?.claimStatus === 'approved' ? (
						<span className='claimed fw-600 mt-1 lh-3 text-primary bg-grey mt-2 mb-0 p-1 pe-2 ps-2 z-index-1 rounded-3 font-xssss'>
						<img
							className="w15 me-1"
							src="../assets/images/badges/claimed.png"
							alt="verified"
						/>Verified
						</span>
					) : (
						<span className='claimed fw-600 mt-1 lh-3 text-grey-500 bg-grey mt-2 mb-0 p-1 pe-2 ps-2 z-index-1 rounded-3 text-grey-600 font-xssss'>
						<img
							className="w15 me-1"
							src="../assets/images/badges/unclaimed.png"
							alt="verified"
						/>Claim Requested
						</span>
					)}
				</span>
			) : (
				<AuthTag>
					<span className='pointer' onClick={() => openModal()}>
						<span className='claimed fw-600 mt-1 lh-3 text-grey-500 bg-grey mt-2 mb-0 p-1 pe-2 ps-2 z-index-1 rounded-3 text-grey-600 font-xsssss'>
						<img
							className="w15 me-1"
							src="../assets/images/badges/unclaimed.png"
							alt="verified"
						/>Unclaimed
						</span>
					</span>
				</AuthTag>
			)}

			<ReactModal
				isOpen={modalIsOpen}
				contentLabel='onRequestClose Example'
				onRequestClose={closeModal}
				className='Modal card shadow-xss rounded-xxl border-0 p-4 mb-3'
				overlayClassName='Overlay'
			>
				<h4 className='fw-700 font-xss text-grey-900 d-flex align-items-center'>
					Claim Game Profile
					<i
						className='feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 pointer'
						onClick={closeModal}
					></i>
				</h4>
				<div className='card-body p-0 d-flex'>
					<p className='fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2'>
						Please claim only games you own or are authorized to claim.
					</p>
				</div>

				<div className='d-flex align-items-center'>
					<button
						className='p-2 lh-20 w100 bg-primary-gradiant me-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0'
						disabled={loading}
						onClick={handleClaim}
					>
						{loading ? <Spinner animation='border' size='sm' /> : 'Claim'}
					</button>

					<button
						className='p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl border-0'
						onClick={closeModal}
					>
						Cancel
					</button>
				</div>
			</ReactModal>
		</>
	)
}

export default ClaimPopup
