import _ from 'lodash'
import Load from '../../components/Posts/Load'
import Layout from '../../components/Layout'
import UProfileCard from '../../components/Users/UProfileCard'
import ProfilePhotos from '../../components/Users/ProfilePhotos'
import UProfiledetails from '../../components/Users/UProfiledetails'
import CreatePost from '../../components/Posts/CreatePost'
import Posts from '../../components/Posts/Posts'
import {
	IGame,
	IGamesReviewPayload,
	IPostData,
	IUserData,
} from '../../types/global.interface'
import { useContext, useEffect, useState } from 'react'
import { getAllPosts } from '../../services/post.service'
import SocialDetails from '../../components/Users/SocialDetails'
import {
	fetchUserReviews,
	getUserById,
	getUserMeta,
} from '../../services/user.service'
import { useParams } from 'react-router'
import NothingFoundCard from '../../components/common/NothingFoundCard'
import { AppContext } from '../../contexts'
import Review from '../../components/Reviews/Review'
import UserPlaceholder from '../../placeholder/users'
import { getGameById } from '../../services/game.service'
import { Helmet } from "react-helmet";

const defaultPosts: IPostData<IUserData>[] = []

export interface IContributions {
	id: string
	reviews: (IGamesReviewPayload | null)[]
}

const UserProfile = (): JSX.Element => {
	const { user: contextUser } = useContext(AppContext)
	const paramsId = useParams<{ id: string }>().id
	const [id, setId] = useState(paramsId || contextUser?._id)
	const [posts, setPosts] = useState<IPostData<IUserData>[]>(defaultPosts)
	const [user, setUser] = useState<IUserData>()
	const [contributions, setContributions] = useState<IContributions | undefined>(
		undefined
	)
	const [loading, setLoading] = useState(false)
	const [hasNextPage, setHasNextPage] = useState(false)
	const [pageNumber, setPageNumber] = useState(1)
	const fetchGamesData = async (id: string) => {
		try {
			const { data } = await getGameById({ id })
			return data
		} catch (err) { }
	}

	const userReviews = async () => {
		try {
			if (id) {
				const { data } = await fetchUserReviews({ id })
				if (data.length) {
					const reviews = _.cloneDeep(data)

					const gamesData: IGame[] = []
					for (const review of reviews) {
						const game = await fetchGamesData(review.game)
						if (game) {
							review.gameData = game
							gamesData.push(game)
						}
					}
					setContributions({ id, reviews })
				}
			}
		} catch (err) { }
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (id) {
					setLoading(true)
					const { data }: any = await getAllPosts(pageNumber, {
						$or: [{ postedTo: id }, { owner: id }],
					})
					setPosts(data?.posts)
					setHasNextPage(data?.hasNextPage)
					pageNumber > 1
						? setPosts([...posts, ...data?.posts])
						: setPosts([...data?.posts])
				}
			} catch (error) {
			} finally {
				user && setLoading(false)
			}
		}
		if (id) {
			const fetchUser = async () => {
				try {
					setLoading(true)
					const res = await getUserById(id)
					if (res?.data) {
						let result: IUserData = res?.data
						const { data } = await getUserMeta({ id })
						result = { ...result, ...data }
						setUser(result)
					}
					userReviews()
				} catch (error) {
					console.log('error')
				} finally {
					setLoading(false)
				}
			}
			fetchUser()
			fetchData()
		}
		if (!id && contextUser) {
			setId(contextUser._id)
		}
	}, [id, contextUser])

	useEffect(() => {
		if (!paramsId) {
			setLoading(true)
			setPosts([])
			setId(contextUser?._id)
		}
	}, [paramsId, contextUser?._id])

	return (
		<Layout>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{user?._id}</title>
				<link rel="canonical" href={`https://unigamer.com/profile/${user?._id}`} />
			</Helmet>
			{user && !loading ? (
				<div className='row'>
					<div className='col-xl-12'>
						<UProfileCard user={user} setUser={setUser} />
					</div>
					<div
						className='col-xl-8 col-xxl-9 col-lg-8 tab-content'
						id='nav-tabContent'
					>
						<div
							className='tab-pane fade show active'
							id='nav-about'
							role='tabpanel'
							aria-labelledby='nav-about-tab'
						>
							<CreatePost
								allPosts={posts}
								setPosts={setPosts}
								postedTo={id}
								postedToType='User'
							/>
							{posts.length ? (
								<Posts
									list={posts}
									setPosts={setPosts}
									allPosts={posts}
									hasNextPage={hasNextPage}
									pageNumber={pageNumber}
									loading={loading}
									setPageNumber={setPageNumber}
								/>
							) : (
								!!!posts.length && !loading && <NothingFoundCard text='No Post Found' />
							)}
							{loading && <Load />}
						</div>

						{/* Content Second Tab */}
						<div
							className='tab-pane fade'
							id='nav-contributions'
							role='tabpanel'
							aria-labelledby='nav-contributions-tab'
						>
							<Review
								list={contributions?.reviews || []}
								game={contributions}
								setGame={setContributions}
								refetch={userReviews}
							/>
						</div>

						{/* Content Fourth Tab */}
						<div
							className='tab-pane fade'
							id='nav-media'
							role='tabpanel'
							aria-labelledby='nav-media-tab'
						>
							{posts.filter(item => item.imageUrl)?.length ? (
								<Posts
									list={posts.filter(item => item.imageUrl)}
									setPosts={setPosts}
									allPosts={posts}
									hasNextPage={hasNextPage}
									pageNumber={pageNumber}
									loading={loading}
									setPageNumber={setPageNumber}
								/>
							) : (
								!loading && <NothingFoundCard text='No Media Post Found' />
							)}
							{loading && <Load />}
						</div>
					</div>
					<div className='col-xl-4 col-xxl-3 col-lg-4 ps-0'>
						<UProfiledetails user={user} />
						<SocialDetails user={user} />
						<ProfilePhotos user={user} posts={posts.filter(item => item.imageUrl)} />
					</div>
				</div>
			) : (
				<UserPlaceholder />
			)}
		</Layout>
	)
}

export default UserProfile
