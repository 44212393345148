import axios from "../http-common";
import { LoginResult } from "../types/global.interface";

export const forgotPassword = async (data: {
  resetemail: string;
  token: string;
}) => {
  try {
    return await axios.post<LoginResult>("/auth/forgot", data);
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (payload: {
  token: string;
  id: string;
  password: string;
}) => {
  try {
    return await axios.post("/auth/reset", payload);
  } catch (error) {
    throw error;
  }
};
