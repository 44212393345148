import Avatar from "react-avatar";
import { Link, NavLink } from "react-router-dom";
import { Fragment, useContext, useState } from "react";
import Search from "./Search";
import Leftnav from "../LeftNav";
import Darkbutton from "./DarkButton";
import AuthTag from "../common/AuthTag";
import { AppContext } from "../../contexts";
import { SocketContext } from "../../contexts/socket";
import NotificationDropdown from "./NotificationDropdown";
import ReactModal from "react-modal";
import React from "react";

const Header = ({
  setSearchValue,
  setPageNumber,
}: {
  setSearchValue?: Function;
  setPageNumber?: Function;
  loading?: boolean;
}): JSX.Element => {
  const { user, setUser, setIsLoggedIn, isLoading,setOnlineUser } = useContext(AppContext);
  const { socket } = useContext(SocketContext);
  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setIsOpenModal] = React.useState(false);

  function openModal() {
    setIsOpenModal(true);
  }

  function closeModal() {
    setIsOpenModal(false);
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    setUser(null);
    setIsLoggedIn(false);
		// socket?.off(`User_Connected`, () => { });
    socket?.disconnect();
    setOnlineUser(()=>[])
    localStorage.removeItem("token");
  };

  return (
    <div className="nav-header backDrop shadow-xs border-0">
      <div className="nav-top">
        <a href="/home">
          <i className="display2-size m-3">
            <img
              className="logo"
              src="/assets/images/brand/mainlogo.svg"
              alt="Unigamer"
            />
          </i>
        </a>
      </div>

      {/** Desktop Searchbar */}
      <Search setSearchValue={setSearchValue} setPageNumber={setPageNumber} />

      <div
        className="ms-auto d-none-mobile"
        onClick={openModal}>
        <i className="feather-search font-lg text-dark"></i>
      </div>

      <ReactModal
        isOpen={modalIsOpen}
        contentLabel="onRequestClose Example"
        onRequestClose={closeModal}
        className="Modal p-4 pe-5 ps-5 search-modal top-11 shadow-xss border-0 ms-auto me-auto mt-5 mb-3"
        overlayClassName="Overlay"
      >
        <div className="search-mobile">
          <Search setSearchValue={setSearchValue} setPageNumber={setPageNumber} />
        </div>
      </ReactModal>
      {/** Mobile Searchbar ends */}
      
      {/** Mobile Searchbar */}
      <div className="ms-2 d-none-tablet m-3">
        <Darkbutton />
      </div>

      <NavLink
        style={({ isActive }) => ({ color: isActive ? "green" : "blue" })}
        className="p-2 text-center ms-3 menu-icon center-menu-icon"
        to="/home"
      >
        <i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>
      <NavLink
        style={({ isActive }) => ({ color: isActive ? "green" : "blue" })}
        className="p-2 text-center ms-0 menu-icon center-menu-icon"
        to="/games/discover"
      >
        <i className="feather-compass font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>
      <NavLink
        style={({ isActive }) => ({ color: isActive ? "green" : "blue" })}
        className="p-2 text-center ms-0 menu-icon center-menu-icon"
        to="/coming-soon"
      >
        <i className="feather-video font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
      </NavLink>
      <AuthTag>
        <NavLink
          style={({ isActive }) => ({ color: isActive ? "green" : "blue" })}
          className="p-2 text-center ms-0 menu-icon center-menu-icon"
          to={`/profile/${user?._id}`}
        >
          <i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
        </NavLink>
      </AuthTag>
      <AuthTag>
        <NavLink
          style={({ isActive }) => ({ color: isActive ? "green" : "blue" })}
          className="p-2 text-center ms-0 menu-icon center-menu-icon"
          to="/settings"
        >
          <i className="feather-settings font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i>
        </NavLink>
      </AuthTag>

      {isLoading ? (
        <Fragment>
          {!user ? (
            <span className="p-2 pointer text-center ms-auto menu-icon">
              <i className="feather-bell font-xl header-icon"></i>
            </span>
          ) : (
            <NotificationDropdown />
          )}
        </Fragment>
      ) : (
        <span className="p-2 pointer text-center ms-auto menu-icon"></span>
      )}

      {!isLoading && user && <NotificationDropdown />}
      <div className="menu-icon">
      <Darkbutton />
      </div>

      {isLoading ? (
        <Fragment>
          {!user ? (
            <div className="p-0 ms-3 rounded-circle w40 h40 loading d-none-xs" />
          ) : (
            <></>
          )}
        </Fragment>
      ) : user ? (
        <Fragment>
          <div
            className={`p-0 ms-3 menu-icon pointer`}
            id="dropdownMenu4"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={toggleOpen}
          >
            {user?.profile?.picture?.length ? (
              <img
                src={user?.profile?.picture}
                alt={user?.username}
                className="rounded-circle w40 h40"
              />
            ) : (
              <Avatar
                name={user?.username}
                className="rounded-circle w40 h40"
              />
            )}
          </div>
          <div
            className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg theme-dark-bg`}
            aria-labelledby="dropdownMenu4"
          >
            <h4 className="fw-700 p-2 font-xss text-grey-900 d-flex align-items-center">
              Settings
              <i
                className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900"
                onClick={toggleOpen}
              ></i>
            </h4>

            <Link to={`/profile/${user._id}`}>
              <div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
                <i className="feather-user text-grey-500 me-2 font-sm"></i>
                <div className="fw-600 mb-0 text-dark me-4">
                  <span className="font-xsss">Profile</span>
                </div>
              </div>
            </Link>

            <Link to="/settings">
              <div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
                <i className="feather-settings text-grey-500 me-2 font-sm"></i>
                <div className="fw-600 mb-0 text-dark me-4">
                  <span className="font-xsss">Settings</span>
                </div>
              </div>
            </Link>

            {!!user.claimGames.length && (
              <Link to={`/analytics/${user.claimGames[0]}`}>
                <div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
                  <i className="feather-layout text-primary me-2 font-sm"></i>
                  <div className="fw-600 mb-0 text-dark me-4">
                    <span className="font-xsss">Dashboard</span>
                  </div>
                </div>
              </Link>
            )}

            <div
              className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer"
              onClick={handleLogout}
            >
              <i className="feather-log-out text-danger me-2 font-sm"></i>
              <div className="fw-600 mb-0 text-dark me-4">
                <span className="font-xsss">Logout</span>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <Link
            to="/login"
            className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl"
          // onClick={handleLogout}
          >
            Login
          </Link>
          <Link
            to="/register"
            className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl"
          // onClick={handleLogout}
          >
            Register
          </Link>
        </Fragment>
      )}

      <Leftnav />
    </div>
  );
};

export default Header;
