const links = ['About', 'Contributions', 'Media']

const UProfileCard = (): JSX.Element => {
	return (
		<div className='card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl mb-3 pb-3'>
			<div
				className='card-body h250 p-0 rounded-xxl overflow-hidden m-3'
				style={{ height: '250px' }}
			>
				<div className='profile-banner loading' />
			</div>

			<div className='card-body p-0 position-relative'>
				<figure
					className='avatar position-absolute w100 z-index-1'
					style={{ top: '-40px', left: '30px' }}
				>
					<div className='float-right p-1 bg-white rounded-circle h100 w100 loading' />
				</figure>

				<h4 className='fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15'>
					<span className='loading'>Placeholder</span>

					<span className='fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block'>
						<span className='loading'>Placeholder</span>
					</span>
				</h4>

				<div className='d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2'>
					<div className='d-none d-lg-block bg-primary-gradiant p-2 lh-20 w100 me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border-0 loading'>
						Follow
					</div>

					<div className='ms-3 pointer'>
						<i className='ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss loading'></i>
					</div>
				</div>
			</div>

			<div className='card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs'>
				<ul
					className='nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4'
					id='nav-tab'
					role='tablist'
				>
					{links.map((link, index) => (
						<li className={`${index === 0 && 'active'} list-inline-item me-5`}>
							<div className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block'>
								{link}
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

export default UProfileCard
