import { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import IGameData, {
  IPostData,
  IUserData,
} from "../../../../types/global.interface";

const TabOne = [
  {
    image: "01",
    bigImage: "/assets/images/avatar.png",
  },
];

const Profilephotos = ({
  posts,
}: {
  posts: (IPostData<IUserData | IGameData> | any)[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const mediaCount = posts.filter((item) => item.imageUrl)

  return (
    <>
      {mediaCount.length
        ?
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
          <div className="card-body d-flex align-items-center p-4">
            <h4 className="fw-700 mb-0 font-xssss text-grey-500">Photos</h4>
          </div>
          <div className="card-body d-block pt-0 pb-3">
            <div className="row ps-3 pe-3">
              {posts.length
                ? posts?.slice(0, 6)?.map((value, index) => (
                  <div className="col-6 p-1" key={index}>
                    {isOpen && (
                      <Lightbox
                        mainSrc={posts[tab]?.imageUrl || ""}
                        nextSrc={posts[(tab + 1) % posts.length]?.imageUrl || ""}
                        prevSrc={
                          posts[(tab + posts.length - 1) % posts.length]
                            ?.imageUrl || ""
                        }
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                          setTab((tab + posts.length - 1) % posts.length)
                        }
                        onMoveNextRequest={() => setTab((tab + 1) % posts.length)}
                      />
                    )}

                    <div onClick={() => [setIsOpen(true), setTab(index)]}>
                      <a href="#portfolio-details">
                        <img
                          src={`${value?.imageUrl}`}
                          alt="Portfolio"
                          className="img-fluid rounded-3 h100 w-100 o-fit"
                        />
                      </a>
                    </div>
                  </div>
                ))
                : TabOne.map((value, index) => (
                  <div className="col-6 p-1" key={index}>
                    {isOpen && (
                      <Lightbox
                        mainSrc={TabOne[tab].bigImage}
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                          setTab((tab + TabOne.length - 1) % TabOne.length)
                        }
                        onMoveNextRequest={() =>
                          setTab((tab + 1) % TabOne.length)
                        }
                      />
                    )}

                    <div onClick={() => [setIsOpen(true), setTab(index)]}>
                      <a href="#portfolio-details">
                        <img
                          src={`${value.bigImage}`}
                          alt="Portfolio"
                          className="img-fluid rounded-3 w-100"
                        />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        : <></>
      }
    </>
  );
};

export default Profilephotos;
