import { useEffect, useState, VFC } from "react";
import Slider from "react-slick";
import UserCard from "./UserCard";
import { getallPopularUsers } from "../../../services/user.service";
import { ListUserQuery, User } from "../../../types/global.interface";
import UserSliderPlaceholder from "../../common/UserSliderPlaceholder";

const UserSlider: VFC = (): JSX.Element => {
  const [userList, setUserList] = useState<(User | null)[]>([]);
  const [loading, setLoading] = useState(false);
  const [page] = useState(1);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const {
        data,
      }: {
        data: ListUserQuery;
      } = await getallPopularUsers({ page });
      if (data.users) {
        setUserList([...data?.users]);
      }
    } catch (error) {
      // TODO: Show error toast with message
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
  };
  return (
    <>
      {loading ? (
        <Slider {...sliderSettings}>
          {[1, 2, 3, 4].map((_, index) => {
            return <UserSliderPlaceholder key={`review_${index}`} />;
          })}
        </Slider>
      ) : (
        <Slider {...sliderSettings}>
          {/* Games Carousel */}
          {userList &&
            userList?.map((user, index: number) => {
              return (
                <UserCard
                  key={index}
                  user={user}
                  userList={userList}
                  setUserList={setUserList}
                />
              );
            })}
        </Slider>
      )}
    </>
  );
};

export default UserSlider;
