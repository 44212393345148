import _ from "lodash";
import moment from "moment";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import Linkify from 'linkify-react';
import 'linkify-plugin-hashtag';
import 'linkify-plugin-mention';
import Comments from "../../Comments";
import AuthTag from "../../../common/AuthTag";
import CreateComment from "../../CreateComment";
import { AppContext } from "../../../../contexts";
import ShareDropdown from "../../../common/ShareDropdown";
import PostSettingsDropdown from "../PostSettingsDropDown";
import { likePost, unLikePost } from "../../../../services/post.service";
import { getAllComments } from "../../../../services/comment.service";
import IGameData, {
  IPostData,
  IUserData,
  ListCommentsQuery,
  Comment,
} from "../../../../types/global.interface";
import Report from "../../Report/index.tsx";
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import PostPreview from "../../../../placeholder/posts/PostPreview";
import { checkIsOnline } from "../../../../helpers/onlineUsers";

export const parseMsgForMentions = (body: string) => {
  if (body.includes("@[") && body.includes("](") && body.includes(")")) {
    const refined: any[] = [];

    body.split(/\@(.*?)\)/g).forEach((word) => {
      if (word.startsWith("[") && word.includes("](")) {
        const startIndex = word.indexOf("[") + 1;
        const endIndex = word.indexOf("]");
        const urlStartIndex = word.indexOf("(") + 1;

        refined.push(
          <Link key={`${Math.random()}-${word}`} to={`/profile/${word.substring(urlStartIndex)}` }>
            {word.substring(startIndex, endIndex)}
          </Link>
        );
        refined.push(" ");
      } else {
        refined.push(`${word} `);
      }
    });
    return refined;
  }
  return body;
};

const Post = ({
  post,
  createdAt,
  likes,
  likesUserData,
  postList,
  setPosts,
}: {
  post: IPostData<IUserData | IGameData | any>;
  createdAt: string;
  likes: string | number;
  likesUserData: any;
  postList?: IPostData<IUserData | IGameData>[];
  setPosts?: Function;
}) => {
  const { user } = useContext(AppContext);
  const [addComment, setAddComment] = useState(false);
  const [commentsCount, setCommentsCount] = useState(0);
  const [comments, setComments] = useState<(Comment | null)[]>([]);
  const {onlineUsers}=useContext(AppContext)

  const urlRegex = /(https?:\/\/[^ ]*)/;

  const preview = post.body.match(urlRegex) ? post.body.match(urlRegex)![0] : ''

  const isLiked = post.likedBy.find((item) => item.email === user?.email)
    ? true
    : false;
  const [timeoutd, setTimeoutId] = useState(0);

  const likeBtn = (type: "like" | "unlike") => {
    if (postList?.length) {
      const index = postList.findIndex((item) => item._id === post._id);
      let ourPost = _.cloneDeep(post);
      if (user) {
        if (type === "like") {
          if (!ourPost.likedBy.find((item) => item.email === user.email))
            ourPost.likedBy.push(user);
        } else if (type === "unlike") {
          const index = ourPost.likedBy.findIndex(
            (item) => item.email === user.email
          );
          if (typeof index === "number") ourPost.likedBy.splice(index, 1);
        }
      }

      setPosts &&
        setPosts([
          ...postList.slice(0, index),
          ourPost,
          ...postList.slice(index + 1),
        ]);
    }
  };

  const handleLike = async (id: string, type: string) => {
    try {
      //setLoading(true);
      if (type === "like") {
        await likePost(id);
      } else if (type === "unlike") {
        await unLikePost(id);
      }
    } catch (err) {
      console.log("Error:", err);
      likeBtn(type === "like" ? "unlike" : "like");
    } finally {
      //setLoading(false);
    }
  };

  const fetchComments = async () => {
    try {
      const {
        data,
      }: {
        data: ListCommentsQuery;
      } = await getAllComments({
        filter: JSON.stringify({
          parent: post._id,
        }),
      });
      setCommentsCount && setCommentsCount(data?.comments?.length || 0);
      setComments(data?.comments || []);
    } catch (error) {
      // TODO: Need to add error toast.
      console.log(error);
    } finally {
    }
  };
  useEffect(() => {
    fetchComments();
  }, [post._id]);

  const content = post.body

  const options = {
    formatHref: {
      hashtag: (href: any) => 'https://twitter.com/hashtag/' + href.substr(1),
      mention: (href: any) => '#' + href // TO DO needs to convert ID in the url to username 
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const menuClass = `${isOpen ? " show" : ""}`;

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="card-body p-0 d-flex">
        <figure className="avatar me-3">
          <a href={`/profile/${post.owner._id}`}>
            {post.owner.profile?.picture?.length ? (
              <img
                src={post.owner?.profile?.picture}
                alt={post.owner.username}
                className="rounded-circle h45 w45"
              />
            ) : (
              <Avatar
                name={post.owner.username}
                className="rounded-circle h45 w45"
              />
            )}
          </a>
        </figure>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          <a className="text-dark" href={`/profile/${post.owner._id}`}>
            {post.owner.username}
          </a>
          {post.owner.username&&checkIsOnline(post.owner, onlineUsers) 
             &&<span className="btn-round-xss ms-2 bg-success me-1"></span>
          }
          <a 
          className="text-grey-500 fw-500 ms-1" 
          href={post.postedToType=='Game'?`/game/${post.postedTo._id}`:`/profile/${post.postedTo._id}`}>
             @{post.postedToType=='Game'?post.postedTo.name.replace(/[^A-Z0-9]+/ig, ''):post.postedTo.username.replace(/[^A-Z0-9]+/ig, '')}
          </a>
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            {moment(createdAt, "LLL").fromNow()}
          </span>
        </h4>
        {post.owner._id === user?._id ? (
          <PostSettingsDropdown
            ownerId={post.owner._id}
            post={post}
            setPosts={setPosts}
            postList={postList}
          />
        ) : (
          <Report />
        )}
      </div>

      <div className="card-body p-0 me-lg-5">
        <Linkify
          tagName="p"
          className='fw-500 text-grey-600 lh-26 font-xsss w-100 mb-2'
          options={options}>
          {parseMsgForMentions(content)}
        </Linkify>
      </div>

      {preview && !post.imageUrl ? (
        <LinkPreview
          url={preview}
          width='100%'
          customLoader={<PostPreview />}
          className=
          "card-body p-0 mb-3 rounded-xxl overflow-hidden uttam-die video-btn" />
      ) : (
        ''
      )}

      {post.imageUrl ? (
        <div className="card-body d-block p-0 mb-3">
          <div className="row ps-2 pe-2">
            <div className="col-sm-12 p-1">
              <img
                src={post.imageUrl}
                className="Container rounded-xxl w-100"
                alt={post.owner.username}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <AuthTag>
        <div className="card-body d-flex p-0">
          <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-1">
            <i
              onClick={() => {
                likeBtn(isLiked ? "unlike" : "like");
                clearTimeout(timeoutd);
                let timeout = window.setTimeout(() => {
                  handleLike(post._id, isLiked ? "unlike" : "like");
                }, 1000);
                setTimeoutId(timeout);
              }}
              className={`feather-thumbs-up btn-like${isLiked ? "-active" : ""
                } me-1 btn-round-xs font-xss `}
            ></i>
            <i
              className="feather-message-circle btn-comment me-2 btn-round-xs font-xss"
              onClick={() => setAddComment(!addComment)}
            ></i>
          </div>
          <div className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
            <div
              className={`ms-auto pointer ${menuClass}`}
              id="dropdownMenu4"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={toggleOpen}
            >
              <span className="d-none-xss">{likes} Like</span>
            </div>

            <div
              className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg ${menuClass} theme-dark-bg`}
              aria-labelledby="dropdownMenu4"
            >
              {likesUserData.map((data: any) =>
                <Link to={`/profile/${data._id}`} key={`${data._id}-likes-${Math.random()}`}>
                  <div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
                    {data.profile.picture ? (
                      <img
                        src={data.profile.picture}
                        alt={data.username}
                        className="rounded-circle h30 w30 me-2"
                      />
                    ) : (
                      <Avatar
                        name={data.username}
                        className="rounded-circle h30 w30 me-2"
                      />
                    )}
                    <span className="font-xsss text-dark">
                      {data.username}
                    </span>
                  </div>
                </Link>
              )}
              {!likesUserData.length &&
                <div className="open-font p-2 fw-600 text-center">
                  <span className="font-xsss text-grey-500">
                    No Likes
                  </span>
                </div>
              }
            </div>
          </div>
          <div
            onClick={() => setAddComment(!addComment)}
            className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ms-3 border-0"
          >
            <span className="d-none-xss">{commentsCount} Comment</span>
          </div>

          <ShareDropdown id={post._id} />
        </div>
      </AuthTag>

      <CreateComment
        open={addComment}
        setOpen={setAddComment}
        postId={post._id}
        refetchComments={fetchComments}
      />

      <Comments
        comments={comments}
        setComments={setComments}
        refetchComments={fetchComments}
      />
    </div>
  );
};

export default Post;