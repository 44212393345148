import { useContext, useState } from 'react'
import { useParams } from 'react-router'
import { Spinner } from 'react-bootstrap'

import AuthTag from '../../common/AuthTag'
import { AppContext } from '../../../contexts'
import { IUserData } from '../../../types/global.interface'
import { followUser, unFollowUser } from '../../../services/user.service'
import Avatar from 'react-avatar'

const UProfileCard = ({
	user,
	setUser,
}: {
	user: IUserData | undefined
	setUser: Function
}): JSX.Element => {
	const { user: contextUser } = useContext(AppContext)
	const { id } = useParams<{ id: string }>()
	const [isOpen, setIsOpen] = useState(false)
	const [loading, setLoading] = useState(false)

	const toggleOpen = () => setIsOpen(!isOpen)

	const menuClass = `${isOpen ? ' show' : ''}`
	const isFollowed =
		(contextUser && user?.followerUsers?.includes(contextUser._id)) || false

	const handleFollow = async (type: 'follow' | 'unfollow') => {
		try {
			setLoading(true)
			if (id) {
				if (type === 'follow') {
					await followUser({ id })
					const follows = user?.followingUsers || []
					if (!follows?.includes(contextUser?._id || '')) {
						setUser({
							...user,
							followerUsers: [...follows, contextUser?._id],
						})
					}
				} else if (type === 'unfollow') {
					await unFollowUser({ id })
					if (user?.followerUsers?.length) {
						const index = user.followerUsers.findIndex(
							item => item === contextUser?._id
						)

						if (typeof index === 'number') {
							setUser({
								...user,
								followerUsers: [
									...user.followerUsers.slice(0, index),
									...user.followerUsers.slice(index + 1),
								],
							})
						}
					}
				}
			}
		} catch (err) {
			console.log('Error ::', err)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className='card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl mb-3 pb-3'>
			<div
				className='card-body h250 p-0 rounded-xxl overflow-hidden m-3'
				style={{ height: '250px' }}
			>
				{user?.profile?.picture?.length ? (
					<img
						style={{
							filter: 'blur(13px) contrast(1)',
							transform: 'scale(1.1)',
						}}
						src={user?.profile?.picture}
						alt={user?.username}
						className='profile-banner'
					/>
				) : (
					<img
						src='/assets/images/bb-16.png'
						alt={user?.username}
						className='profile-banner'
					/>
				)}

				{user?.claimGames?.length != 0
				?
				<span className='pointer'>
					<span className='claimed fw-600 mt-1 lh-3 bg-grey mt-2 mb-0 p-1 pe-2 ps-2 z-index-1 rounded-3 text-grey-600 font-xssss'>
						<img
							className="w15 me-1"
							src="../assets/images/badges/verified.png"
							alt="verified"
						/>Game Dev
					</span>
				</span>
				:''
				}

			</div>
			<div className='card-body p-0 position-relative'>
				<figure
					className='avatar position-absolute w100 z-index-1'
					style={{ top: '-40px', left: '30px' }}
				>
					<div className='div rounded-circle-div'>
						{user?.profile?.picture.length ? (
							<img
								src={user?.profile?.picture}
								alt={user?.username}
								className='float-right bg-white rounded-circle h100 w100'
							/>
						) : (
							<Avatar
								name={user?.username}
								round={true}
								size={'100px'}
								className='float-right rounded-circle avatar-size'
							/>
						)}
					</div>
				</figure>
				<h4 className='fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15'>
					{user?.profile?.firstname || '--'} {user?.profile?.lastname}
					<span className='fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block'>
						{user?.username || '--'}
					</span>
				</h4>
				<div className='d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2'>

					<AuthTag>
						{id && contextUser?._id !== id && (
							<button
								onClick={() => handleFollow(isFollowed ? 'unfollow' : 'follow')}
								disabled={loading}
								className={`d-none d-lg-block ${isFollowed ? 'bg-primary-gradiant-active' : 'bg-primary-gradiant'
									} p-2 lh-20 w100 me-2 ms-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0`}
							>
								{loading ? (
									<Spinner animation='border' size='sm' />
								) : isFollowed ? (
									' Following'
								) : (
									' Follow'
								)}
							</button>
						)}
					</AuthTag>
					<div
						className={`ms-3 pointer ${menuClass}`}
						id='dropdownMenu4'
						data-bs-toggle='dropdown'
						aria-expanded='false'
						onClick={toggleOpen}
					>
						<i className='ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss'></i>
					</div>
					<div
						className='dropdown-menu p-2 rounded-xxl border-0 shadow-lg theme-dark-bg'
						aria-labelledby='dropdownMenu4'
					>
						<div className='nav-content-bttn open-font p-2 fw-600 text-dark d-flex pointer'>
							<i className='feather-bookmark text-grey-500 me-2 font-md'></i>
							Flag
						</div>
					</div>
				</div>
			</div>

			<div className='card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs'>
				<ul
					className='nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4'
					id='nav-tab'
					role='tablist'
				>
					<li className='active list-inline-item me-5'>
						<a
							href='/#'
							className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active'
							id='nav-about-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-about'
							role='tab'
							aria-controls='nav-about'
							aria-selected='true'
						>
							About
						</a>
					</li>

					<li className='list-inline-item me-5'>
						<a
							href='/#'
							className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block'
							id='nav-contributions-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-contributions'

							role='tab'
							aria-controls='nav-contributions'
							aria-selected='false'
						>
							Contributions
						</a>
					</li>

					<li className='list-inline-item me-5'>
						<a
							href='/#'
							className='fw-700 me-sm-5 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block'
							id='nav-media-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-media'
							role='tab'
							aria-controls='nav-media'
							aria-selected='false'
						>
							Media
						</a>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default UProfileCard
