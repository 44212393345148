import moment from "moment";
import { useContext, useEffect, useState ,} from "react";

import { AppContext } from "../../contexts";
import { actionTypes } from "../../constants";
import { SocketContext } from "../../contexts/socket";
import { INotification } from "../../types/global.interface";
import {
  deleteNotification,
  getAllNotifications,
  readNotification,
} from "../../services/notifications.service";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import NotificationPlaceholder from "./Placeholder";
import { checkIsOnline } from "../../helpers/onlineUsers";

const NotificationListItem = ({
  notification,
  allNotifications,
  setNotifications,
}: {
  notification: INotification | null;
  allNotifications: (INotification | null)[];
  setNotifications: Function;
}) => {
  const navigate = useNavigate();
  const {onlineUsers}=useContext(AppContext)
  const [isRead, setIsRead] = useState<Boolean>(
    notification?.isRead ? true : false
  );
  return (
    <li
      onClick={() => {
        if (!notification?.isRead) {
          setIsRead(true);
          readNotification({ id: notification?._id || "" });
        }
      }}
    >
        <Link 
        to={notification?.link || ""}
        className={`rounded-3 d-flex notification-li ${!isRead ? "bg-lightblue theme-light-bg" : ""
        }`}
        >
          <div className="notification-user">
            {notification?.sender.profile.picture ? (
              <img
                src={notification?.sender.profile.picture}
                alt={notification?.sender.username}
                className="rounded-circle h30 w30 me-2"
              />
            ) : (
              <Avatar
                name={notification?.sender.username}
                className="rounded-circle h30 w30 me-2"
              />
            )}
            <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700">
              {notification?.sender.username || "N/A"}
            </h6>
          {notification?.sender&&checkIsOnline(notification?.sender,onlineUsers) 
             &&<span className="btn-round-xss ms-2 bg-success me-2"></span>
          }
          </div>
          <div className="notification-description text-grey-900 text-dark fw-600 font-xssss">
            <i className={actionTypes[notification?.type || "USER_FOLLOW"]}></i>
            {notification?.type}
          </div>
          <div className="ms-2 notification-text text-grey-500 fw-600 font-xssss">
            {notification?.body}
          </div>
          <div className="notification-time text-grey-500 fw-600">
            {moment(moment(notification?.createdAt), "LLL").fromNow()}
          </div>
        </Link>
    </li>
  );
};

const NotificationsComponent = (): JSX.Element => {
  const { user } = useContext(AppContext);
  const { socket } = useContext(SocketContext);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<(INotification | null)[]>(
    []
  );

  const getUserNotifications = async () => {
    try {
      setLoading(true);
      const { data } = await getAllNotifications({});
      setNotifications(data.notifications || []);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error ::", err);
    }
  };

  useEffect(() => {
    getUserNotifications();
  }, []);

  useEffect(() => {
    if (user) {
      // subscribe to socket events
      socket?.on(`NOTIFICATION-${user._id}`, (data: INotification) => {
        if (!notifications.find((item) => item?._id === data._id)) {
          setNotifications([{ ...data }, ...notifications]);
        }
      });
    }
    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket?.off(`NOTIFICATION-${user?._id}`, () => { });
    };
  }, [notifications, socket, user]);

  return (
    <>
      <ul className="notification-message">
        {loading && <NotificationPlaceholder />}

        {notifications?.map((value, index) => {
          return (
            <NotificationListItem
              key={`notification_${index}`}
              notification={value}
              allNotifications={notifications}
              setNotifications={setNotifications}
            />
          );
        })}
      </ul>
    </>
  );
};

export default NotificationsComponent;
