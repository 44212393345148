import React from 'react'

class RHomeLayout extends React.Component<any> {
	public render() {
		return (
			<div className='col-xl-4 col-xxl-3 col-lg-4 ps-lg-0'>
				{this.props.children}
			</div>
		)
	}
}

export default RHomeLayout
