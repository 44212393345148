import { useContext, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { AppContext } from "../../../contexts";
import { Comment } from "../../../types/global.interface";
import Avatar from "react-avatar";
import { parseMsgForMentions } from "../Posts/Post";
import AuthTag from "../../common/AuthTag";
import LikeComment from "./LikeComment";
import DeleteCommentModal from "./DeleteCommentModal";

const CommentCard = ({
  comment,
  comments,
  setComments,
  setEditComment,
  setOpenEdit,
  refetchComments,
}: {
  comment: Comment | null;
  comments: (Comment | null)[];
  setComments: Function;
  setEditComment: Function;
  setOpenEdit: Function;
  refetchComments: Function;
}) => {
  const { user } = useContext(AppContext);
  const [isCommentLikesOpen, setIsCommentLikesOpen] = useState(false);

  const menuClass = `${isCommentLikesOpen ? " show" : ""}`;
  const isLiked = comment?.likedBy.find((item) => item._id === user?._id)
    ? true
    : false;
  const createdAt = moment(comment?.createdAt).format("LLL");

  const toggleOpen = () => {
    setIsCommentLikesOpen(!isCommentLikesOpen);
  };

  return (
    <div className="comment-body">
      <div className="messages-content pb-1">
        <div className="message-item">
          <div className="message-user ms-2">
            <figure className="avatar mb-auto">
              <a href={`/profile/${comment?.owner._id}`}>
                {comment?.owner.profile?.picture?.length ? (
                  <img
                    src={comment?.owner.profile?.picture}
                    alt={comment?.owner.username}
                    className="rounded-circle h45 w45"
                  />
                ) : (
                  <Avatar
                    name={comment?.owner.username}
                    className="rounded-circle h45 w45 me-10px"
                  />
                )}
              </a>
            </figure>

            <div>
              <div className="message-wrap">
                <span className="fw-700 text-grey-900 font-xssss mt-1">
                  <a
                    className="text-black z-index-9"
                    href={`/profile/${comment?.owner._id}`}
                  >
                    {comment?.owner.username || "username"}
                  </a>
                  <span className="d-block font-xsss fw-500 mt-1 text-grey-600">
                    {parseMsgForMentions(comment?.body || "N/A")}
                  </span>
                </span>
              </div>

              <div className="card-body d-flex ms-2 p-0">
                <AuthTag>
                  <LikeComment
                    value={comment}
                    comments={comments}
                    setComments={setComments}
                  />
                  <div className="btn-toolbar">
                    {comment?.owner._id === user?._id && (
                      <div
                        className="d-flex align-items-center fw-600 text-grey-500 text-dark lh-26 font-xssss pointer border-0 ms-3"
                        onClick={() => {
                          setEditComment(comment);
                          setOpenEdit(true);
                        }}
                      >
                        <span className="d-none-xss">
                          <u>Edit</u>
                        </span>
                      </div>
                    )}

                    {comment?.owner._id === user?._id && (
                      <DeleteCommentModal
                        commentId={comment?._id || ""}
                        refetchComments={refetchComments}
                      />
                    )}
                  </div>
                </AuthTag>
                <span className="d-flex align-items-center fw-600 text-grey-500 text-dark lh-26 font-xssss ms-3 d-none-xss">
                  {moment(createdAt, "LLL").fromNow()}
                </span>
              </div>
              <div>
                <div
                  className={`ms-auto pointer ${menuClass}`}
                  id="dropdownComment"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={toggleOpen}
                >
                  <div
                    style={{ float: "right" }}
                    className="d-flex align-items-center fw-600 text-grey-500 text-dark font-xssss shadow-sm btn-like-icon-bd bg-light theme-dark-bg"
                  >
                    <i
                      className={`feather-thumbs-up btn-like-icon${
                        isLiked ? "-active" : ""
                      } me-1 btn-round-icon-xs `}
                    ></i>
                    {comment?.likedBy.length || 0}
                  </div>
                </div>

                <div
                  className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg ${menuClass} theme-dark-bg lh-26`}
                  aria-labelledby="dropdownComment"
                >
                  {!comment?.likedBy?.length ? (
                    <div className="open-font p-2 fw-600 text-center">
                      <span className="font-xsss text-grey-500">No Likes</span>
                    </div>
                  ) : (
                    comment?.likedBy.map((data: any) => (
                      <Link
                        to={`/profile/${data._id}`}
                        key={`${data._id}-likes-${Math.random()}`}
                      >
                        <div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
                          {data?.profile?.picture ? (
                            <img
                              src={data?.profile?.picture}
                              alt={data?.username}
                              className="rounded-circle h30 w30 me-2"
                            />
                          ) : (
                            <Avatar
                              name={data?.username}
                              className="rounded-circle h30 w30 me-2"
                            />
                          )}
                          <span className="font-xsss text-dark">
                            {data?.username}
                          </span>
                        </div>
                      </Link>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommentCard;
