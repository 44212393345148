const RatingsSummary = ({
  experience,
  difficulty,
  hoursPlayed,
}: {
  experience: string;
  difficulty: string;
  hoursPlayed: string;
}): JSX.Element => {
  return (
    <div className="pt-2 pb-2">
      <div className="row">
        <div className="col-4">
          {(() => {
            switch (experience) {
              case "Positive":
              case "positive":
                return (
                  <>
                    <span
                      className="text-dark font-xssss me-2 fw-700"
                      id="hide"
                    >
                      Experience
                    </span>
                    <span className="shape-circle shape-red mx-2"></span>{" "}
                    <span className="text-dark text-grey-900 font-xssss">
                      Positive
                    </span>
                  </>
                );
              case "Negative":
              case "negative":
                return (
                  <>
                    <span
                      className="text-dark font-xssss me-2 fw-700"
                      id="hide"
                    >
                      Experience
                    </span>
                    <span className="shape-circle shape-red-light mx-2"></span>{" "}
                    <span className="text-dark text-grey-900 font-xssss">
                      Negative
                    </span>
                  </>
                );
              case "Neutral":
              case "neutral":
                return (
                  <>
                    <span
                      className="text-dark font-xssss me-2 fw-700"
                      id="hide"
                    >
                      Experience
                    </span>
                    <span className="shape-circle shape-red-dark mx-2"></span>{" "}
                    <span className="text-dark text-grey-900 font-xssss">
                      Neutral
                    </span>
                  </>
                );
              default:
                return <></>;
            }
          })()}
        </div>
        <div className="col-4">
          {(() => {
            switch (difficulty) {
              case "Easy":
              case "easy":
                return (
                  <>
                    <span
                      className="text-dark font-xssss mx-2 fw-700"
                      id="hide"
                    >
                      Difficulty
                    </span>
                    <span className="shape-circle shape-green mx-2"></span>{" "}
                    <span className="text-dark text-grey-900 font-xssss">
                      Easy
                    </span>
                  </>
                );
              case "Hard":
              case "hard":
                return (
                  <>
                    <span
                      className="text-dark font-xssss mx-2 fw-700"
                      id="hide"
                    >
                      Difficulty
                    </span>
                    <span className="shape-circle shape-green-light mx-2"></span>{" "}
                    <span className="text-dark text-grey-900 font-xssss">
                      Hard
                    </span>
                  </>
                );
              case "Normal":
              case "normal":
                return (
                  <>
                    <span
                      className="text-dark font-xssss mx-2 fw-700"
                      id="hide"
                    >
                      Difficulty
                    </span>
                    <span className="shape-circle shape-green-dark mx-2"></span>{" "}
                    <span className="text-dark text-grey-900 font-xssss">
                      Normal
                    </span>
                  </>
                );
              default:
                return <></>;
            }
          })()}
        </div>
        <div className="col-4">
          <span className="text-dark font-xssss mx-2 fw-700" id="hide">
            Time Spent
          </span>
          <span className="shape-circle shape-yellow mx-2"></span>{" "}
          <span className="text-dark text-grey-900 font-xssss">
            {hoursPlayed?.includes(">")
              ? hoursPlayed.replace(">", "+")
              : hoursPlayed.replace("<", "-")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RatingsSummary;
