interface IValues  {
  authentic: number;
  relevant: number;
  complete: number;
  correct: number;
};


const getValue = (value: number): number => {
  if(value === 1) return -0.25;
  if(value === 2) return -0.20;
  if(value === 3) return 0.15;
  if(value === 4) return 0.20;
  if(value === 5) return 0.25;

  return 0;
}

export const getPoint = (values: IValues): number => {
  
  const grade = getValue(values?.authentic) + getValue(values?.relevant) + getValue(values?.complete) + getValue(values?.correct);
  
  return grade;
};






