import Layout from '../../components/Layout'
import SearchComponent from '../../components/Search'
import { Helmet } from "react-helmet";

const SearchResults = (): JSX.Element => {

	return (
		<Layout>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Search Results</title>
			</Helmet>
			<div className='col-xl-10'>
				<SearchComponent />
			</div>
		</Layout>
	)
}

export default SearchResults
