import { useEffect, useState, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import PopularGames from "../../components/RightNav/PopularGames";
import PopularMembers from "../../components/RightNav/PopularUsers";
import SuggestedGames from "../../components/RightNav/SuggestedGames";
import Posts from "../../components/Posts/Posts";
import Load from "../../components/Posts/Load";
import {
  getAllPosts,
  getPostById,
  getSearchPost,
} from "../../services/post.service";
import IGameData, {
  IGameResponse,
  IPostData,
  IUserData,
} from "../../types/global.interface";
import Layout from "../../components/Layout";
import CHomeLayout from "../../components/Layout/HomeLayout/MHomeLayout";
import RHomeLayout from "../../components/Layout/HomeLayout/RHomeLayout";
import { allGames } from "../../services/game.service";
import { AppContext } from "../../contexts";
import PostPlaceholder from "../../placeholder/posts";
import { Helmet } from "react-helmet";

const defaultPosts: IPostData<IUserData | IGameData>[] = [];

const PostPage = (): JSX.Element => {
  const { user } = useContext(AppContext);

  const { id } = useParams<{ id: string }>();

  const [games, setGames] = useState<IGameResponse>();
  const [posts, setPosts] =
    useState<IPostData<IUserData | IGameData>[]>(defaultPosts);
  const [isError, setIsError] = useState(false);
  const [, setHasNextPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const getPosts = async (page: number) => {
    try {
      setLoading(true);
      if (id) {
        const { data } = await getPostById(id);
        setPosts([data]);
      } else {
        const { data } = searchValue
          ? await getSearchPost(searchValue, page)
          : await getAllPosts(page);
        setHasNextPage(data?.hasNextPage);
        pageNumber > 1
          ? setPosts([...posts, ...data?.posts])
          : setPosts([...data?.posts]);
      }
    } catch (err) {
      console.log(err);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  const mountEffect = () => {
    getPosts(pageNumber);
  };

  useEffect(() => {
    const fetchAllGames = async () => {
      try {
        const res = await allGames({
          page: 1,
        });
        setGames(res?.data);
      } catch (error) {
        console.log("error");
      }
    };
    fetchAllGames();
  }, []);

  useEffect(mountEffect, [pageNumber, searchValue, id]);

  return user && !user?.active ? (
    <Navigate to="/auth/verify" replace={true} />
  ) : (
    <Layout setSearchValue={setSearchValue} setPageNumber={setPageNumber}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{posts.map((post) => post.owner.username)}</title>
      </Helmet>

      <div className="row feed-body">
        {isError && <PostPlaceholder />}

        {loading && !posts.length && <PostPlaceholder />}
        {!!posts?.length && (
          <>
            <CHomeLayout searchValue={""}>
              <Posts list={posts.slice(0, 3)} />
              {loading && <Load />}
            </CHomeLayout>
            <RHomeLayout>
              <PopularGames />
              <PopularMembers />
              <SuggestedGames data={games} />
            </RHomeLayout>
          </>
        )}
      </div>
    </Layout>
  );
};

export default PostPage;
