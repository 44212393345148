import { Fragment, useContext, useState } from 'react'
import Avatar from 'react-avatar'
import { Link } from 'react-router-dom'
import { AppContext } from '../../../contexts'
import { SocketContext } from '../../../contexts/socket'

const Appfooter = () => {
	const { user, setUser, setIsLoggedIn, isLoading ,setOnlineUser} = useContext(AppContext)
	const { socket } = useContext(SocketContext)
	const [isOpen, setIsOpen] = useState(false)

	const toggleOpen = () => {
		setIsOpen(!isOpen)
	}

	const handleLogout = () => {
		setUser(null)
		setIsLoggedIn(false)
		setOnlineUser(()=>[])
		socket?.disconnect()
		localStorage.removeItem('token')
	}

	return (
		<div className='app-footer border-0 shadow-lg bg-primary-gradiant-active'>
			<Link to='/' className='nav-content-bttn nav-center'>
				<i className='feather-home'></i>
			</Link>

			<Link to='/games/discover' className='nav-content-bttn'>
				<i className='feather-compass'></i>
			</Link>

			<Link to='/coming-soon' className='nav-content-bttn' data-tab='chats'>
				<i className='feather-video'></i>
			</Link>

			<a href='https://media.unigamer.com' className='nav-content-bttn'>
				<i className='feather-cast'></i>
			</a>

			{isLoading ? (
				<Fragment>
					{!user ? (
						<Link to={''}>
							<i className='feather-user w30 h30 rounded-xxl loading'></i>
						</Link>
					) : (
						<></>
					)}
				</Fragment>
			) : user ? (
				<Fragment>
					<div
						id='dropdownMenuMobile'
						data-bs-toggle='dropdown'
						aria-expanded='false'
						onClick={toggleOpen}
					>
						{user?.profile?.picture?.length ? (
							<img
								src={user?.profile?.picture}
								alt={user?.username}
								className='rounded-xxl w30 h30'
							/>
						) : (
							<Avatar name={user?.username} className='rounded-xxl w30 h30' />
						)}
					</div>
					<div
						className={`dropdown-menu p-0 rounded-xxl border-0 shadow-lg theme-dark-bg`}
						aria-labelledby='dropdownMenuMobile'
					>
						<h4 className='fw-700 p-2 font-xss text-grey-900 d-flex align-items-center'>
							Settings
							<i
								className='feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900'
								onClick={toggleOpen}
							></i>
						</h4>

						<Link className="p-0" to={`/profile/${user?._id}`}>
							<div className='nav-content-bttn open-font p-3 fw-600 text-grey-900 d-flex pointer'>
								<i className='feather-user text-grey-500 me-2 font-sm'></i>

								<div className='fw-600 mb-0 text-dark mt-0 me-4'>
									<span className='font-xsss text-dark'>Profile</span>
								</div>
							</div>
						</Link>

						<Link className="p-0" to='/settings'>
							<div className='nav-content-bttn open-font p-3 fw-600 text-grey-900 d-flex pointer'>
								<i className='feather-settings text-grey-500 me-2 font-sm'></i>

								<div className='fw-600 mb-0 text-dark mt-0 me-4'>
									<span className='font-xsss text-dark'>Settings</span>
								</div>
							</div>
						</Link>

						<div
							className='p-0 nav-content-bttn open-font p-3 fw-600 text-grey-900 d-flex pointer'
							onClick={handleLogout}
						>
							<i className='feather-log-out text-grey-500 me-2 font-sm'></i>

							<div className='fw-600 mb-0 text-dark mt-0 me-4'>
								<span className='font-xsss'>Logout</span>
							</div>
						</div>
					</div>
				</Fragment>
			) : (
				<Link to='/login' className='nav-content-bttn'>
					<i className='feather-user bg-primary-gradiant w30 h40 rounded-xxl'></i>
				</Link>
			)}
		</div>
	)
}

export default Appfooter
