import { Fragment } from "react";
import CForgot from "../../../components/Auth/Forgot";
import { Helmet } from "react-helmet";

const Forgot = (): JSX.Element => {
  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Forgot Page</title>
        <link rel="canonical" href={`https://unigamer.com/forgot`} />
      </Helmet>
      <CForgot />
    </Fragment>
  );
};

export default Forgot;
