import { useContext, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { AppContext } from '../../../../contexts'
import { followGame, unFollowGame } from '../../../../services/game.service'
import { IGame } from '../../../../types/global.interface'
import AuthTag from '../../../common/AuthTag'
import ConsoleTag from '../../../common/consoleTag'
import CreateReview from '../../../Reviews/CreateReview'
import ClaimPopup from '../../ClaimPopup'

const GProfileCard = ({
	data,
	setGame,
	postCount,
	refetch,
}: {
	data: IGame | undefined
	setGame: Function
	postCount: number
	refetch: Function
}): JSX.Element => {
	const { user } = useContext(AppContext)
	const [isOpen, setIsOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const isFollowed = (user && data?.followers?.includes(user._id)) || false

	const toggleOpen = () => setIsOpen(!isOpen)

	const handleFollow = async (type: 'follow' | 'unfollow') => {
		try {
			setLoading(true)
			if (type === 'follow') {
				await followGame({ id: data?._id || '' })
			} else if (type === 'unfollow') {
				await unFollowGame({ id: data?._id || '' })
			}
			await refetch()
		} catch (err) {
			console.log('err::', err)
		} finally {
			setLoading(false)
		}
	}

	const menuClass = `${isOpen ? ' show' : ''}`
	return (
		<div className='card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 pb-3'>
			<div
				className='card-body h250 p-0 rounded-xxl overflow-hidden m-3'
				style={{ height: '250px' }}
			>
				{data?.coverUrl.length ? (
					<img
						style={{
							filter: 'blur(13px) contrast(1)',
							transform: 'scale(1.1)',
						}}
						src={data?.coverUrl}
						alt={data?.name}
						className='profile-banner'
					/>
				) : (
					<img
						src='/assets/images/bb-16.png'
						alt={data?.name}
						className='profile-banner'
					/>
				)}
				<ClaimPopup gameData={data} setGame={setGame} />
			</div>

			<div className='card-body d-block pt-4 text-center'>
				<figure className='avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto'>
					<img
						src={data?.coverUrl ? data?.coverUrl : '/assets/images/avatar.png'}
						alt='avater'
						className='p-1 bg-white rounded-xl h100 w100 '
					/>
				</figure>

				<h4 className='font-xs ls-1 fw-700 text-grey-900'>
					{data?.name || '--'}
					<span className='d-block font-xssss fw-500 mt-1 lh-3'>
						<ConsoleTag data={data} />
					</span>
				</h4>
			</div>

			<div className='card-body d-flex align-items-center ps-4 pe-4 pt-0'>
				<h4 className='font-xsssss text-center text-grey-500 fw-600 ms-2 me-2'>
					<b className='text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark'>
						{postCount || 0}
					</b>{' '}
					Posts
				</h4>

				<h4 className='font-xsssss text-center text-grey-500 fw-600 ms-2 me-2'>
					<b className='text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark'>
						{data?.followersCount || 0}
					</b>{' '}
					Followers
				</h4>

				<h4 className='font-xsssss text-center text-grey-500 fw-600 ms-2 me-2'>
					<b className='text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark'>
						{data?.reviews?.length || 0}
					</b>{' '}
					Reviews
				</h4>
			</div>
			<div className='card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0'>
				<AuthTag>
					<button
						onClick={() => {
							handleFollow(isFollowed ? 'unfollow' : 'follow')
						}}
						disabled={loading}
						className={`${
							isFollowed ? 'bg-primary-gradiant-active' : 'bg-primary-gradiant'
						} p-2 lh-20 w100 me-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0`}
					>
						{loading ? (
							<Spinner animation='border' size='sm' />
						) : isFollowed ? (
							' Following'
						) : (
							' Follow'
						)}
					</button>
				</AuthTag>

				<CreateReview refetch={refetch} game={data} />

				<div
					className={`ms-auto pointer ${menuClass}`}
					id='dropdownMenu4'
					data-bs-toggle='dropdown'
					aria-expanded='false'
					onClick={toggleOpen}
				>
					<i className='ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss'></i>
				</div>

				<div
					className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg theme-dark-bg`}
					aria-labelledby='dropdownMenu4'
				>
					<div className='nav-content-bttn open-font p-2 fw-600 text-dark d-flex pointer'>
						<i className='feather-bookmark text-grey-500 me-2 font-md'></i>
						Flag
					</div>
				</div>
			</div>

			<div className='card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs'>
				<ul
					className='nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4'
					id='nav-tab'
					role='tablist'
				>
					<li className='active list-inline-item me-5'>
						<a
							href='/#'
							className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active'
							id='nav-post-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-post'

							role='tab'
							aria-controls='nav-post'
							aria-selected='true'
						>
							Posts
						</a>
					</li>
					<li className='list-inline-item me-5'>
						<a
							href='/#'
							className='fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block'
							id='nav-review-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-review'

							role='tab'
							aria-controls='nav-review'
							aria-selected='false'
						>
							Reviews
						</a>
					</li>
					<li className='list-inline-item me-5'>
						<a
							href='/#'
							className='fw-700 me-sm-5 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block'
							id='nav-media-tab'
							data-bs-toggle='tab'
							data-bs-target='#nav-about'

							role='tab'
							aria-controls='nav-about'
							aria-selected='false'
						>
							About
						</a>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default GProfileCard
