import * as Yup from 'yup'
import { useState, useEffect } from 'react'
import { Formik, Form, FormikProps, Field } from 'formik'

import LeftBanner from '../LeftBanner'
import AuthLayout from '../../Layout/Auth'
import { IForgotPassword } from '../../../types/global.interface'
import { forgotPassword } from '../../../services/forgot.service'

import { loadRecaptcha } from '../../../helpers/recaptcha'
import { handleErrorMessage } from '../../../helpers/error'
import { showNotification } from '../../common/ShowNotification'

const ForgotPasswordPayload = Yup.object().shape({
	email: Yup.string().required('Email Required'),
})

const initialValues: IForgotPassword = {
	email: '',
}

interface IForgot extends IForgotPassword {
	token: string
}

const CForgot = (): JSX.Element => {

	const fetchReCaptchaToken = ({ email }: IForgotPassword) => {
		try {
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
						action: 'submit',
					})
					.then((token: string) => {
						handleForgotPassword({ email, token })
					})
			})
		} catch (err) {}
	}

	const handleForgotPassword = async ({ email, token }: IForgot) => {
		try {
			const { data } = await forgotPassword({
				resetemail: email,
				token,
			})

			const successMessage = data.message || 'An email will be Sent to you'

			showNotification(successMessage,'success',{autoClose:5000})

		} catch (err: any) {
			const message = handleErrorMessage(err?.response?.data?.message)

			showNotification(message,'error',{autoClose:5000})
		} 
	}

	useEffect(() => {
		loadRecaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY || '')
	}, [])

	return (
		<AuthLayout>
			<LeftBanner />

			<div className='row'>
				<div
					className='col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat '
					style={{
						backgroundImage: `url("assets/images/landingpage/background.svg")`,
						filter: 'brightness(0.8)',
					}}
				></div>

				<div className='col-xl-7 vh-100 align-items-center d-flex rounded-3 overflow-hidden'>
					<div className='shadow-none border-0 ms-auto me-auto login-card'>
						<div className='card-body rounded-0 text-left'>
						

							<h2 className='fw-700 display1-size display2-md-size mb-4'>
								Enter <br />
								your email
							</h2>

							<Formik
								validationSchema={ForgotPasswordPayload}
								initialValues={initialValues}
								onSubmit={async (values, actions) => {
									actions.setSubmitting(true)
									fetchReCaptchaToken(values)
									actions.setSubmitting(false)
								}}
							>
								{(props: FormikProps<IForgotPassword>) => {
									return (
										<Form>
											<div className='form-group icon-input mb-3'>
												<i className='font-sm ti-email text-grey-500 pe-0'></i>

												<Field
													type='text'
													name='email'
													className='style2-input ps-5 form-control text-grey-900 font-xsss fw-600'
													placeholder='Your email address'
												/>

												<small className='text-danger'>{props.errors.email}</small>
											</div>

											<div className='col-sm-12 p-0 text-left'>
												<div className='form-group mb-1'>
													<button
														type='submit'
														className='form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 '
													>
														Submit
													</button>
												</div>
											</div>
										</Form>
									)
								}}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</AuthLayout>
	)
}

export default CForgot
