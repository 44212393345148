import { IUserData } from "../../../types/global.interface";

const UProfiledetails = ({
  user,
}: {
  user: IUserData | undefined;
}): JSX.Element => {
  const followers = user?.followerUsers?.length || 0;
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-500">Details</h4>
        <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
          {user?.profile?.description || "--"}
        </p>
      </div>
      <div className="card-body border-top-xs d-flex">
        <i className="feather-users text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          {followers > 0
            ? followers
            : "--"}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            Followers
          </span>
        </h4>
      </div>

      <div className="card-body d-flex pt-0">
        <i className="feather-edit-2 text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          {user?.reviewsCount || "--"}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            Contributions
          </span>
        </h4>
      </div>
      <div className="card-body d-flex pt-0">
        <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          {user?.profile?.country || "Unknown"}, {user?.profile?.city || "--"}
        </h4>
      </div>
    </div>
  );
};

export default UProfiledetails;
