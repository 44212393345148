import { Link } from "react-router-dom";
import Slider from "react-slick";
import GameSliderPlaceholder from "../../common/GameSliderPlaceholder";

import { IGameResponse } from "../../../types/global.interface";

const GameSlider = ({ data }: { data: IGameResponse | undefined }) => {
  const membersettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
  };
  return (
    <>
      {!data ? (
        <Slider {...membersettings}>
          {[1, 2, 3, 4].map((_, index) => {
            return <GameSliderPlaceholder key={`review_${index}`} />;
          })}
        </Slider>
      ) : (
        <Slider {...membersettings}>
          {data &&
            data?.games?.map((value, index) => (
              <Link key={index} to={`/game/${value?._id}`}>
                <div className="card w200 d-block border-0 shadow-xss rounded-xxl overflow-hidden mb-3 me-3">
                  <div
                    className="card-body position-relative h100 bg-image-cover bg-image-center"
                    style={{
                      backgroundImage: `url(${value?.coverUrl}`,
                    }}
                  ></div>
                  <div className="card-body d-block w-100 ps-4 pe-4 pb-4 text-center">
                    <figure className="avatar overflow-hidden ms-auto me-auto mb-0 mt--6 position-relative w75 h75 z-index-1">
                      <img
                        src={value?.coverUrl}
                        alt={value?.name}
                        className="float-right p-1 bg-white rounded-circle-2 w75 h75"
                      />
                    </figure>
                    <div className="clearfix"></div>
                    <h4 className="fw-700 font-xsss mt-2 mb-1 trnc-game-card">
                      {value?.name}
                    </h4>
                    <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">
                      Followers: Placeholder
                    </p>
                    <span className="live-tag mt-2 mb-0 bg-grey p-2 z-index-1 rounded-3 text-grey-600 font-xsssss text-uppersace fw-700 ls-3">
                      Sponsored
                    </span>
                    <div className="clearfix mb-2"></div>
                  </div>
                </div>
              </Link>
            ))}
        </Slider>
      )}
    </>
  );
};

export default GameSlider;
