import { searchUser } from "../services/user.service";
import { ISuggestionList, IUserData } from "../types/global.interface";

interface IEvent {
  target: {
    value: string;
  };
}

export const handleMentionInputChange = (
  e: IEvent,
  id: string,
  setMessage: Function,
  setSuggestionList: Function,
  suggestionList: ISuggestionList[],
  userId?: string
) => {
  setMessage(e.target.value);
  const regex = /\((.[a-zA-Z0-9]+?)\)/g;
  const excludeUsers: string[] = [
    userId || "",
    ...getMentionIds(e.target.value),
  ];
  setSuggestionList(
    suggestionList.filter((suggestion) => !excludeUsers.includes(suggestion.id))
  );

  const msg = e.target.value
    ?.split(/\[(.*?)\]/g)
    ?.map((word: string) => {
      if (word.endsWith("@")) {
        let newWord = word.substring(0, word.length - 1);

        if (newWord.match(regex)?.length) {
          newWord = newWord
            .split(" ")
            .filter((w) => {
              if (!w.match(regex)?.length) return w;
            })
            .join(" ");
        }
        return newWord;
      }
      if (word.match(regex)?.length) {
        return word
          .split(" ")
          .filter((w) => {
            if (!w.match(regex)?.length) return w;
          })
          .join(" ");
      } else {
        return word;
      }
    })
    ?.join(" ");

  const myElement: HTMLInputElement | null = document.getElementById(
    id
  ) as HTMLInputElement;
  const startPosition = myElement?.selectionStart || 0;

  const splitMsg = msg?.substring(0, startPosition).split(" ");
  const lastWord = splitMsg[splitMsg.length - 1];

  if (lastWord.startsWith("@") && !lastWord.includes("["))
    fetchUsers(lastWord.substring(1), setSuggestionList, excludeUsers);
};
const fetchUsers = async (
  query: string,
  setSuggestionList: Function,
  excludeUsers: string[]
) => {
  try {
    const users = await searchUser(query, excludeUsers);
    let list = [];
    if (users?.data?.users?.length) {
      list = users?.data?.users?.map((user: IUserData) => ({
        id: user._id,
        display: user.username,
      }));
    }
    setSuggestionList(list);
  } catch (err) {
    console.log("err", err);
  }
};

export const getMentionIds = (body: string) => {
  const mentionIds: string[] = [];

  if (body.includes("@[") && body.includes("](") && body.includes(")")) {
    body.split(/\@(.*?)\)/g).forEach((word: string) => {
      if (word.startsWith("[") && word.includes("](")) {
        const urlStartIndex = word.indexOf("(") + 1;
        mentionIds.push(word.substring(urlStartIndex));
      }
    });
  }

  return mentionIds;
};
