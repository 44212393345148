import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import ReactModal from "react-modal";
import { Spinner } from "react-bootstrap";
import Avatar from "react-avatar";
import { Mention, MentionsInput } from "react-mentions";
import moment from "moment";
import { AppContext } from "../../../contexts";
import IGameData, {
  IPostData,
  ISuggestionList,
  IUserData,
} from "../../../types/global.interface";
import { editPost } from "../../../services/post.service";
import { getMentionIds, handleMentionInputChange } from "../../../helpers/MentionInput";

ReactModal.setAppElement("#root");

const EditPost = ({
  ownerId,
  setPosts,
  post,
  postList,
}: {
  ownerId?: string;
  setPosts?: Function;
  post?: IPostData<IUserData | IGameData>;
  postList?: IPostData<IUserData | IGameData>[];
}) => {
  const { user } = useContext(AppContext);
  const [postMessage, setPostMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [suggestionList,setSuggestionList]=useState<(ISuggestionList)[]>([])

  const createdAt = moment(post?.createdAt).format("LLL");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handleUpdate = async () => {
    try {
      setLoading(true);

      const latestMentionIds:string[]=getMentionIds(postMessage)
      const oldMentionIds:string[]=getMentionIds(post?.body||"")

      await editPost(post?._id || "", postMessage, latestMentionIds?.filter(mentionId=>!oldMentionIds?.includes(mentionId)));
      if (postList?.length && post?._id) {
        const index = postList.findIndex((item) => item._id === post._id);
        const ourPost = _.cloneDeep(post);
        ourPost.body = postMessage;
        setPosts &&
          setPosts([
            ...postList.slice(0, index),
            ourPost,
            ...postList.slice(index + 1),
          ]);
      }
    } catch (err) {
      console.log("Error ::", err);
    } finally {
      setLoading(false);
      closeModal();
    }
  };
  useEffect(() => setPostMessage(post?.body || ""), [post]);

  return (
    <div>
      <div
        className="nav-content-bttn open-font p-2 fw-600 text-dark d-flex pointer"
        onClick={() => {
          if (ownerId === user?._id) openModal();
        }}
      >
        <i className="feather-edit text-grey-500 me-2 font-sm"></i>
        <span className="font-xsss">Edit</span>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        contentLabel="onRequestClose Example"
        onRequestClose={closeModal}
        className="Modal modal-card card shadow-xss rounded-xxl border-0 p-4 mb-3"
        overlayClassName="Overlay"
      >
        <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">
          Edit Post
          <i
            className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 pointer"
            onClick={closeModal}
          ></i>
        </h4>
        <div className="card-body p-0 d-flex">
          <figure className="avatar me-3">
            {user?.profile?.picture?.length ? (
              <img
                src={user?.profile?.picture}
                alt={user?.username}
                className="rounded-circle h45 w45"
              />
            ) : (
              <Avatar
                name={user?.username}
                className="rounded-circle h45 w45"
              />
            )}
          </figure>
          <h4 className="fw-700 text-grey-900 font-xssss mt-1">
            {" "}
            {post?.owner.username}
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
              {" "}
              {moment(createdAt, "LLL").fromNow()}
            </span>
          </h4>
        </div>
        <div className="card-body p-0 mt-1 position-relative">
          {/* <textarea
            name="message"
            className="h100 bor-0 w-100 rounded-xxl p-2 ps-3 font-xssss text-grey-500 fw-500 border-light-md mb-3 theme-dark-bg"
            cols={30}
            rows={10}
            value={postMessage}
            onChange={(e) => setPostMessage(e.target.value)}
          ></textarea> */}
          <MentionsInput
					    id='edit-post-box-element'
              name='message'
              className='editMentionTextarea mentionTextarea'
              cols={30}
              rows={10}
              placeholder="What's on your mind?"
              value={postMessage} 
						  allowSuggestionsAboveCursor
						  allowSpaceInQuery
              onChange={(e)=>handleMentionInputChange(e,'edit-post-box-element',setPostMessage,setSuggestionList,suggestionList,user?._id)}
					>
						<Mention
							trigger="@"
							data={suggestionList}
							appendSpaceOnAdd	
              style={{background:"gray",color:"white"}}
						/>
					</MentionsInput>
          <button
            className="p-2 lh-20 w100 bg-primary-gradiant me-2 text-center font-xssss fw-600 ls-1 rounded-xl border-0"
            onClick={handleUpdate}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Update"
            )}
          </button>
          <button
            className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl border-0"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </ReactModal>
    </div>
  );
};

export default EditPost;
