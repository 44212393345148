import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../contexts";
import { SocketContext } from "../../../contexts/socket";
import { INotification } from "../../../types/global.interface";
import NotificationDropdownItem from "./NotificationDropdownItem";
import {
  deleteNotification,
  getAllNotifications,
  readAll,
} from "../../../services/notifications.service";

const NotificationDropdown = () => {
  const { user } = useContext(AppContext);
  const { socket } = useContext(SocketContext);

  const [, setWating] = useState(false);
  const [isNoti, setIsNoti] = useState(false);
  const [newNotification, setNewNotification] = useState(false);
  const [notifications, setNotifications] = useState<(INotification | null)[]>(
    []
  );

  const toggleisNoti = () => {
    setIsNoti(!isNoti);
  };

  const getUserNotifications = async () => {
    try {
      setWating(true);
      const { data } = await getAllNotifications({ limit: 20 });
      setNotifications(data.notifications || []);
      setWating(false);
    } catch (err) {
      setWating(false);
      toast.error(
        "Something went wrong while fetching Notification, please contact with customer support."
      );
    }
  };

  const mountEffect = () => {
    if (user) {
      getUserNotifications();
    }
  };

  useEffect(mountEffect, [isNoti, user]);

  useEffect(() => {
    if (user) {
      socket?.on(`NOTIFICATION-${user._id}`, (data: INotification) => {
        setNewNotification(true);
        if (!notifications.find((item) => item?._id === data._id)) {
          setNotifications([{ ...data }, ...notifications]);
        }
      });
    }
    return () => {
      socket?.off(`NOTIFICATION-${user?._id}`, () => {});
    };
  }, [socket, user, notifications]);

  return (
    <>
      <span
        className={`p-2 pointer text-center ms-auto menu-icon header-icon`}
        id="notifications"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={() => {
          user && toggleisNoti();
          readAll();
        }}
      >
        {user && (
          <>
            {(notifications.find((notification) => !notification?.isRead) ||
              newNotification) && (
              <span className="dot-count bg-warning"></span>
            )}
            <i className="feather-bell font-xl"></i>
          </>
        )}
      </span>

      <div
        className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg theme-dark-bg`}
        aria-labelledby="notifications"
      >
        <div className="fw-700 font-xss text-dark d-flex align-items-center p-2">
          Notification
          <Link
            to="/notifications"
            className="fw-600 me-auto ms-2 font-xssss text-primary"
          >
            See all
          </Link>
          <i
            className="feather-x ms-3 font-xssss btn-round-xs bg-greylight text-grey-900 pointer"
            onClick={toggleisNoti}
          ></i>
        </div>

        {/* {wating ? (
          "loading..."
        ) : (
          <>
            {notifications?.length ? (
              <>
                {notifications?.map((value, index) => {
                  return (
                    <NotificationItem
                      notification={value}
                      key={`noiti_${index}`}
                    />
                  );
                })}
              </>
            ) : (
              <div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
                <div className="fw-600 mb-0 text-grey-500 mt-0 me-4">
                  <span className="d-none-xs font-xsss">No Notifications</span>
                </div>
              </div>
            )}
          </>
        )} */}
        {notifications?.slice(0, 5).map((value, index) => (
          <NotificationDropdownItem
            notification={value}
            key={`noiti_${index}`}
            onDeleteNotification={({ id }: { id: string }) => {
              deleteNotification({ id });
              const filteredNotification = notifications.filter(
                (item) => item?._id !== id
              );
              setNotifications([...filteredNotification]);
              // getUserNotifications();
            }}
          />
        ))}
      </div>
    </>
  );
};

export default NotificationDropdown;