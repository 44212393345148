import { useState } from "react";
import Chart from "react-apexcharts";

const DefaultAreaBarChart = () => {
  const [labels] = useState<string[]>([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);
  const [seriesPrimary, setSeriesPrimary] = useState<
    { name: string; data: number[] }[]
  >([
    {
      name: "Returning Visitors",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "New Visitors",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);

  const [optionsPrimary] = useState<any>({
    chart: {
      type: "bar",
      //   width:'100%',
      height: 300,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    legend: {
      labels: {
        colors: "grey",
        useSeriesColors: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: "grey",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "grey",
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
  });

  return (
    <>
      <div className="col-lg-12 mb-3">
        <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
          <Chart
            options={optionsPrimary}
            series={seriesPrimary}
            labels={labels}
            type="area"
            width="100%"
          />
        </div>
      </div>

      <div className="col-lg-12 mb-3">
        <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
          <Chart
            options={optionsPrimary}
            series={seriesPrimary}
            labels={labels}
            type="bar"
            width="100%"
          />
        </div>
      </div>
    </>
  );
};
export default DefaultAreaBarChart;
