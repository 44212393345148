import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import NotificationsComponent from '../../components/Notifications'
import { AppContext } from '../../contexts'
import { Helmet } from "react-helmet";

const Notifications = (): JSX.Element => {
	const { user } = useContext(AppContext)

	if (!user) {
		return <Navigate to='/home' />
	}

	return (
		<Layout>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Notifications </title>
				<link rel="canonical" href={`https://unigamer.com/notifications`} />
			</Helmet>
			<div className='col-xl-10'>
				<NotificationsComponent />
			</div>
		</Layout>
	)
}

export default Notifications
