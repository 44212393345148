import { useState } from 'react'
import {
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	RedditShareButton,
	TelegramShareButton,
	TumblrShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	FacebookMessengerShareButton,
	FacebookMessengerIcon,
	TumblrIcon,
	RedditIcon,
	WhatsappIcon,
	TwitterIcon,
	TelegramIcon,
} from 'react-share'
import { shareIconSize } from '../../constants'

const ShareDropdown = ({ id }: { id: string }) => {
	const [isActive, setIsActive] = useState(false)
	const shareOptions = `${isActive ? ' show' : ''}`

	const toggleActive = () => {
		setIsActive(!isActive)
	}
	return (
		<>
			<div
				className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ${shareOptions}`}
				id={`dropdownMenu${id}`}
				data-bs-toggle='dropdown'
				aria-expanded='false'
				onClick={toggleActive}
			>
				<i className='feather-share btn-share me-1 btn-round-xs font-xss'></i>

				<span className='d-none-xs'>Share</span>
			</div>
			<div
				className={`dropdown-menu dropdown-menu-end p-3 rounded-xxl border-0 shadow-lg right-0 ${shareOptions} theme-dark-bg`}
				aria-labelledby={`dropdownMenu${id}`}
			>
				<h4 className='fw-700 font-xss text-grey-900 d-flex align-items-center'>
					Share
					<i
						className='feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 pointer'
						onClick={toggleActive}
					></i>
				</h4>
				<div className='card-body p-0 d-flex'>
					<ul className='d-flex align-items-center justify-content-between mt-2'>
						<li className='me-1'>
							<FacebookShareButton
								url={`https://unigamer.com/post/${id}`}
								className='Demo__some-network__share-button'
							>
								<FacebookIcon size={shareIconSize} round />
							</FacebookShareButton>
						</li>

						<li className='me-1'>
							<LinkedinShareButton
								url={`https://unigamer.com/post/${id}`}
								className='Demo__some-network__share-button'
							>
								<LinkedinIcon size={shareIconSize} round />
							</LinkedinShareButton>
						</li>

						<li className='me-1'>
							<FacebookMessengerShareButton
								url={`https://unigamer.com/post/${id}`}
								appId='521270401588372'
								className='Demo__some-network__share-button'
							>
								<FacebookMessengerIcon size={shareIconSize} round />
							</FacebookMessengerShareButton>
						</li>

						<li className='me-1'>
							<TwitterShareButton
								url={`https://unigamer.com/post/${id}`}
								className='Demo__some-network__share-button'
							>
								<TwitterIcon size={shareIconSize} round />
							</TwitterShareButton>
						</li>

						<li className='me-1'>
							<TelegramShareButton
								url={`https://unigamer.com/post/${id}`}
								className='Demo__some-network__share-button'
							>
								<TelegramIcon size={shareIconSize} round />
							</TelegramShareButton>
						</li>
					</ul>
				</div>
				<div className='card-body p-0 d-flex'>
					<ul className='d-flex align-items-center justify-content-between mt-2'>
						<li className='me-1'>
							<WhatsappShareButton
								url={`https://unigamer.com/post/${id}`}
								separator=':: '
								className='Demo__some-network__share-button'
							>
								<WhatsappIcon size={shareIconSize} round />
							</WhatsappShareButton>
						</li>

						<li className='me-1'>
							<RedditShareButton
								url={`https://unigamer.com/post/${id}`}
								className='Demo__some-network__share-button'
							>
								<RedditIcon size={shareIconSize} round />
							</RedditShareButton>
						</li>

						<li className='me-1'>
							<TumblrShareButton
								url={`https://unigamer.com/post/${id}`}
								className='Demo__some-network__share-button'
							>
								<TumblrIcon size={shareIconSize} round />
							</TumblrShareButton>
						</li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default ShareDropdown
