const PostsPlaceholder = (): JSX.Element => {
	return (
		<>
			{/* Post Placeholder */}
			<div className='card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3'>
				<div className='card-body p-0 d-flex'>
					<figure className='avatar me-3'>
						<div className='rounded-circle h45 w45 loading' />
					</figure>

					<h4 className='fw-700 text-grey-900 font-xssss mt-1 trnc-game-card'>
						<span className='loading pointer-none'>Placeholder</span>
						<span className='d-block font-xssss fw-500 mt-1 lh-3 pointer-none loading'>
							Placeholder
						</span>
					</h4>

					<div className='ms-auto'>
						<i className='ti-more-alt btn-round-md font-xss loading'></i>
					</div>
				</div>

				<div className='card-body p-0 me-lg-5'>
					<p className='fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2'>
						<span className='loading pointer-none'>
							Reply Text dummy Data, Reply Text dummy Data, Reply Text dummy Data,
							Reply Data
						</span>{' '}
						<span className='loading pointer-none'>
							Reply Text dummy Data, Reply Text dummy Data, Reply Te
						</span>{' '}
					</p>
				</div>

				<div className='card-body d-flex p-0'>
					<div className='emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-1 pointer-none '>
						<i className='feather-thumbs-up me-1 btn-round-xs font-xss loading'></i>
						<i className='feather-message-circle me-2 btn-round-xs font-xss loading'></i>
					</div>

					<div className='d-flex align-items-center fw-600 lh-26 font-xssss'>
						<span className='d-none-xss'>
							<span className='pointer-none loading'>22.8K Like</span>
						</span>
					</div>

					<div className='d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ms-3'>
						<span className='d-none-xss'>
							<span className='pointer-none loading'>222 Comment</span>
						</span>
					</div>

					<div className='dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0 theme-dark-bg'>
						<h4 className='fw-700 font-xss text-grey-900 d-flex align-items-center'>
							Share
							<i className='feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900'></i>
						</h4>

						<div className='card-body p-0 d-flex'>
							<ul className='d-flex align-items-center justify-content-between mt-2'>
								<li className='me-1'>
									<span className='btn-round-lg pointer bg-facebook'>
										<i className='font-xs ti-facebook text-white'></i>
									</span>
								</li>

								<li className='me-1'>
									<span className='btn-round-lg pointer bg-twitter'>
										<i className='font-xs ti-twitter-alt text-white'></i>
									</span>
								</li>

								<li className='me-1'>
									<span className='btn-round-lg pointer bg-linkedin'>
										<i className='font-xs ti-linkedin text-white'></i>
									</span>
								</li>

								<li className='me-1'>
									<span className='btn-round-lg pointer bg-instagram'>
										<i className='font-xs ti-instagram text-white'></i>
									</span>
								</li>

								<li>
									<span className='btn-round-lg pointer bg-pinterest'>
										<i className='font-xs ti-pinterest text-white'></i>
									</span>
								</li>
							</ul>
						</div>
						<div className='card-body p-0 d-flex'>
							<ul className='d-flex align-items-center justify-content-between mt-2'>
								<li className='me-1'>
									<span className='btn-round-lg pointer bg-tumblr'>
										<i className='font-xs ti-tumblr text-white'></i>
									</span>
								</li>
								<li className='me-1'>
									<span className='btn-round-lg pointer bg-youtube'>
										<i className='font-xs ti-youtube text-white'></i>
									</span>
								</li>
								<li className='me-1'>
									<span className='btn-round-lg pointer bg-flicker'>
										<i className='font-xs ti-flickr text-white'></i>
									</span>
								</li>
								<li className='me-1'>
									<span className='btn-round-lg pointer bg-black'>
										<i className='font-xs ti-vimeo-alt text-white'></i>
									</span>
								</li>
								<li>
									<span className='btn-round-lg pointer bg-whatsup'>
										<i className='font-xs feather-phone text-white'></i>
									</span>
								</li>
							</ul>
						</div>
						<h4 className='fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3'>
							Copy Link
						</h4>
						<i className='feather-copy position-absolute right-35 mt-3 font-xs text-grey-500'></i>
						<input
							type='text'
							placeholder='https://socia.be/1rGxjoJKVF0'
							className='bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg'
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default PostsPlaceholder
