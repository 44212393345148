export const gameList = [
  {
    bgUrl: "co1z2s.jpeg",
    imageUrl: "co1z2s.jpeg",
    name: "Game 1",
    console: "@ps5",
  },
  {
    bgUrl: "co1z2s.jpeg",
    imageUrl: "co1z2s.jpeg",
    name: "Game 2",
    console: "@ps5",
  },
  {
    bgUrl: "co1z2s.jpeg",
    imageUrl: "co1z2s.jpeg",
    name: "Game 3",
    console: "@ps5",
  },
  {
    bgUrl: "co1z2s.jpeg",
    imageUrl: "co1z2s.jpeg",
    name: "Game 4",
    console: "@ps5",
  },
  {
    bgUrl: "co1z2s.jpeg",
    imageUrl: "co1z2s.jpeg",
    name: "Game 5",
    console: "@ps5",
  },
  {
    bgUrl: "co1z2s.jpeg",
    imageUrl: "co1z2s.jpeg",
    name: "Game 6",
    console: "@ps5",
  },
  {
    bgUrl: "co1z2s.jpeg",
    imageUrl: "co1z2s.jpeg",
    name: "Game 7",
    console: "@ps5",
  },
];

export const comments = [
  {
    imageUrl: "avatar.png",
    name: "User 1",
    comment:
      "Reply Text dummy Data, Reply Text dummy Data, Reply Text dummy Data, Reply Text dummy Data, Reply Text dummy Data, Reply Text dummy Data",
  },
  {
    imageUrl: "avatar.png",
    name: "User 2",
    comment: "Reply Text dummy Data",
  },
  {
    imageUrl: "avatar.png",
    name: "User 3",
    comment: "Reply Text dummy Data",
  },
];

export const usserList = [
  {
    imageUrl: "co1z2s.jpeg",
    name: "Game 1",
    followers: "12",
  },
  {
    imageUrl: "co1z2s.jpeg",
    name: "Game 2",
    followers: "18",
  },
  {
    imageUrl: "co1z2s.jpeg",
    name: "Game 3",
    followers: "28",
  },
];

export const shareIconSize = 50;

export const colors = ["#ff2d55", "#ecd900", "#00e0bd"];

export const Platforms = ["PC", "PS4", "PS5", "SWITCH", "XBOX", "XBOX-SX"];

export const Popularity = ["By Ratings", "By Popularity", "By Release"];
export const Genre = [
  "Adventure",
  "Arcade",
  "Fighting",
  "Hack and Slash/beat 'em up",
  "Indie",
  "Music",
  "Pinball",
  "Point-and-Click",
  "Puzzle",
  "Quiz/Trivia",
  "Racing",
  "Real Time Strategy (RTS)",
  "Role-playing (RPG)",
  "Shooter",
  "Simulator",
  "Sport",
  "Strategy",
  "Tactical",
];

export const actionTypes = {
  POST_LIKE:
    "feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2",
  USER_FOLLOW:
    "feather-users bg-success text-white me-1 btn-round-xs font-xss me-2",
  REPLY_LIKE:
    "feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2",
  COMMENT_LIKE:
    "feather-thumbs-up btn-like-active me-1 btn-round-xs font-xss me-2",
  COMMENT_REPLY:
    "feather-message-circle bg-blue text-white me-1 btn-round-xs font-xss me-2",
  POST_COMMENT:
    "feather-message-circle bg-blue text-white me-1 btn-round-xs font-xss me-2",
};
