import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PopularGamesPlaceholder from '../../../placeholder/home/PopularGames'
import { allGames } from '../../../services/game.service'
import { GamesEntity, IGameResponse } from '../../../types/global.interface'
import GameCard from './GameCard'

const PopularGames = () => {
	const [gamesData, setGamesData] = useState<IGameResponse>()

	useEffect(() => {
		const fetchAllGames = async () => {
			try {
				const res = await allGames({
					page: 1,
					sortBy: 'popularity',
					limit: 3,
				})
				setGamesData(res?.data)
			} catch (error) {
				console.log('error')
			}
		}
		fetchAllGames()
	}, [])
	return gamesData ? (
		<div className='card w-100 shadow-xss rounded-xxl border-0 mb-3'>
			<div className='card-body d-flex align-items-center p-4'>
				<span className='fw-700 mb-0 font-xssss text-grey-500'>Popular Games</span>

				<Link
					to='/games/discover'
					className='fw-600 ms-auto font-xssss text-primary'
				>
					See all
				</Link>
			</div>

			{gamesData?.games?.map((game: GamesEntity, index: number) => {
				return (
					<div key={index}>
						<GameCard game={game} />
					</div>
				)
			})}
		</div>
	) : (
		<PopularGamesPlaceholder />
	)
}

export default PopularGames
