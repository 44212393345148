import { Fragment } from 'react'

const GameDiscoveryPlaceholder = () => {
	return (
		<Fragment>
			<div className='card rounded-xxl shadow-xss w-100 d-block d-flex border-0 p-4 mb-3'>
				<div className='filterBox'>
					<h2 className='fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center'>
						Browse
						<form
							style={{
								position: 'absolute',
								left: 0,
								width: '100%',
								top: 0,
								paddingTop: '10px',
							}}
							action='#'
							className='pb-0 ms-auto'
						>
							<input className='menu-btn' type='checkbox' id='menu-btn' />

							<label className='menu-icon text-grey-900' htmlFor='menu-btn'>
								<span className='navicon'></span>
							</label>

							<ul
								style={{
									paddingRight: '7px',
								}}
								className='menu'
							>
								<li className='p-2 ms-2'>
									<div className='fw-700 font-xssss text-uppercase ls-3 lh-32 rounded-xl text-center align-items-center d-flex pe-3 ps-3 p-1 bg-greylight theme-dark-bg loading'>
										<i className='me-2 feather-layers font-xss text-grey-500 loading' />{' '}
										Platform
									</div>
								</li>

								<li className='p-2 ms-2'>
									<div className='fw-700 font-xssss text-uppercase ls-3 lh-32 rounded-xl text-center align-items-center d-flex pe-3 ps-3 p-1 bg-greylight theme-dark-bg loading'>
										<i className='me-2 feather-filter font-xss text-grey-500 loading' />{' '}
										Genre
									</div>
								</li>

								<li className='p-2 ms-2'>
									<div className='fw-700 font-xssss text-uppercase ls-3 lh-32 rounded-xl text-center align-items-center d-flex pe-3 ps-3 p-1 bg-greylight theme-dark-bg loading'>
										<span className='me-2'>Sort</span>
										<i
											style={{ marginLeft: 'auto' }}
											className='feather-chevron-down font-xss text-grey-500 loading'
										/>
									</div>
								</li>
							</ul>
						</form>
					</h2>
				</div>
			</div>

			<div className='row ps-2 pe-1'>
				{Array.from(Array(12).keys()).map((item,index) => (
				<div className='col-md-3 col-xss-6 pe-2 ps-2' key={`${Math.random()}-${index}`}>
						<div className='card h300 d-block border-0 shadow-xss rounded-xxl overflow-hidden mb-3 bg-image-cover'>
							<div className='card-body d-block w-100 position-absolute bottom-0 text-center'>
								<figure className='avatar ms-auto me-auto mb-0 position-relative w50 z-index-1'>
									<i className='btn-round-md bg-xo me-3 loading' />
								</figure>
								<div className='clearfix'></div>
								<h4 className='fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1 trnc-game-card loading'>
									Placeholder
								</h4>
							</div>
						</div>
					</div>
				))}
			</div>
		</Fragment>
	)
}

export default GameDiscoveryPlaceholder
