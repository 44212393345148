import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Heatmap = ({ gameData }) => {
  const [showHeatMap, setShowHeatMap] = useState(false);
  const [state, setState] = useState({
    series: [
      {
        name: "Desktops",
        data: [
          {
            x: "ABC",
            y: 10,
          },
          {
            x: "DEF",
            y: 60,
          },
          {
            x: "XYZ",
            y: 41,
          },
        ],
      },
      {
        name: "Mobile",
        data: [
          {
            x: "ABCD",
            y: 10,
          },
          {
            x: "DEFG",
            y: 20,
          },
          {
            x: "WXYZ",
            y: 51,
          },
          {
            x: "PQR",
            y: 30,
          },
          {
            x: "MNO",
            y: 20,
          },
          {
            x: "CDE",
            y: 30,
          },
        ],
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: "treemap",
      },
    },
  });

  useEffect(() => {
    if (gameData?.reviews) {
      let wordsCount = [];

      gameData?.reviews?.map((review) => {
        const words = review?.body.split(" ");

        words?.map((w) => {
          let count = 1;
          const exist = wordsCount.find((wc) => wc.x === w.toLowerCase());
          if (!exist) {
            gameData?.reviews?.map((item) => {
              if (item && item?._id !== review?._id) {
                const occurence =
                  item?.body?.toLowerCase()?.split(w.toLowerCase()).length -
                    1 || 0;
                count = count + occurence;
              }
            });
            if (count > 1 && w.length > 2)
              wordsCount.push({
                x: w.toLowerCase(),
                y: count,
              });
          }
        });
      });

      const series = [];

      if (!!wordsCount.length) {
        wordsCount.sort((a, b) => b.y - a.y);
        wordsCount = wordsCount.slice(0, 10);

        let desktopData = [];
        let mobileData = [];
        if (wordsCount.length > 1) {
          desktopData = [...wordsCount.slice(0, wordsCount.length / 2)];
          mobileData = [...wordsCount.slice(wordsCount.length / 2, 10)];
          series.push({ name: "Desktops", data: desktopData });
          series.push({ name: "Mobile", data: mobileData });
        } else {
          desktopData = wordsCount;
          series.push({ name: "Desktops", data: desktopData });
        }
        setShowHeatMap(true);
        setState({
          ...state,
          series,
        });
      } else {
        setShowHeatMap(false);
      }
    }
  }, [gameData]);

  return showHeatMap ? (
    <div id="chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="treemap"
        height={350}
      />
    </div>
  ) : (
    <></>
  );
};

export default Heatmap;
