import { useEffect, useState } from "react";
import Rating from "react-rating";
import { Link } from "react-router-dom";
import { getGameById } from "../../../services/game.service";
import { GamesEntity } from "../../../types/global.interface";

const GameCard = ({ game }: { game: GamesEntity }) => {
  const [avgRating, setAvgRating] = useState(0);
  const [reviewsCount, setReviewsCount] = useState(0);

  useEffect(() => {
    const fetchGame = async () => {
      try {
        if (game._id) {
          const res = await getGameById({
            id: game._id,
          });
          if (res.data.averageRating) setAvgRating(res.data.averageRating);
          if (res.data.reviews) setReviewsCount(res.data.reviews.length);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
      }
    };
    fetchGame();
  }, [game._id]);

  return (
    <a href={`/game/${game._id}`}>
      <div
        key={game._id}
        className="card overlay-dark w175 h240 d-block border-0 shadow-xss rounded-xxxl bg-gradiant-bottom overflow-hidden cursor-pointer mb-2 mt-0 me-3"
      >
        <img
          className="bg-image-cover o-fit w-100 h-100"
          src={game.coverUrl}
          style={{
            position: "absolute",
            background: '#343a3f'
          }}
          alt={game.name}
          loading="lazy" />
        {/* <GameRatings /> */}
        <div
          className="p-2 d-flex"
          style={{
            backgroundImage: "linear-gradient(black, #00000000)",
            position: "absolute",
            zIndex: "2",
            width: "100%"
          }}
        >
          <Rating
            readonly
            initialRating={avgRating}
            emptySymbol="feather-star emptySymbol-star ms-1"
            fullSymbol="feather-star fullSymbol-star ms-1"
          />
          <span className="text-white ms-2">({reviewsCount})</span>
        </div>
        <Link to={`/game/${game._id}`}>
          <div className="card-body d-block p-3 w-100 position-absolute bottom-0 text-center">
            <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w50 z-index-1">
              <i
                className={`btn-round-md me-3 ${game.consoles === "ps5"
                    ? "btn-round-md me-3 bg-p5"
                    : game.consoles === "ps4"
                      ? " btn-round-md me-3 bg-p4"
                      : game.consoles === "switch"
                        ? "bg-sw"
                        : game.consoles === "pc"
                          ? "bg-pc"
                          : game.consoles === "xbox-sx"
                            ? "bg-xs"
                            : "btn-round-md bg-xo me-3"
                  }`}
              >
                {game.consoles === "ps4"
                  ? "P4"
                  : game.consoles === "ps5"
                    ? "P5"
                    : game.consoles === "xbox"
                      ? "XO"
                      : game.consoles === "switch"
                        ? "SW"
                        : game.consoles === "pc"
                          ? "PC"
                          : "XS"}
              </i>
            </figure>
            <div className="clearfix mt-1"></div>
            <h4 className="fw-600 position-relative z-index-1 ls-1 font-xssss text-white mt-2 mb-1 trnc-game-card">
              {game.name}
            </h4>
          </div>
        </Link>
      </div>
    </a>
  );
};

export default GameCard;
