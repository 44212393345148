import { useState, useEffect, useContext } from 'react'
import Load from '../../../components/Posts/Load'
import Layout from '../../../components/Layout'
import GProfileCard from '../../../components/Games/GameProfileComponent/GProfileCard'
import GProfileDetails from '../../../components/Games/GameProfileComponent/GProfileDetails'
import ProfilePhotos from '../../../components/Games/GameProfileComponent/ProfilePhotos'
import Critics from '../../../components/Games/GameProfileComponent/Critics'
import CreatePost from '../../../components/Posts/CreatePost'
import Posts from '../../../components/Posts/Posts'
import IGameData, {
	IGame,
	IPostData,
	IUserData,
} from '../../../types/global.interface'
import { getAllPosts } from '../../../services/post.service'
import { getGameById } from '../../../services/game.service'
import { useParams } from 'react-router-dom'
import ReviewContainer from '../../../components/Reviews/ReviewContainer'
import Review from '../../../components/Reviews/Review'
import NothingFoundCard from '../../../components/common/NothingFoundCard'
import Report from '../../../components/Posts/Report/index.tsx'
import moment from 'moment'
import GamesPlaceholder from '../../../placeholder/games'
import { createAnalytics } from '../../../services/analytics.service'
import { AppContext } from '../../../contexts'
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import PostPreview from '../../../placeholder/posts/PostPreview'
import { Helmet } from "react-helmet";

const defaultPosts: IPostData<IUserData>[] = []

const GameProfile = (): JSX.Element => {
	const { user } = useContext(AppContext)
	const { id } = useParams<{ id: string }>()
	const [posts, setPosts] =
		useState<IPostData<IUserData | IGameData>[]>(defaultPosts)
	const [game, setGame] = useState<IGame | undefined>()
	const [loading, setLoading] = useState(false)
	const [hasNextPage, setHasNextPage] = useState(false)
	const [pageNumber, setPageNumber] = useState(1)
	const [postCount, setPostCount] = useState(0)

	const fetchGame = async () => {
		try {
			if (id) {
				setLoading(true)
				const res = await getGameById({
					id,
					getReviewUsers: true,
				})
				res?.data && setGame(res?.data)
			}
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		const fetchData = async () => { }
		if (id) {
			fetchGame()
		}
		fetchData()

		const getPosts = async (page: number) => {
			try {
				if (game) {
					setLoading(true)

					const { data } = await getAllPosts(page, {
						$or: [{ postedTo: game._id }],
					})

					setHasNextPage(data?.hasNextPage)
					pageNumber > 1
						? setPosts([...posts, ...data?.posts])
						: setPosts([...data?.posts])
					setPostCount(data?.totalDocs || 0)
				}
			} catch (err) {
				console.log(err)
				// setIsError(true);
			} finally {
				setLoading(false)
			}
		}
		getPosts(pageNumber)
	}, [pageNumber, id, game?.name])

	useEffect(() => {
		createAnalytics({
			game: id || '',
			user: user?._id || '',
		})
	}, [])

	const gameReleaseDate = moment(game?.releaseDate).format('DD-MMM-YYYY') || ''

	return (
		<Layout>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{game?.name}</title>
				<link rel="canonical" href={`https://unigamer.com/game/${game?._id}`} />
			</Helmet>
			{game && game ? (
				<div className='row'>
					<div className='col-xl-12'>
						<GProfileCard
							data={game}
							setGame={setGame}
							refetch={fetchGame}
							postCount={postCount}
						/>
					</div>

					<div
						className='col-xl-8 col-xxl-9 col-lg-8 tab-content'
						id='nav-tabContent'
					>
						<div
							className='tab-pane fade show active'
							id='nav-post'
							role='tabpanel'
							aria-labelledby='nav-post-tab'
						>
							<CreatePost
								allPosts={posts}
								setPosts={setPosts}
								postedTo={game?._id}
								postedToType='Game'
							/>

							{posts?.length ? (
								<Posts
									list={posts}
									setPosts={setPosts}
									allPosts={posts}
									hasNextPage={hasNextPage}
									pageNumber={pageNumber}
									loading={loading}
									setPageNumber={setPageNumber}
								/>
							) : (
								!!!posts.length &&
								!loading && <NothingFoundCard text='No Post Found!' />
							)}
							{loading && <Load />}
						</div>

						{/* Content Second Tab */}
						<div
							className='tab-pane fade'
							id='nav-review'
							role='tabpanel'
							aria-labelledby='nav-review-tab'
						>
							<ReviewContainer reviews={game?.reviews || []} />

							<Review
								list={game?.reviews || []}
								game={game}
								setGame={setGame}
								refetch={fetchGame}
							/>
						</div>

						<div
							className='tab-pane fade'
							id='nav-about'
							role='tabpanel'
							aria-labelledby='nav-about-tab'
						>
							<div className='card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3'>
								<div className='card-body p-0 d-flex'>
									<figure className='avatar me-3'>
										<img
											src={game?.coverUrl}
											alt={game?.name}
											className='shadow-sm rounded-circle h45 w45'
										/>
									</figure>
									<h4 className='fw-700 text-grey-900 font-xssss mt-1'>
										{game?.name}

										<span className='d-block font-xssss fw-500 mt-1 lh-3 text-grey-500'>
											{gameReleaseDate}
										</span>
									</h4>

									<Report />
								</div>

								{game?.videoUrl ? (
									<LinkPreview
										url={game?.videoUrl}
										width='100%'
										customLoader={<PostPreview />}
										className=
										"card-body p-0 mb-3 rounded-xxl overflow-hidden uttam-die video-btn" />
								) : (
									''
								)}

								<div className='card-body p-0 me-lg-5'>
									<p className='fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2'>
										{game?.description}
										<a href='/' className='fw-600 text-primary ms-2'>
											{/* See more */}
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className='col-xl-4 col-xxl-3 col-lg-4 ps-0'>
						<Critics game={game} />

						<GProfileDetails data={game} />

						<ProfilePhotos posts={posts.filter(item => item.imageUrl)} />
					</div>
				</div>
			) : (
				<GamesPlaceholder />
			)}
		</Layout>
	)
}

export default GameProfile
