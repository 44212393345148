import React from "react";
import { Socket } from "socket.io-client";

let socket: Socket | undefined;

interface ISocket {
  socket: Socket | undefined;
  setSocket: (socket: Socket) => void;
}

export const SocketContext = React.createContext<ISocket>({
  socket,
  setSocket() {},
});
