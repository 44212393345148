import axios from "../http-common";
import IGameData, {
  IPostData,
  IUserData,
  PostRootObject,
} from "../types/global.interface";

interface filterInterface {
  $or?: (
    | {
        postedTo: string;
      }
    | {
        owner?: string;
      }
  )[];
}

/**
 * @description list posts
 * @returns Promise
 */
export const getAllPosts = async (page: number, filter?: filterInterface) => {
  try {
    return await axios.get<PostRootObject>(`/posts`, {
      params: {
        page,
        filter,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getSearchPost = async (search: string, page: number) => {
  try {
    return await axios.get<PostRootObject>(`/posts/search`, {
      params: {
        q: search,
        page,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const getPostById = async (id: string) => {
  try {
    return await axios.get<IPostData<IUserData | IGameData>>(`/posts/${id}`);
  } catch (error) {
    throw error;
  }
};

export const searchPosts = async (search: string) => {
  try {
    return await axios.get<PostRootObject>(`/posts/search`, {
      params: {
        q: search,
        limit: 20,
      },
    });
  } catch (error) {
    throw error;
  }
};
export interface ICreatePostInput {
  postedToType: string;
  postedTo: string;
  body: string;
  videoUrl?: string;
  imageUrl?: string;
  mentionIds?:string[]
}

export const createPost = async (data: ICreatePostInput) => {
  try {
    return await axios.post<IPostData<IUserData | IGameData>>(`/posts`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const likePost = async (postId: string) => {
  try {
    return await axios.patch<any>(
      `/posts/${postId}/like`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};
export const unLikePost = async (postId: string) => {
  try {
    return await axios.patch<any>(
      `/posts/${postId}/unlike`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const deletePost = async (postId: string) => {
  try {
    return await axios.delete(`/posts/${postId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const editPost = async (postId: string, body: string, mentionIds:string[]) => {
  try {
    return await axios.patch(
      `/posts/${postId}`,
      { body,
        mentionIds 
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const uploadMedia = async (file: File) => {
  try {
    const data = new FormData();
    data.append("file", file, `Date.now()-${file.name}`);
    return await axios.post<any>(`/media/upload`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    throw error;
  }
};
