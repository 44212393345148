import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useOnLoadImages } from "../../../hooks/useOnLoadImages";
import Layout from "../../../components/Layout";
import { allGames } from "../../../services/game.service";
import { IGameResponse } from "../../../types/global.interface";
import GameDiscoveryPlaceholder from "../../../placeholder/games/GameDiscoveryPlaceholder";
import GameCategoryComponent from "../../../components/Games/GameDiscoveryComponent";
import { Helmet } from "react-helmet";

const GameDiscovery = (): JSX.Element => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const platform = new URLSearchParams(search).get("platform");
  const [games, setGames] = useState<IGameResponse>();
  const [pageNumber, setPageNumber] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const fetchAllGames = async ({
    sort,
    sortOrder,
    genre,
    platform,
    page,
  }: {
    sort?: string;
    sortOrder?: string;
    genre?: string;
    platform?: string;
    page?: number;
  }) => {
    try {
      setLoading(true);
      let filter = {};

      if (genre)
        filter = {
          ...filter,
          genre: { $in: genre.split(",") },
        };
      if (platform)
        filter = {
          ...filter,
          consoles: { $in: platform.split(",") },

          // $or: [{ consoles: "pc" }, { consoles: "ps4" }],
        };
      const { data } = await allGames({
        page: query.get("page") ? 1 : page || 1,
        sortBy: sort || "metaScore",
        filter,
        sortOrder,
      });

      setHasNextPage(data.hasNextPage);

      if (page && page > 1 && games && !query.get("page")) {
        const previousGames = games.games?.length ? [...games.games] : [];
        const newGames = data?.games?.length ? [...data.games] : [];
        const result = { ...data, games: [...previousGames, ...newGames] };
        setGames(result);
      } else setGames(data);
    } catch (error) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllGames({
      sort: query.get("sort") || "",
      genre: query.get("genre") || "",
      platform: query.get("platform") || "",
      page: pageNumber,
      sortOrder: query.get("sortOrder") || "-1",
    });
  }, [
    query.get("sort"),
    query.get("genre"),
    query.get("platform"),
    query.get("sortOrder"),
    pageNumber,
  ]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
				<title>Discover Games</title>
				<link rel="canonical" href={`https://unigamer.com/games/discover`} />
			</Helmet>
      <div className="col-xl-12" ref={wrapperRef}>
        {(!games && !imagesLoaded) || (loading && pageNumber === 1) ? (
          <GameDiscoveryPlaceholder />
        ) : (
          games && (
            <GameCategoryComponent
              data={games}
              setPageNumber={setPageNumber}
              hasNextPage={hasNextPage}
              pageNumber={pageNumber}
              loading={loading}
            />
          )
        )}
      </div>
    </Layout>
  );
};

export default GameDiscovery;
