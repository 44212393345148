import axios from "../http-common";
import { IEditReviewPayload, IGradeReviewPayload, IReviewPayload } from "../types/global.interface";

/**
 * @description list posts
 * @returns Promise
 */

export const addReview = async (payload: IReviewPayload) => {
  try {
    return await axios.post(
      `/reviews`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const addGradeReview = async (payload: IGradeReviewPayload) => {
  try {
    return await axios.post(
      `/reviews/add-grade-review`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const likeReview = async ({ id }: { id: string }) => {
  try {
    return await axios.patch(
      `/reviews/${id}/like`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const unLikeReview = async ({ id }: { id: string }) => {
  try {
    return await axios.patch(
      `/reviews/${id}/unlike`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const editReview = async ({
  id,
  payload,
}: {
  id: string;
  payload: IEditReviewPayload;
}) => {
  try {
    return await axios.patch(
      `/reviews/${id}`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};
export const deleteReview = async ({ id }: { id: string }) => {
  try {
    return await axios.delete(`/reviews/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    throw error;
  }
};
