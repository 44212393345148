import { VFC } from 'react'

const ReviewLoader: VFC = (): JSX.Element => {
	return (
		<div
			data-bs-toggle='modal'
			data-bs-target='#Modalstory'
			className='card w175 h240 d-block border-0 rounded-xxxl shadow-xss overflow-hidden mb-3 mt-0 me-3'
		>
			<div className='card-body d-block p-3 w-100 position-absolute bottom-0 text-center'>
				<span className='btn-round-lg loading'>
					<i className='feather-plus font-lg'></i>
				</span>

				<div className='clearfix mt-1'></div>

				<h4
					id='loading'
					className='fw-700 position-relative z-index-1 ls-1 font-xssss mt-2 mb-1 loading'
				>
					Placeholder
				</h4>
			</div>
		</div>
	)
}

export default ReviewLoader
