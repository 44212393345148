import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Link, Navigate, useParams } from "react-router-dom";
import Heatmap from "../../components/Analytics/Heatmap";
import Negative from "../../components/Analytics/ReviewsSentiment/Negative";
import Neutral from "../../components/Analytics/ReviewsSentiment/Neutral";
import Positive from "../../components/Analytics/ReviewsSentiment/Positive";
import Layout from "../../components/Layout";
import {
  getGameMainAnalytics,
  getGameVisitorsStats,
} from "../../services/analytics.service";
import {
  IAnalyticsGameData,
  IUserClaimGame,
} from "../../types/global.interface";
import { AppContext } from "../../contexts";
import { getUserClaimGame } from "../../services/game.service";
import AreaBarChart from "./AreaBarChart";
import DefaultAreaBarChart from "./DefaultAreaBarChart";
import { Helmet } from "react-helmet";

const Analytics = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false)

  const [mainAnalytics, setMainAnalytics] = useState({
    dailyVisits: 0,
    monthlyVisits: 0,
    monthlyReviewCount: 0,
    monthlyFollowCount: 0,
  });
  const [gameData, setGameData] = useState<IAnalyticsGameData | undefined>();
  const [userOtherGames, setUserOtherGames] = useState<IUserClaimGame[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [oldVisitors, setOldVisitors] = useState<number[]>([]);
  const [newVisitors, setNewVisitors] = useState<number[]>([]);

  const findGameExperince = (experience: string) => {
    const exp =
      gameData?.reviews?.filter((item) => item?.experience === experience)
        ?.length || 0;
    const total = gameData?.reviews?.length || 0;
    if (!total) return 0;
    return ((exp / total) * 100).toFixed(2);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const mountEffect = () => {
    setOldVisitors([]);
    setNewVisitors([]);
    const apiCall = async () => {
      try {
        setLoading(true)
        const date = moment().format("DD-MM-YYYY");
        const timeOffset = new Date().getTimezoneOffset() / 60;
        const { data } = await getGameMainAnalytics({
          id: id || "",
          date,
          timeOffset: -timeOffset,
        });
        const { gameData, ...rest } = data;
        setMainAnalytics(rest);
        setGameData(gameData);
        setLoading(false)

        const Visitors = await getGameVisitorsStats({
          id: id || "",
          date,
          timeOffset: -timeOffset,
        });

        setOldVisitors([
          ...Visitors.data?.map((item) => item.oldVisitCount || 0),
        ]);
        setNewVisitors([
          ...Visitors.data?.map((item) => item.newVisitCount || 0),
        ]);
      } catch (err) {
        console.log('err ::', err)
      }
    };
    apiCall();
  };

  useEffect(() => {
    let isMounted = true;
    if (id && isMounted && gameData?._id !== id) {
      mountEffect();
    }
    return () => {
      isMounted = false;
    };
  }, [id]);

  const fetchOtheGamesData = () => {
    const callApi = async () => {
      try {
        if (user?.claimGames?.length || 0 > 1) {
          const games: IUserClaimGame[] = [];
          for (const game of user?.claimGames || []) {
            const { data } = await getUserClaimGame({ id: game });
            games.push(data);
          }
          setUserOtherGames([...games]);
        }
      } catch (err) {
        console.log("Error in fetchOtheGamesData::", err);
      }
    };
    callApi();
  };

  useEffect(() => {
    if (user && !userOtherGames.length) {
      fetchOtheGamesData();
    }
  }, [user]);

  if (!user || !id || !user.claimGames.includes(id)) {
    return <Navigate to={"/home"} />;
  }

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Analytics for {gameData?.name}</title>
        <link rel="canonical" href={`https://unigamer.com/anaytics/${gameData?._id}`} />
      </Helmet>
      <div className="row">
        <div className="col-lg-12">
          <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
            <div
              className="pointer"
              id="SelectGame"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={toggleOpen}
            >
              <span className="analyticsSelection fw-600 mt-1 lh-3 text-grey-500 bg-grey mt-2 mb-0 p-1 pe-2 ps-2 z-index-1 rounded-3 text-grey-600 font-xsssss">
                {loading ? 'Loading' : gameData?.name}
              </span>
            </div>
            <div
              className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg theme-dark-bg`}
              aria-labelledby="SelectGame"
            >
              {userOtherGames?.length ? (
                userOtherGames?.map((g) => (
                  <div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
                    <div className="fw-600 mb-0 text-dark mt-0 me-4">
                      <Link to={`/analytics/${g?._id}`}>
                        <span className="d-none-xs font-xsss">{g?.name}</span>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <div className="nav-content-bttn open-font p-2 fw-600 text-grey-900 d-flex pointer">
                  <div className="fw-600 mb-0 text-dark mt-0 me-4">
                    <Link to={`/analytics/${gameData?._id}`}>
                      <span className="d-none-xs font-xsss">
                        {gameData?.name}
                      </span>
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  src="/assets/images/bg-2.png"
                  alt="banner"
                  className="w-100"
                />
              </div>
              <div className="col-lg-6 ps-lg-5">
                <h2 className="display1-size d-block mb-2 text-grey-900 fw-700">
                  Check your Analytics with Unigamer Studio
                </h2>
                <p className="font-xssss fw-500 text-grey-500 lh-26">
                  Unigamer Studio gathers trends and patterns from your game
                  profiles.
                </p>
                <a
                  href={`/game/${gameData?._id}`}
                  className="btn pb-3 pt-3 ps-5 pe-5 bg-blue text-white mr-4 text-uppercase mt-4"
                >
                  Game Page
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 pe-2">
          <div
            className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
            style={{ background: `#e5f6ff` }}
          >
            <div className="card-body d-flex p-0">
              <i className="btn-round-lg d-inline-block me-3 bg-primary-gradiant feather-home font-md text-white"></i>
              <h4 className="text-primary font-xl fw-700">
                {mainAnalytics.dailyVisits}
                <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                  Daily Visits
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-3 pe-2 ps-2">
          <div
            className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
            style={{ background: `#f6f3ff` }}
          >
            <div className="card-body d-flex p-0">
              <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-lock font-md text-white"></i>
              <h4 className="text-secondary font-xl fw-700">
                {mainAnalytics.monthlyVisits}
                <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                  Monthly Visits
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-3 pe-2 ps-2">
          <div
            className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
            style={{ background: `#e2f6e9` }}
          >
            <div className="card-body d-flex p-0">
              <i className="btn-round-lg d-inline-block me-3 bg-success feather-command font-md text-white"></i>
              <h4 className="text-success font-xl fw-700">
                {mainAnalytics.monthlyReviewCount}
                <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                  New Reviews
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-3 ps-2">
          <div
            className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3"
            style={{ background: `#fff0e9` }}
          >
            <div className="card-body d-flex p-0">
              <i className="btn-round-lg d-inline-block me-3 bg-warning feather-shopping-bag font-md text-white"></i>
              <h4 className="text-warning font-xl fw-700">
                {mainAnalytics.monthlyFollowCount}
                <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">
                  New Follower
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className="col-lg-4 pe-2">
          <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
            <Positive value={findGameExperince("positive")} />
          </div>
        </div>

        <div className="col-lg-4 pe-2">
          <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
            <Negative value={findGameExperince("negative")} />
          </div>
        </div>

        <div className="col-lg-4 pe-2">
          <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
            <Neutral value={findGameExperince("neutral")} />
          </div>
        </div>

        {oldVisitors.length && newVisitors.length ? (
          <AreaBarChart oldVisitors={oldVisitors} newVisitors={newVisitors} />
        ) : (
          <DefaultAreaBarChart />
        )}

        <div className="col-lg-12 mb-3">
          <div className="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
            <Heatmap gameData={gameData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Analytics;
