import { useContext, useState, useEffect } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import AuthLayout from '../../../components/Layout/Auth'
import { verifyEmail } from '../../../services/user.service'
import { AppContext } from '../../../contexts'
import { Helmet } from "react-helmet";

const VerifyEmail = (): JSX.Element => {
	const search = useLocation().search
	const id = new URLSearchParams(search).get('id')
	const token = new URLSearchParams(search).get('token')

	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const { user, setIsLoggedIn } = useContext(AppContext)

	useEffect(() => {
		const _verifyEmail = async () => {
			try {
				setLoading(true)
				const { data } = await verifyEmail({
					userId: id || '',
					token: token || '',
				})

				if (data?.jwtToken) {
					localStorage.setItem('token', data.jwtToken)
					setIsLoggedIn(true)
					navigate('/home')
				}
			} catch (error) {
				console.log('error', error)
			} finally {
				setLoading(false)
			}
		}
		_verifyEmail()
	}, [id, navigate, setIsLoggedIn, token])

	if (user?.active) {
		return <Navigate to='/home' replace={true} />
	}

	return (
		<AuthLayout>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Verify Email</title>
				<link rel="canonical" href={`https://unigamer.com/verify`} />
			</Helmet>
			<div className='row'>
				<div
					className='col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat '
					style={{
						backgroundImage: `url("/assets/images/landingpage/background.svg")`,
						filter: 'brightness(0.8)',
					}}
				></div>

				<div className='col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden'>
					<div className='card shadow-none border-0 ms-auto me-auto login-card'>
						<div className='card-body rounded-0 text-left'>
							<h2 className='fw-700 display1-size display2-md-size mb-3'>
								A verification link has been sent to your email address.
							</h2>
							<h3 className='fw-700 display2-md-size mb-3'>
								If you do not see the email in a few minutes, please check your junk folder.
							</h3>

							<div className='col-sm-12 p-0 text-left'>
								{token && id && (
									<div className='form-group mb-1'>
										<button
											className='form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 '
											disabled={true}
										>
											{loading ? 'Verifying...' : 'Verify'}
										</button>
									</div>
								)}
								<h6 className='text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32'>
									To access home page without verifying email
									<Link to='/home'>
										<button className='form-control text-center style2-input text-white fw-600 rounded-xl bg-current border-0 p-0'>
											Go To Home Page
										</button>
									</Link>
								</h6>
								{/* {user && (
                  <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                    To access Home page without verifying email
                    <button
                      disabled={loading}
                      onClick={handleLogout}
                      className="form-control text-center style2-input text-white fw-600 bg-danger border-0 p-0"
                    >
                      Log Out
                    </button>
                  </h6>
                )} */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</AuthLayout>
	)
}

export default VerifyEmail
