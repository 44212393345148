const PostPreview = (): JSX.Element => {
	return (
		<>
			<div data-testid="container" className="Container card-body p-0 mb-3 rounded-xxl overflow-hidden uttam-die" style={{width: "100%", backgroundColor: "white", borderColor: "rgb(204, 204, 204)"}}>
				<div data-testid="image-container" className="Image loading-preview" style={{backgroundImage: "url(https://assets-prd.ignimgs.com/2022/01/10/elden-ring-blogroll-1623770981414-1641826078940.jpg?width=1280), url(https://i.imgur.com/UeDNBNQ.jpeg)"}}></div>
					<div className="LowerContainer">
						<p className="Title" style={{color: "black"}}><span className="loading">The Best Reviewed Games of 2022 - IGN</span></p>
						<span data-testid="desc" className="loading" style={{color: "rgb(100, 100, 100)"}}>To keep track of 2022's best games  we’ve compiled awe’ve compiled a we’ve</span>
						<div style={{color: "rgb(100, 100, 100)"}}>
						<span className="loading">www.ign.com</span>
					</div>
				</div>
			</div>
		</>
	)
}

export default PostPreview
