import _ from "lodash";
import moment from "moment";
import Avatar from "react-avatar";
import Rating from "react-rating";
import { useContext } from "react";
import RatingsSummary from "./RatingsSummary";
import Linkify from "linkify-react";
import "linkify-plugin-hashtag";
import "linkify-plugin-mention";
import { AppContext } from "../../../contexts";
import ReviewSettingsDropDown from "../ReviewSettingsDropDown";
import {
  IGame,
  IGamesReviewPayload
} from "../../../types/global.interface";
import { IContributions } from "../../../pages/Users";
import Report from "../../Posts/Report/index.tsx";
import { checkIsOnline } from "../../../helpers/onlineUsers";
import CARM from "./CARM";

const ReviewCard = ({
  list,
  item,
  game,
  refetch,
  setGame,
}: {
  list: (IGamesReviewPayload | null)[];
  item: IGamesReviewPayload;
  game: IGame | any | IContributions;
  refetch: Function;
  setGame: Function;
}) => {
  const { user } = useContext(AppContext);
  const { onlineUsers } = useContext(AppContext)
  const createdAt = moment(item?.createdAt || item?.updatedAt).format("LLL");

  const content = item?.body;

  const options = {
    formatHref: {
      hashtag: (href: any) => "https://twitter.com/hashtag/" + href.substr(1),
      mention: (href: any) => "#" + href, // TO DO needs to convert ID in the url to username
    },
  };

  return (
    <div
      key={`review_`}
      className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
    >
      <div>
        <div className="card-body p-0 d-flex">
          <figure className="avatar me-3">
            <a href={`/profile/${item.owner._id}`}>
              {item.owner.profile?.picture?.length ? (
                <img
                  src={item.owner?.profile?.picture}
                  alt={item.owner.username}
                  className="rounded-circle h45 w45 avatar-index"
                />
              ) : (
                <Avatar
                  name={item.owner.username}
                  className="rounded-circle h45 w45 avatar-index"
                />
              )}
            </a>
            {item?.gameData ? (
              <img
                src={
                  item?.gameData?.coverUrl
                    ? item?.gameData?.coverUrl
                    : "/assets/images/avatar.png"
                }
                alt={item.gameData.name}
                className="rounded-circle h45 w45 m--20"
              />
            ) : (
              <img
                src={
                  game.coverUrl ? game.coverUrl : "/assets/images/avatar.png"
                }
                alt={game.name}
                className="rounded-circle h45 w45 m--20"
              />
            )}
          </figure>
          <h4 className="fw-700 text-grey-900 font-xssss mt-1 ms-3">
            {" "}
            <a className="text-dark" href={`/profile/${item.owner._id}`}>
              {item?.owner.username}{" "}
            </a>
            {item?.owner.username && checkIsOnline(item?.owner, onlineUsers)
              && <span className="btn-round-xss ms-1 bg-success me-1"></span>
            }
            <a
              className="text-grey-500 fw-500 ms-1"
              href={`/game/${item?.gameData?._id}`}>
              @{item?.gameData?.name.replace(/[^A-Z0-9]+/ig, '')}
            </a>
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
              {moment(createdAt, "LLL").fromNow()}
            </span>
          </h4>
          {item.owner._id === user?._id ? (
            <ReviewSettingsDropDown review={item} refetch={refetch} />
          ) : (
            <Report />
          )}
        </div>

        {/* Rating */}
        {/* <ReviewRatings rating={item?.rating || 0} /> */}

        <Rating
          readonly
          initialRating={item?.rating || 0}
          emptySymbol="feather-star emptySymbol-star me-1"
          fullSymbol="feather-star fullSymbol-star me-1"
        />

        {/* Rating Summary Experience, Difficulty, Time Spent */}
        <RatingsSummary
          difficulty={item?.difficulty || ""}
          experience={item?.experience || ""}
          hoursPlayed={item?.hoursPlayed || ""}
        />

        <div className="card-body p-0 me-lg-5">
          <Linkify
            className="fw-500 text-grey-600 lh-26 font-xsss w-100 mb-2"
            tagName="p"
            options={options}
          >
            {content}
          </Linkify>
        </div>
        <CARM item={item} refetch={refetch} />
      </div>
    </div>
  );
};

export default ReviewCard;
