import { Fragment, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import GameCard from './GameCard'
import { Genre, Platforms, Popularity } from '../../../constants'
import { IGameResponse } from '../../../types/global.interface'
import NothingFoundCard from '../../common/NothingFoundCard'

const GameDiscoveryComponent = ({
	data,
	setPageNumber,
	loading,
	hasNextPage,
	pageNumber
}: {
	data: IGameResponse | undefined
	setPageNumber: Function
	loading: boolean
	pageNumber:number
	hasNextPage: boolean
}) => {
	const query = new URLSearchParams(window.location.search)

	const navigate = useNavigate()
	const [isOpen, setIsOpen] = useState(false)
	const [selectedGenre, setSelectedGenre] = useState<string[]>(
		query.get('genre')?.split(',') || []
	)
	const [selectedPlatform, setSelectedPlatform] = useState<string[]>(
		query.get('platform')?.split(',') || []
	)

	const toggleOpen = () => {
		setIsOpen(!isOpen)
	}

	const handleSortOrder = (sortType: string) => {
		let order = query.get('sortOrder') ? -1 : 1
		if (query.get('sort') === sortType) {
			order = query.get('sortOrder') == '-1' ? 1 : -1
		}
		return `${order}`
	}

	const handlePoularitySearch = (p: string) => {
		setPageNumber(1)
		query.delete('page')
		let sort = ''
		if (p === 'Ratings') {
			sort = 'rating'
		} else if (p === 'Release') {
			sort = 'newest'
		} else {
			sort = 'popularity'
		}
		const sortOrder = handleSortOrder(sort)
		query.set('sort', sort)
		query.set('sortOrder', sortOrder)

		navigate({
			pathname: '/games/discover',
			search: query.toString(),
		})
	}

	const handleGenreSelect = (genre: string) => {
		setPageNumber(1)
		query.delete('page')
		const allGenres = [...selectedGenre]
		if (!selectedGenre.includes(genre)) {
			allGenres.push(genre)
			setSelectedGenre([...selectedGenre, genre])
		} else {
			const index = allGenres.findIndex(g => g === genre)
			allGenres.splice(index, 1)
			setSelectedGenre([...allGenres])
		}

		const joinedGenres =
			allGenres.length > 1 ? allGenres.join(',') : allGenres.join('')

		if (joinedGenres.length) query.set('genre', joinedGenres)
		if (!joinedGenres.length) query.delete('genre')

		navigate({
			pathname: '/games/discover',
			search: query.toString(),
		})
	}

	const handlePlatformSelect = (p: string) => {
		setPageNumber(1)
		query.delete('page')
		let platform = p
		const allPlatforms = [...selectedPlatform]
		if (!allPlatforms.includes(platform)) {
			allPlatforms.push(platform)
			setSelectedPlatform([...selectedPlatform, platform])
		} else {
			const index = allPlatforms.findIndex(item => item === platform)
			allPlatforms.splice(index, 1)
			setSelectedPlatform([...allPlatforms])
		}

		const joinedPlatforms =
			allPlatforms.length > 1 ? allPlatforms.join(',') : allPlatforms.join('')

		if (joinedPlatforms.length) query.set('platform', joinedPlatforms)
		if (!joinedPlatforms.length) query.delete('platform')

		navigate({
			pathname: '/games/discover',
			search: query.toString(),
		})
	}

	return (
		<Fragment>
			<div className='card transparent rounded-xxl shadow-xss w-100 d-block d-flex border-0 p-4 mb-3'>
				<div className='filterBox'>
					<h2 className='fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center'>
						Browse
						<form
							action='#'
							className='pb-0 ms-auto'
							style={{
								position: 'absolute',
								left: 0,
								width: '100%',
								top: 0,
								paddingTop: '10px',
							}}
						>
							<input className='menu-btn' type='checkbox' id='menu-btn' />
							<label className='menu-icon text-grey-900' htmlFor='menu-btn'>
								<span className='navicon'></span>
							</label>
							<ul
								style={{
									paddingRight: '7px',
								}}
								className='menu bg-white'
							>
								<li className='p-2 ms-2'>
									<div
										className={`fw-700 font-xssss text-uppercase ls-3 lh-32 rounded-xl text-center align-items-center d-flex pe-3 ps-3 p-1 bg-greylight theme-dark-bg pointer`}
										id='platform'
										data-bs-toggle='dropdown'
										aria-expanded='false'
										onClick={toggleOpen}
									>
										<i className='me-2 feather-layers font-xss text-grey-500' />
										Platform
									</div>
									<div
										className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg theme-dark-bg`}
										aria-labelledby='platform'
									>
										{Platforms.map(p => (
											<div
												key={`Platform-${p}`}
												className='nav-content-bttn open-font p-2 fw-600 text-grey-900 pointer'
												onClick={() => handlePlatformSelect(p.toLowerCase())}
											>
												<div className='fw-600 mb-0 text-dark mt-1 mb-1 d-block d-flex'>
													{selectedPlatform.includes(p.toLowerCase()) ? (
														<i className='feather-check-square text-grey-500 me-2'></i>
													) : (
														<i className='feather-square text-grey-500 me-2'></i>
													)}
													<span className='font-xsss'>{p}</span>
												</div>
											</div>
										))}
									</div>
								</li>
								<li className='p-2 ms-2'>
									<div
										className={`fw-700 font-xssss text-uppercase ls-3 lh-32 rounded-xl text-center align-items-center d-flex pe-3 ps-3 p-1 bg-greylight theme-dark-bg pointer`}
										id='Genre'
										data-bs-toggle='dropdown'
										aria-expanded='false'
										onClick={toggleOpen}
									>
										<i className='me-2 feather-filter font-xss text-grey-500' /> Genre
									</div>
									<div
										className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg theme-dark-bg`}
										aria-labelledby='Genre'
									>
										{Genre.map(g => (
											<div
												key={`${g}-key`}
												className='nav-content-bttn open-font p-2 fw-600 text-grey-900 pointer'
												onClick={() => handleGenreSelect(g.toLowerCase())}
											>
												<div className='fw-600 mb-0 text-dark mt-1 mb-1 d-block d-flex'>
													{selectedGenre.includes(g.toLowerCase()) ? (
														<i className='feather-check-square text-grey-500 me-2'></i>
													) : (
														<i className='feather-square text-grey-500 me-2'></i>
													)}
													<span className='font-xsss'>{g}</span>
												</div>
											</div>
										))}
									</div>
								</li>
								<li className='p-2 ms-2 pb-3'>
									<div
										className={`fw-700 font-xssss text-uppercase ls-3 lh-32 rounded-xl text-center align-items-center d-flex pe-3 ps-3 p-1 bg-greylight theme-dark-bg pointer`}
										id='sort'
										data-bs-toggle='dropdown'
										aria-expanded='false'
										onClick={toggleOpen}
									>
										<span className='me-2'>Sort</span>
										<i
											style={{ marginLeft: 'auto' }}
											className='feather-chevron-down font-xss text-grey-500'
										/>
									</div>
									<div
										className={`dropdown-menu p-2 rounded-xxl border-0 shadow-lg theme-dark-bg`}
										aria-labelledby='sort'
									>
										{Popularity.map(p => (
											<div
												onClick={() => {
													handlePoularitySearch(p.split(' ')[1])
												}}
												key={`${p}-key`}
												className='nav-content-bttn open-font p-2 fw-600 text-grey-900 pointer'
											>
												<div className='fw-600 mb-0 text-dark mt-1 mb-1'>
													<span className='font-xsss'>{p}</span>
													{query.get('sort') ? (
														(p === 'By Release' && query.get('sort') === 'newest') ||
														p.toLowerCase().search(query.get('sort') || '') >= 0 ? (
															(query.get('sortOrder') || '-1') === '-1' ? (
																<i className='feather-arrow-down text-grey-500 float-right'></i>
															) : (
																<i className='feather-arrow-up text-grey-500 float-right'></i>
															)
														) : (
															<></>
														)
													) : (
														<></>
													)}
												</div>
											</div>
										))}
									</div>
								</li>
							</ul>
						</form>
					</h2>
				</div>
			</div>

			<div className='row ps-2 pe-1'>
				{!data?.games?.length? (
					<NothingFoundCard text='No Game Found!' />
				) : (
					data?.games?.map((game, index) => (
						<GameCard key={`${game._id}${index}`} game={game} />
					))
				)}
			</div>
			{hasNextPage && (
				<div className='col-lg-12 mt-3 mb-5 text-center'>
					<button
						disabled={loading || !hasNextPage}
						onClick={() => {
		                    query.delete('page')
							navigate({
								pathname: '/games/discover',
								search: query.toString(),
							})
							hasNextPage && setPageNumber((p: number) => p + 1)
						}}
						className='fw-700 text-white font-xssss text-uppercase ls-3 lh-32 mt-3 text-center d-inline-block p-2 bg-current w150 rounded-xl border-0'
					>
						{loading ? (
							<Spinner animation='border' variant='light' size='sm' />
						) : (
							'Load More'
						)}
					</button>
				</div>
			)}
		</Fragment>
	)
}

export default GameDiscoveryComponent
