import { FieldProps } from 'formik'
import Rating from 'react-rating'

const RatingField = ({ field, form }: FieldProps): JSX.Element => {
	return (
		<Rating
			onChange={value => {
				form.setFieldValue(field.name, value)
			}}
			initialRating={field.value}
			emptySymbol='feather-star emptySymbol-star ms-1'
			fullSymbol='feather-star fullSymbol-star ms-1'
		/>
	)
}

export default RatingField
