import { Link } from "react-router-dom";
import Slider from "react-slick";
import { GamesEntity, IGameResponse } from "../../../types/global.interface";
import GameCard from "./GameCard";

const Gameslider = ({ data }: { data: IGameResponse | undefined }) => {
  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
  };

  return (
    <Slider {...sliderSettings}>
      <div
        data-bs-toggle="modal"
        data-bs-target="#Modalstory"
        className="card w175 h240 d-block border-0 shadow-none rounded-xxxl bg-dark overflow-hidden mb-3 mt-0 me-3"
      >
        <Link to="/games/discover">
        <div className="card-body pointer d-block p-3 w-100 position-absolute bottom-0 text-center">
          <span className="btn-round-lg bg-greylight">
            <i className="feather-arrow-right font-lg"></i>
          </span>
          <div className="clearfix mt-1"></div>
          <h4 className="fw-700 position-relative z-index-1 ls-1 font-xssss text-white mt-2 mb-1">
            Discover
          </h4>
        </div>
        </Link>
      </div>
      {/* Games Carousel */}
      {data &&
        data?.games?.map((game: GamesEntity, index: number) => (
          <div key={index}>
            <GameCard game={game} />
          </div>
        ))}
    </Slider>
  );
};

export default Gameslider;
