import axios from "../http-common";
import {
  IGame,
  IGameResponse,
  IGameStats,
  IUserClaimGame,
} from "../types/global.interface";

/**
 * @description search games by query
 * @returns Promise
 */

export const searchGames = async (query: string) => {
  try {
    return await axios.get("/games/search", {
      params: {
        q: query,
      },
    });
  } catch (error) {
    throw error;
  }
};

/**
 * @description list games
 * @returns Promise
 */

interface filterInterface {
  consoles?: string[] | string;
  genre?: string[] | string;
}
export const allGames = async ({
  page,
  sortBy,
  filter,
  sortOrder,
  limit = 16,
}: {
  page: number;
  sortBy?: string;
  filter?: filterInterface;
  sortOrder?: string;
  limit?: number;
}) => {
  try {
    return await axios.get<IGameResponse>("/games/allGames", {
      params: {
        limit,
        page,
        sortBy,
        filter,
        sortOrder,
      },
    });
  } catch (error) {
    throw error;
  }
};

/**
 * @description get game stats
 * @returns Promise
 */

export const gameStats = async ({ id }: { id: string }) => {
  try {
    return await axios.get<IGameStats>(`/games/${id}/stats`);
  } catch (error) {
    throw error;
  }
};

/**
 * @description get game stats
 * @returns Promise
 */

export const allGamesWithStats = async ({ id }: { id: string }) => {
  try {
    const stats = await axios.post("/games/stats", {
      params: {
        id,
      },
    });
    const games = await axios.post("/games/allGames", {
      params: {
        id,
      },
    });
    return {
      ...stats.data,
      ...games.data,
    };
  } catch (error) {
    throw error;
  }
};

/**
 * @description Get game by Id
 * @returns Promise
 */
export const getGameById = async ({
  id,
  getReviewUsers = false,
}: {
  id: string;
  getReviewUsers?: boolean;
}) => {
  try {
    return await axios.get<IGame>(`/games/${id}/${getReviewUsers}`);
  } catch (error) {
    throw error;
  }
};

/**
 * @description Follow game by id
 * @returns Promise
 */
export const followGame = async ({ id }: { id: string }) => {
  try {
    return await axios.post(
      `/games/follow/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};
export const unFollowGame = async ({ id }: { id: string }) => {
  try {
    return await axios.delete(`/games/unfollow/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const updateGame = async ({ id, body }: { id: string; body: IGame }) => {
  try {
    return await axios.patch(
      `/games/${id}`,
      { body },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const claimGameRequest = async ({ id }: { id: string }) => {
  try {
    return await axios.post(
      `/games/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

export const claimGameApprove = async ({
  gameId,
  userId,
}: {
  gameId: string;
  userId: string;
}) => {
  try {
    return await axios.post(
      `/games/claim/approve`,
      { userId, gameId },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch (error) {
    throw error;
  }
};

/**
 * @description Get User Claim game by Id
 * @returns Promise
 */
export const getUserClaimGame = async ({ id }: { id: string }) => {
  try {
    return await axios.get<IUserClaimGame>(`/games/user/claimGame/${id}`);
  } catch (error) {
    throw error;
  }
};
