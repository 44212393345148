import axios from '../http-common'
import { IGameMainAnalytics, IGameVisitors } from '../types/global.interface'

export const createAnalytics = async ({
	game,
	user,
}: {
	game: string
	user: string
}) => {
	try {
		return await axios.post(
			'/analytics',
			{
				game,
				user,
			},
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}
		)
	} catch (error) {
		throw error
	}
}

export const getGameMainAnalytics = async ({
	id,
	date,
	timeOffset,
}: {
	id: string
	date: string
	timeOffset: number
}) => {
	try {
		return await axios.get<IGameMainAnalytics>(
			`/analytics/${id}/${date}/${timeOffset}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}
		)
	} catch (error) {
		throw error
	}
}

export const getGameVisitorsStats = async ({
	id,
	date,
	timeOffset,
}: {
	id: string
	date: string
	timeOffset: number
}) => {
	try {
		return await axios.get<IGameVisitors[]>(
			`/analytics/visitors/${id}/${date}/${timeOffset}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}
		)
	} catch (error) {
		throw error
	}
}
